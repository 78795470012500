import { LinkOff, MiscellaneousServices } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Alerts'
import { DomainAlert } from 'features/inventory/domain/types/DomainAlert'
import { DOMAIN_REBUILD_ALERT_URL } from './constants'

type UseAlerts = {
  switcher: Switch<DomainAlert>
}

export const useAlerts = (): UseAlerts => {
  const { t } = useTranslation('features/domain')
  const theme = useTheme()

  const switcher: UseAlerts['switcher'] = alert => {
    switch (alert) {
      case 'scriptInactive':
        return {
          Icon: <LinkOff />,
          color: theme.palette.error.main,
          tooltip: {
            text: t('alerts.scriptInactive'),
          },
        }
      case 'rebuildInProgress':
        return {
          Icon: <MiscellaneousServices />,
          color: theme.palette.warning.main,
          tooltip: {
            text: t('alerts.rebuildInProgress'),
            link: DOMAIN_REBUILD_ALERT_URL,
          },
        }
      case 'rebuildFailed':
        return {
          Icon: <MiscellaneousServices />,
          color: theme.palette.error.main,
          tooltip: {
            text: t('alerts.rebuildFailed'),
            link: DOMAIN_REBUILD_ALERT_URL,
          },
        }
      default:
        throw Error('Missing alert config')
    }
  }

  return { switcher }
}
