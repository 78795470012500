import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const REMOVE_VIEWPORT: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation deleteViewport($input: DeleteViewportInput!) {
    deleteViewport(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
`
