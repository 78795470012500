import { useEffect, useState } from 'react'

import { USE_PAGINATION_DEFAULTS } from './constants'
import { UsePagination, UsePaginationProps } from './types'

const { ROWS_PER_PAGE, FIRST_PAGE } = USE_PAGINATION_DEFAULTS

export const usePagination = ({ resetOnChange }: UsePaginationProps = {}): UsePagination => {
  const [page, setPage] = useState(FIRST_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE)

  useEffect(() => {
    if (resetOnChange) {
      setPage(1)
    }
  }, resetOnChange) // eslint-disable-line react-hooks/exhaustive-deps

  return { page, setPage, rowsPerPage, setRowsPerPage }
}
