import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { isSwitchDisabledBasedOnUnfilledRecovery } from '../../utils/unfilledRecovery'
import { CommonSchema } from './schemas/commonSchema'

export const CommonForm = (): JSX.Element => {
  const { t } = useTranslation('features/adUnit')
  const { adUnit } = useContext(AddEditAdUnitContext)
  const { control } = useFormContext<CommonSchema>()

  if (!adUnit) {
    throw Error('Ad unit should be accessible')
  }

  const { unfilledRecoveryAdUnit } = adUnit

  const isDisabled: boolean = isSwitchDisabledBasedOnUnfilledRecovery(
    unfilledRecoveryAdUnit,
    'priceGenius',
  )

  return (
    <Paper>
      <SectionHeader
        title={t('form.priceGenius.enabled.title')}
        titleEndAdornment={
          <Switch
            control={control}
            name='priceGeniusEnabled'
            disabled={isDisabled}
          />
        }
        subtitle={t('form.priceGenius.enabled.description')}
      />
    </Paper>
  )
}
