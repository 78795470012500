import { mapDTO } from '@twistezo/ts-dto-mapper'

import { workspaceFromDTO } from 'features/workspace'
import { UserSlice, UserSliceDTO, Variables, VariablesDTO } from './types'

export const fromDTO = (from: UserSliceDTO): UserSlice =>
  mapDTO<UserSliceDTO, UserSlice>({ from }).transform(userSliceDTO => {
    const { currentWorkspace, currentWorkspaceRole, workspaceProducts } = userSliceDTO

    return {
      ...userSliceDTO,
      currentWorkspaceRole: currentWorkspaceRole || undefined,
      currentWorkspace: currentWorkspace ? workspaceFromDTO(currentWorkspace) : undefined,
      workspaceProducts: workspaceProducts || undefined,
    }
  })

export const toDTO = (from: Variables): VariablesDTO =>
  mapDTO<Variables, VariablesDTO>({ from }).transform(variables => {
    const { currentWorkspaceId } = variables

    return {
      ...variables,
      currentWorkspaceId: currentWorkspaceId || null,
    }
  })
