import { useSelector } from 'react-redux'

import { useAbility } from 'providers/casl'
import { RootState } from 'providers/redux'
import { UserWorkspaceProductsSlice } from '../types/user'
import { authorizeGroupRole } from '../utils/authorizeGroupRole'
import { GetProductStatus, UseAuthUser } from './types'

export const useAuthUser = (): UseAuthUser => {
  const { user } = useSelector((state: RootState) => state.user)
  const ability = useAbility()

  if (user === null) {
    throw Error('User is not authenticated')
  }

  const canUseWorkspace = (): boolean => {
    if (authorizeGroupRole('employeesOnly', user.role)) {
      return true
    } else if (user.currentWorkspace?.active === true) {
      return true
    } else return false
  }

  const getProductStatus: GetProductStatus = product => {
    if (!user.workspaceProducts) {
      return 'inactive'
    }

    const userProduct: UserWorkspaceProductsSlice | undefined = user.workspaceProducts.find(
      (wp: UserWorkspaceProductsSlice) => wp.product === product,
    )
    if (userProduct === undefined) {
      throw Error('Mismatched Products lists')
    }
    return userProduct.status
  }

  const isEmployee: boolean = authorizeGroupRole('employeesOnly', user.role)

  const isEmployeeView: boolean =
    ability.can('access', 'WorkspacePage-list') && user.currentWorkspace === undefined

  return {
    user,
    helpers: {
      canUseWorkspace: canUseWorkspace(),
      getProductStatus,
      isEmployee,
      isEmployeeView,
    },
  }
}
