import { FieldErrors } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'

import { WorkspaceBidder } from 'features/globalSetup/types/workspaceBidder'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { mapGraphQLErrors } from 'providers/graphql'
import { ROUTES } from 'routes'
import { AddSchema } from '../schemas/addSchema'
import { Data as OwnData } from './useCreateOwnWorkspaceBidder/api/types'
import { Data as ThirdPartyProviderData } from './useCreateThirdPartyProviderWorkspaceBidder/api/types'
import { Data as YieldbirdData } from './useCreateYieldbirdWorkspaceBidder/api/types'
import { useSnackbar } from './useSnackbar'

type ResponseData = OwnData | YieldbirdData | ThirdPartyProviderData

export const useOnCompleted = () => {
  const { workspaceId } = useWorkspaceParam()
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const onCompleted = (
    bidderCode: WorkspaceBidder['bidderCode'],
    data: ResponseData,
  ): FieldErrors<AddSchema> => {
    const errors = data.result?.errors

    if (errors && errors.length > 0) {
      snackbar(bidderCode, 'error')

      return mapGraphQLErrors(errors)
    } else {
      navigate(generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.ROOT, { workspaceId }))
      snackbar(bidderCode)

      return {}
    }
  }

  return { onCompleted }
}
