import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { AdUnitParametersDTO } from 'features/globalSetup/api/types/adUnitBidderDTO'
import {
  Schema,
  SchemaAdUnitBidderParams,
  SchemaAdUnitBidderProps,
} from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { parseValidJSON } from 'utils/json'
import { AdUnitBidderAttribute, Variables } from './types'

type From = Schema
type To = Variables

export const toDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((data: From): To => {
    const {
      domainIds: { domainBidderId },
      adUnitBidders,
      ...rest
    } = data

    const parsedAttributes: AdUnitBidderAttribute[] = parseSchemaToAPI(adUnitBidders)
    const filteredAttributes: AdUnitBidderAttribute[] = filterEmptyAttributes(parsedAttributes)

    return {
      adUnitBiddersAttributes: filteredAttributes,
      id: domainBidderId,
      ...rest,
    }
  })

const parseSchemaToAPI = (adUnitBidders: Schema['adUnitBidders']): AdUnitBidderAttribute[] =>
  Object.entries(adUnitBidders).map(
    ([key, { csEnabled, s2sEnabled, csParams, s2sParams }]: [
      key: keyof Schema['adUnitBidders'],
      SchemaAdUnitBidderProps,
    ]): AdUnitBidderAttribute => ({
      id: key,
      csEnabled,
      csParams: parseParams(csParams),
      s2sEnabled,
      s2sParams: parseParams(s2sParams),
    }),
  )

const parseParams = (params: SchemaAdUnitBidderParams): AdUnitParametersDTO =>
  Object.fromEntries(
    Object.entries(params).map(([key, value]: [string, string | number]): [string, unknown] => [
      key,
      parseValue(value),
    ]),
  )

const parseValue = (value: string | number): unknown => {
  const isEmptyString: boolean = _.isString(value) && value.length === 0
  const parsedValue: unknown = isEmptyString ? null : parseValidJSON(value.toString())

  return parsedValue
}

const filterEmptyAttributes = (attributes: AdUnitBidderAttribute[]): AdUnitBidderAttribute[] =>
  attributes.filter((attribute: AdUnitBidderAttribute): boolean => {
    const { csParams, s2sParams } = attribute

    const areCsParamsFilled: boolean = _.values(csParams).some(_.negate(_.isNull))
    const areS2sParamsFilled: boolean = _.values(s2sParams).some(_.negate(_.isNull))

    return areCsParamsFilled || areS2sParamsFilled
  })
