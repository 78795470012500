import { mapDTO } from '@twistezo/ts-dto-mapper'

import { Schema } from '../../schema'
import { Variables } from './types'

export const toDTO = (from: Schema): Variables =>
  mapDTO<Schema, Variables>({ from }).transform(schema => {
    const { id, financeReportUrl } = schema

    return {
      id,
      financeReportUrl: financeReportUrl || null,
    }
  })
