import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CodeField, TextField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { SectionHeaderEndAdornment } from './components/SectionHeaderEndAdornment'
import { EditSchema } from './schemas/editSchema'

export const EditForm = (): JSX.Element => {
  const { t } = useTranslation('features/domain')
  const { control, watch } = useFormContext<EditSchema>()
  const { customCodeEnabled } = watch()

  return (
    <Paper>
      <SectionHeader
        subtitle={t('form.general.customCodeAttributes.subtitle')}
        title={t('form.general.customCodeAttributes.title')}
        titleEndAdornment={<SectionHeaderEndAdornment />}
      />

      {customCodeEnabled && (
        <>
          <CodeField
            control={control}
            name='customCodeAttributes.codeBefore'
            title={t('form.general.customCodeAttributes.codeBefore')}
            bottomSpacing
          />

          <CodeField
            control={control}
            name='customCodeAttributes.codeAfter'
            title={t('form.general.customCodeAttributes.codeAfter')}
            bottomSpacing
          />

          <TextField
            control={control}
            fullWidth
            label={t('form.general.customCodeAttributes.comment')}
            minRows={5}
            multiline
            name='customCodeAttributes.comment'
          />
        </>
      )}
    </Paper>
  )
}
