import { Edit, MoreVert, Tune } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { CellContext } from '@tanstack/react-table'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { ListItemButton } from 'components/List'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { DomainBidderSlice } from 'features/inventory/domain/forms/PrebidStackForm'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { ROUTES } from 'routes'

export const ActionsCell = ({
  row: {
    original: { id, workspaceBidderId },
  },
}: CellContext<DomainBidderSlice, unknown>): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { domain } = useContext(AddEditDomainContext)
  const navigate = useNavigate()
  const { t } = useTranslation(['features/domain', 'common'])
  const { anchor, isOpen, open, close } = useMenu()

  if (!domain) {
    throw new Error('Domain is required')
  }

  const handleEdit = (): void =>
    navigate(
      generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT, {
        workspaceId,
        id: workspaceBidderId,
        tab: 'general',
      }),
    )

  const handleParamsAndConnection = (): void =>
    navigate(
      generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT_SPECIFIC, {
        workspaceId,
        id: workspaceBidderId,
        tab: 'bid-params-and-connection',
        domainBidderId: id,
        domainId: domain?.id,
      }),
    )

  const EditItem = (): JSX.Element => (
    <ListItemButton
      icon={<Edit />}
      text={t('common:form.action.edit')}
      onClick={handleEdit}
      data-cy='actions-edit'
    />
  )

  const ParamsAndConnectionItem = (): JSX.Element => (
    <ListItemButton
      icon={<Tune />}
      text={t('form.prebidStack.bidders.list.action.paramsAndConnection')}
      onClick={handleParamsAndConnection}
      data-cy='actions-edit'
    />
  )

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <EditItem />

        <Divider
          key='divider'
          sx={{ my: 1 }}
        />

        <ParamsAndConnectionItem />
      </Menu>
    </>
  )
}
