import { Close as CloseIcon, Delete as DeleteIcon, Save as SaveIcon } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { FlexBox } from 'components/Box'
import { Select, SelectAutocomplete } from 'components/Form'
import Loading from 'components/Loading'
import { userWorkspaceRoleOptions } from 'features/user'
import { useGetWorkspaceOptions } from '../hooks/useGetWorkspaceOptions'
import { UserWorkspaceForForm } from '../types'
import { UserWorkspacesContext } from './contexts/UserWorkspacesContext'
import { useRemovePublisherFromWorkspace } from './hooks/useRemovePublisherFromWorkspace'
import { CommonSchema } from './schemas'

export const EditForm = (): JSX.Element => {
  const { userWorkspaces } = useContext(UserWorkspacesContext)
  const { workspaceOptions, loading } = useGetWorkspaceOptions()

  const {
    control,
    formState: { isDirty },
    reset,
    watch,
  } = useFormContext<CommonSchema>()
  const { workspaceId, userId } = watch()
  const { removePublisherFromWorkspace } = useRemovePublisherFromWorkspace({ workspaceId })

  if (loading) {
    return <Loading />
  }

  return (
    <Stack
      direction='row'
      gap={2}
    >
      <SelectAutocomplete
        control={control}
        name='workspaceId'
        options={workspaceOptions}
        disabled
        loading={loading}
        disabledOptions={userWorkspaces.map(({ workspaceId }: UserWorkspaceForForm) => workspaceId)}
      />

      <Select
        control={control}
        name='role'
        options={userWorkspaceRoleOptions()}
      />

      <FlexBox axis='x'>
        {isDirty ? (
          <>
            <IconButton type='submit'>
              <SaveIcon />
            </IconButton>

            <IconButton onClick={() => reset()}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={() => removePublisherFromWorkspace({ workspaceId, userId })}>
            <DeleteIcon />
          </IconButton>
        )}
      </FlexBox>
    </Stack>
  )
}
