import { Box } from '@mui/material'

import { FooterInfoProps } from './types'

export const FooterInfo = ({ text, color }: FooterInfoProps) => (
  <Box
    component='td'
    sx={{
      display: 'flex',
      alignItems: 'center',
      p: 2,
      color: color || undefined,
    }}
  >
    {text}
  </Box>
)
