import { useTranslation } from 'react-i18next'

import { PageHeader } from 'components/Header'
import ViewportList from 'features/inventory/viewport/components/ViewportList'
import { DESCRIPTION_URL } from 'features/inventory/viewport/constants'

export const ViewportPage = (): JSX.Element => {
  const { t } = useTranslation(['features/viewport', 'common'])

  return (
    <>
      <PageHeader
        title={t('header.title')}
        description={{
          text: t('header.description.text'),
          link: {
            title: t('common:learnMore'),
            url: DESCRIPTION_URL,
          },
        }}
      />

      <ViewportList />
    </>
  )
}
