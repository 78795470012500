import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, RadioGroup, RadioGroupOption, TextField } from 'components/Form'
import { CheckboxListFilterable } from 'components/Form/CheckboxListFilterable'
import { ChipsListFilterable } from 'components/Form/ChipsListFilterable'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { useApplicableDomainsOptions } from './hooks/useApplicableDomainsOptions'
import { useGetDomainsOptions } from './hooks/useGetDomainsOptions'
import { CommonSchema } from './schemas/commonSchema'

export const CommonForm = () => {
  const ability = useAbility()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.protection.form',
  })
  const applicableDomainsOptions: RadioGroupOption[] = useApplicableDomainsOptions()
  const { control, watch } = useFormContext<CommonSchema>()
  const { domainsOptions } = useGetDomainsOptions()
  const { applicableDomains } = watch()

  const canCreate: boolean = ability.can('create', 'GlobalSetupFeature-protection')

  return (
    <Paper>
      <BoxForm>
        <TextField
          control={control}
          name='name'
          label={t('name.label')}
          disabled={!canCreate}
        />

        <ChipsListFilterable
          control={control}
          name='blocked'
          label={t('blocked.label')}
          placeholder={t('blocked.placeholder')}
        />

        <Box>
          <RadioGroup
            control={control}
            name='applicableDomains'
            label={t('applicableDomains.label')}
            options={applicableDomainsOptions}
            row
          />

          {applicableDomains === 'specified' && (
            <CheckboxListFilterable
              control={control}
              name='domainIds'
              options={domainsOptions}
              placeholder={t('domainIds.placeholder')}
            />
          )}
        </Box>
      </BoxForm>
    </Paper>
  )
}
