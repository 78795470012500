import { useContext } from 'react'

import { Form as FormProvider } from 'components/Form'
import Loading from 'components/Loading'
import { WorkspacePrebidConfigurationContext } from 'features/globalSetup/contexts/WorkspacePrebidConfigurationContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { Form } from './Form'
import { useUpdateWorkspacePrebidConfiguration } from './hooks/useUpdateWorkspacePrebidConfiguration'
import { Schema, schema, schemaDefault } from './schema'

export const FormContainer = (): JSX.Element => {
  const { formId, loading, setTouched, setValid, workspacePrebidConfiguration } = useContext(
    WorkspacePrebidConfigurationContext,
  )
  const { workspaceId } = useWorkspaceParam()
  const { update, errors: apiErrors } = useUpdateWorkspacePrebidConfiguration()

  if (!workspacePrebidConfiguration) {
    return <Loading />
  }

  return (
    <FormProvider<Schema>
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(workspacePrebidConfiguration, workspaceId)}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <Form />)}
    </FormProvider>
  )
}
