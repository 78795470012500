import { Button } from '@mui/material'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { BoxForm, TextField } from 'components/Form'
import { PasswordIcon } from 'features/user/components/PasswordIcon'
import { INPUT_WIDTH } from './constants'
import { Schema } from './schema'

export const Form = () => {
  const { t } = useTranslation('features/user')
  const {
    control,
    formState: { isValid },
  } = useFormContext<Schema>()
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <>
      <BoxForm>
        <FlexBox
          axis='x'
          gap={2}
        >
          <TextField
            control={control}
            label={t('completeSetup.form.firstName.label')}
            name='firstName'
            placeholder={t('completeSetup.form.firstName.placeholder')}
            sx={{ minWidth: INPUT_WIDTH, maxWidth: INPUT_WIDTH }}
          />

          <TextField
            control={control}
            label={t('completeSetup.form.lastName.label')}
            name='lastName'
            placeholder={t('completeSetup.form.lastName.placeholder')}
            sx={{ minWidth: INPUT_WIDTH, maxWidth: INPUT_WIDTH }}
          />
        </FlexBox>

        <TextField
          control={control}
          InputProps={{ endAdornment: <PasswordIcon {...{ showPassword, setShowPassword }} /> }}
          name='password'
          label={t('completeSetup.form.password.label')}
          type={showPassword ? 'text' : 'password'}
          fullWidth
        />

        <TextField
          control={control}
          InputProps={{ endAdornment: <PasswordIcon {...{ showPassword, setShowPassword }} /> }}
          name='passwordConfirmation'
          label={t('completeSetup.form.passwordConfirmation.label')}
          type={showPassword ? 'text' : 'password'}
          fullWidth
        />
      </BoxForm>

      <Button
        data-cy='button-submit-complete-setup-form'
        disabled={!isValid}
        sx={{ mt: 2 }}
        type='submit'
        variant='contained'
      >
        {t('completeSetup.form.button')}
      </Button>
    </>
  )
}
