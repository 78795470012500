import { t } from 'i18next'
import { z } from 'zod'

export const schema = z
  .object({
    password: z.string().min(1),
    passwordConfirmation: z.string().min(1),
    resetPasswordToken: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.passwordConfirmation) {
      ctx.addIssue({
        message: t('features/user:confirmResetPassword.form.passwordConfirmation.error'),
        path: ['passwordConfirmation'],
        code: z.ZodIssueCode.custom,
      })
    }
  })

export const schemaDefaults = (resetPasswordToken: string): Schema => ({
  password: '',
  passwordConfirmation: '',
  resetPasswordToken,
})

export type Schema = z.infer<typeof schema>
