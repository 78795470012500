import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { usePrevious } from 'hooks/usePrevious'
import { useGetPrebidModuleParametersSchema } from '../hooks/useGetPrebidModuleParametersSchema'
import { CommonSchema } from '../schemas/commonSchema'
import { unregisterParameters } from '../utils'
import { ParametersSection } from './sections/ParametersSection'
import { UserIdSection } from './sections/UserIdSection'
import { CommonFormProps } from './types'

export const UserIdsForm = ({ variant }: CommonFormProps): JSX.Element => {
  const { watch, unregister } = useFormContext<CommonSchema>()
  const { accountType, prebidModuleId } = watch()
  const { parametersSchema } = useGetPrebidModuleParametersSchema({ id: prebidModuleId })
  const previousParametersSchema = usePrevious<PrebidModuleParameter[]>(parametersSchema)

  useEffect(() => {
    if (previousParametersSchema && previousParametersSchema.length > 0) {
      unregisterParameters<CommonSchema>(previousParametersSchema, unregister)
    }
  }, [parametersSchema]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {variant === 'add' && <UserIdSection />}

      {accountType === 'own' && parametersSchema.length > 0 && (
        <ParametersSection parametersSchema={parametersSchema} />
      )}
    </>
  )
}
