import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { AdUnitForTable } from '../../types'
import { adUnitForTableFromDTO, sortToDTO } from './api/mapper'
import { GET_AD_UNITS_FOR_TABLE } from './api/query'
import { AdUnitForTableDTO } from './api/types'
import { UseAdUnits, UseAdUnitsProps } from './types'

export const useAdUnits = ({ filters, first, last, sort }: UseAdUnitsProps): UseAdUnits => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const [adUnits, setAdUnits] = useState<AdUnitForTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_AD_UNITS_FOR_TABLE(ability), {
    variables: {
      first,
      last,
      terms: filters,
      workspaceId,
      sort: sortToDTO(sort),
    },
    fetchPolicy: 'cache-and-network',

    onCompleted: ({ adUnits: { nodes: adUnits, totalCount: count } }): void => {
      if (adUnits) {
        setAdUnits(
          adUnits.map((node: AdUnitForTableDTO): AdUnitForTable => adUnitForTableFromDTO(node)),
        )
        setCount(count)
      }
    },
  })

  return {
    adUnits,
    setAdUnits,
    count,
    loading,
  }
}
