import { FetchResult, useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { SupplyChain } from 'features/globalSetup/types/supplyChain'
import { DELETE_SUPPLY_CHAIN } from './api/mutation'
import { Data } from './api/types'
import { UseDeleteSupplyChain, UseDeleteSupplyChainProps } from './types'

export const useDeleteSupplyChain = ({ name }: UseDeleteSupplyChainProps): UseDeleteSupplyChain => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.delete.action',
  })

  const [mutateDeleteSupplyChain] = useMutation(DELETE_SUPPLY_CHAIN, {
    refetchQueries: ['supplyChains'],
    onCompleted: ({ errors }): void => {
      if (errors && errors.length > 0) {
        enqueueSnackbar(t('error', { supplyChain: name }), { variant: 'error' })
      } else {
        enqueueSnackbar(t('success', { supplyChain: name }), { variant: 'success' })
      }
    },
  })

  const deleteSupplyChain = (id: SupplyChain['id']): Promise<FetchResult<Data>> =>
    mutateDeleteSupplyChain({ variables: { id } })

  return {
    deleteSupplyChain,
  }
}
