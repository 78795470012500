import { useContext } from 'react'

import { Form as FormContainer } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditDomainContext } from '../../contexts/AddEditDomainContext'
import {
  PrebidStackFormContext,
  PrebidStackFormContextType,
} from './context/PrebidStackFormContext'
import { Form } from './Form'
import { useGetDomainBidders } from './hooks/useGetDomainBidders'
import { useUpdateDomainPrebidStack } from './hooks/useUpdateDomainPrebidStack'
import { Schema, schema, schemaDefault } from './schema'

export const PrebidStackForm = (): JSX.Element => {
  const { domain, formId, loading, setTouched, setValid } = useContext(AddEditDomainContext)
  const { domainBidders } = useGetDomainBidders({
    domainId: domain?.id,
  })
  const { errors: apiErrors, update } = useUpdateDomainPrebidStack()

  if (!domain || !domainBidders) {
    return <Loading />
  }

  const contextProps: PrebidStackFormContextType = {
    domainBidders: domainBidders,
  }

  return (
    <PrebidStackFormContext.Provider value={contextProps}>
      <FormContainer<Schema>
        id={formId}
        onSubmit={update}
        onTouched={setTouched}
        onValid={setValid}
        schema={schema}
        schemaDefaults={schemaDefault({ domain, domainBidders })}
        externalErrors={apiErrors}
      >
        {() => (loading ? <Loading /> : <Form />)}
      </FormContainer>
    </PrebidStackFormContext.Provider>
  )
}
