import { gql, TypedDocumentNode } from '@apollo/client'

import { FilteringRuleSliceDTO } from './types'

export const FILTERING_RULE_FRAGMENT: TypedDocumentNode<FilteringRuleSliceDTO> = gql`
  fragment FilteringRuleFragment on FilteringRule {
    id
    name
  }
`
