import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BoxForm, Select, TextField } from 'components/Form'
import Paper from 'components/Paper'
import { KNOWLEDGE_BASE } from 'features/globalSetup/constants'
import { useAuthUser, UserWorkspaceRole, userWorkspaceRoleOptions } from 'features/user'
import { Schema } from './schema'
import { WorkspaceUserEditPageParams } from './types'

export const Form = (): JSX.Element => {
  const { id } = useParams<WorkspaceUserEditPageParams>()
  const {
    user: { id: currentUserId },
  } = useAuthUser()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const { control } = useFormContext<Schema>()

  const disabled = currentUserId == id

  return (
    <Paper>
      <BoxForm>
        <TextField
          disabled={true}
          control={control}
          name='email'
          label={t('general.userManagement.common.email.label')}
          placeholder={t('general.userManagement.common.email.placeholder')}
        />

        <Select<Schema, UserWorkspaceRole>
          disabled={disabled}
          control={control}
          label={t('general.userManagement.common.role.label')}
          name='role'
          options={userWorkspaceRoleOptions()}
          tooltip={{
            content: t('general.userManagement.common.role.tooltip'),
            link: {
              title: t('common:learnMore'),
              url: KNOWLEDGE_BASE.USER_MANAGEMENT,
            },
          }}
        />
      </BoxForm>
    </Paper>
  )
}
