import { gql, TypedDocumentNode } from '@apollo/client'

import { VIEWPORT_FRAGMENT } from 'features/inventory/viewport/api/fragment'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const CREATE_VIEWPORT: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${VIEWPORT_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation createViewport($input: CreateViewportInput!) {
    createViewport(input: $input) {
      viewport {
        ...ViewportFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`
