import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Viewport } from 'features/inventory/viewport/types/viewport'
import { mapGraphQLErrors } from 'providers/graphql'
import { EditSchema } from '../../schemas/editSchema'
import { REMOVE_VIEWPORT } from './api/mutation'
import { Data } from './api/types'
import { UseRemoveViewport } from './types'

export const useRemoveViewport = (): UseRemoveViewport => {
  const { t } = useTranslation('features/viewport')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutateRemoveViewport] = useMutation(REMOVE_VIEWPORT, {
    refetchQueries: ['getViewports'],
    onCompleted: ({ deleteViewport }: Data) => {
      if (deleteViewport) {
        const { errors } = deleteViewport

        if (errors && errors.length > 0) {
          setErrors(mapGraphQLErrors(errors))
          enqueueSnackbar(t('form.error.delete'), { variant: 'error' })
        }
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.delete'), { variant: 'success' })
      }
    },
  })

  const remove = (id: Viewport['id']) =>
    mutateRemoveViewport({
      variables: {
        input: {
          id,
        },
      },
    })

  return { remove, errors }
}
