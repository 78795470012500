import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import { ChipsMultiValueProps } from './types'

export const ChipsMultiValue = <T extends FieldValues>({
  control,
  name,
  sx,
  disabled,
}: ChipsMultiValueProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name as FieldPath<T>}
    render={({ field, fieldState: { error } }) => (
      <FormControl
        error={Boolean(error)}
        sx={sx}
      >
        <Autocomplete
          {...field}
          disableClearable
          freeSolo
          id={name}
          multiple
          onChange={(_, values: string[]): void => field.onChange(values)}
          open={false}
          options={[]}
          renderInput={(props: AutocompleteRenderInputParams) => (
            <TextField
              {...props}
              sx={sx}
              error={Boolean(error)}
            />
          )}
          disabled={disabled}
        />

        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    )}
  />
)
