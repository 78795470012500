import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useAbility } from 'providers/casl'
import { CommonForm } from './CommonForm'
import { EditForm } from './EditForm'
import { useUpdateDomain } from './hooks/useUpdateDomain'
import { EditSchema, editSchema, editSchemaDefault } from './schemas/editSchema'
import { AdvancedSettings } from './sections/AdvancedSettings'
import BulkManagement from './sections/BulkManagement'

export const GeneralFormEdit = (): JSX.Element => {
  const ability = useAbility()
  const { domain, formId, loading, setTouched, setValid } = useContext(AddEditDomainContext)
  const { errors: apiErrors, updateDomain } = useUpdateDomain()

  if (!domain) {
    return <Loading />
  }

  return (
    <Form<EditSchema>
      id={formId}
      onSubmit={data => updateDomain(data)}
      onTouched={setTouched}
      onValid={setValid}
      schema={editSchema}
      schemaDefaults={editSchemaDefault(domain, ability)}
      externalErrors={apiErrors}
    >
      {() =>
        loading ? (
          <Loading />
        ) : (
          <>
            <CommonForm />
            <EditForm />
            <BulkManagement />
            <AdvancedSettings />
          </>
        )
      }
    </Form>
  )
}
