import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { SelectOption } from 'components/Select'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { domainFromDTO } from './api/mapper'
import { GET_DOMAINS } from './api/query'
import { DomainSliceDTO } from './api/types'
import { DomainSlice, UseGetDomainsForSelect } from './types'

export const useGetDomainsForSelect = (): UseGetDomainsForSelect => {
  const { workspaceId } = useWorkspaceParam()
  const [domains, setDomains] = useState<SelectOption<DomainSlice>[]>([])

  useQuery(GET_DOMAINS, {
    variables: {
      workspaceId,
    },

    onCompleted: ({ domains: { nodes: domains } }): void => {
      if (domains && domains.length > 0) {
        setDomains(
          domains.map((domain: DomainSliceDTO): SelectOption<DomainSlice> => domainFromDTO(domain)),
        )
      }
    },
  })

  return { domains }
}
