import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { DomainSupplyChain } from 'features/globalSetup/types/domainSupplyChain'
import { CustomDomainSidInputCell } from '../../components/CustomDomainSidInputCell'

export const useColumns = (): ColumnDef<DomainSupplyChain>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.domainSids.headers',
  })

  return [
    {
      accessorKey: 'domain.name',
      header: t('domainName'),
      meta: { isPinned: true },
    },
    {
      accessorKey: 'customDomainSid',
      header: t('customDomainSid'),
      cell: context => <CustomDomainSidInputCell {...context} />,
    },
    {
      header: t('lastModified'),
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
    },
  ]
}
