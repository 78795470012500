import { CancelOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { UploadErrors } from '../hooks/useUploadAdUnitsConfiguration'

type Props = {
  filename: File['name']
  errors: UploadErrors
}

export const DialogContentFailure = ({ filename, errors }: Props): JSX.Element => {
  const { t } = useTranslation('features/domain')

  return (
    <FlexBox axis='xy'>
      <CancelOutlined
        sx={{
          fontSize: 75,
          color: theme => theme.palette.error.main,
          pb: 2,
        }}
      />

      <Typography variant='h6'>
        {t('form.general.bulkManagement.dialog.upload.error.title', {
          filename,
        })}
      </Typography>

      <Box>
        <Typography py={2}>
          {t('form.general.bulkManagement.dialog.upload.error.description')}
        </Typography>

        {errors.map((err: string) => (
          <Typography
            key={err}
            variant='body2'
          >
            {err}
          </Typography>
        ))}
      </Box>
    </FlexBox>
  )
}
