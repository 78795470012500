import _ from 'lodash'
import { useEffect } from 'react'
import { DeepPartial, useFormContext, useWatch } from 'react-hook-form'

import {
  Schema,
  SchemaAdUnitBidderParams,
  SchemaAdUnitBidderProps,
} from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { UseErrorsProps } from './types'
import { getDirtyValues } from './utils/getDirtyValues'
import { setFormErrorsFor } from './utils/setFormErrorsFor'

export const useErrors = ({ adUnitBidders }: UseErrorsProps) => {
  const {
    setError,
    formState,
    formState: { dirtyFields, errors },
  } = useFormContext<Schema>()

  const schema: DeepPartial<Schema> = useWatch<Schema>()

  useEffect(() => {
    if (adUnitBidders.length === 0 || _.isEmpty(dirtyFields)) return

    const { adUnitBidders: dirtyAdUnitBidders } = getDirtyValues<Schema>(dirtyFields, schema)
    if (dirtyAdUnitBidders === undefined) return

    Object.entries(dirtyAdUnitBidders).forEach(
      ([adUnitBidderId, adUnitBidderProps]: [
        key: keyof SchemaAdUnitBidderParams,
        value: DeepPartial<SchemaAdUnitBidderProps> | undefined,
      ]): void => {
        if (adUnitBidderProps === undefined) return
        const { csParams, s2sParams } = adUnitBidderProps

        if (csParams) {
          setFormErrorsFor({
            adUnitBidderId,
            params: csParams,
            kind: 'cs',
            adUnitBidders,
            errors,
            setError,
          })
        }

        if (s2sParams) {
          setFormErrorsFor({
            adUnitBidderId,
            params: s2sParams,
            kind: 's2s',
            adUnitBidders,
            errors,
            setError,
          })
        }
      },
    )
  }, [adUnitBidders, formState]) // eslint-disable-line react-hooks/exhaustive-deps
}
