import _ from 'lodash'

import { DomainBidderSlice } from './hooks/useGetDomainBidders'
import { DomainBiddersAttributes } from './types'

export const domainBiddersToAttributes = (
  domainBidders: DomainBidderSlice[],
): DomainBiddersAttributes =>
  _.chain(domainBidders)
    .keyBy('id')
    .mapValues((domainBidder: DomainBidderSlice) => {
      const { csEnabled, s2sEnabled, priority } = domainBidder

      return { csEnabled, s2sEnabled, priority }
    })
    .value()
