import { Edit, MoreVert } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import { CellContext } from '@tanstack/react-table'
import { constantCase } from 'change-case'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { ScreamingSnakeCase } from 'type-fest'

import { ListItemButton } from 'components/List'
import { canUpdate } from 'features/globalSetup'
import { PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { DomainPrebidModuleSlice } from '../../hooks/useGetDomainPrebidModules/types'

export const ActionsCell = ({
  moduleType,
  row: {
    original: { workspacePrebidModule, prebidModule },
  },
}: { moduleType: PrebidModuleType } & CellContext<
  DomainPrebidModuleSlice,
  unknown
>): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { anchor, isOpen, open, close } = useMenu()

  const disabled = !canUpdate({ account: prebidModule.account, moduleType, ability })

  const handleEdit = (): void =>
    navigate(
      generatePath(
        ROUTES.GLOBAL_SETUP.PREBID.MODULES[
          constantCase(moduleType) as ScreamingSnakeCase<PrebidModuleType>
        ].EDIT,
        {
          workspaceId,
          id: workspacePrebidModule.id,
        },
      ),
    )

  const EditItem = (): JSX.Element => (
    <ListItemButton
      icon={<Edit />}
      text={t('form.action.edit')}
      onClick={handleEdit}
      data-cy='actions-edit'
    />
  )

  return (
    <>
      <IconButton
        onClick={open}
        disabled={disabled}
      >
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <EditItem />
      </Menu>
    </>
  )
}
