import { mapDTO } from '@twistezo/ts-dto-mapper'

import { AdUnitBidderSlice } from '../types'
import { AdUnitBidderSliceDTO } from './types'

export const fromDTO = (from: AdUnitBidderSliceDTO) =>
  mapDTO<AdUnitBidderSliceDTO, AdUnitBidderSlice>({ from }).transform(
    (adUnitBidderDTO: AdUnitBidderSliceDTO): AdUnitBidderSlice => {
      const {
        id,
        csEnabled,
        csRequiredParams,
        domainBidderId,
        s2sEnabled,
        s2sRequiredParams,
        updatedAt,
        workspaceBidder: {
          accountType,
          bidder: { name, s2sAvailable },
          bidderCode: code,
          id: workspaceBidderId,
          status: workspaceBidderStatus,
        },
      } = adUnitBidderDTO

      return {
        id,
        accountType,
        code,
        csEnabled,
        csRequiredParams,
        domainBidderId,
        name,
        s2sAvailable,
        s2sEnabled,
        s2sRequiredParams,
        updatedAt: new Date(updatedAt),
        workspaceBidderId,
        workspaceBidderStatus,
      }
    },
  )
