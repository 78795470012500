import { ColumnDef, ColumnMeta } from '@tanstack/react-table'

import AllRowsExpanderCell from '../Cell/AllRowsExpanderCell'
import RowExpanderCell from '../Cell/RowExpanderCell'
import { COLUMN_SIZE } from '../constants'

const ID: string = 'expander'

export const RowExpanderColumn = <T,>(
  isPinned: ColumnMeta<T, unknown>['isPinned'] = false,
): ColumnDef<T> => ({
  id: ID,
  accessorKey: ID,
  header: ({ table }) => <AllRowsExpanderCell table={table} />,
  cell: ({ row }) => <RowExpanderCell row={row} />,
  meta: {
    centered: true,
    isPinned,
    nonClickable: true,
  },
  size: COLUMN_SIZE.EXPANDER,
})
