import { enqueueSnackbar, VariantType } from 'notistack'
import { useTranslation } from 'react-i18next'

import { WorkspaceBidder } from 'features/globalSetup/types/workspaceBidder'

export const useSnackbar = (bidderCode: WorkspaceBidder['bidderCode']) => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.list.updateEnabled.action',
  })

  const snackbar = (enabled: boolean, variant?: VariantType): void => {
    const tParams = {
      bidderCode,
      action: enabled ? 'enabled' : 'disabled',
    }

    if (variant === 'error') {
      enqueueSnackbar(t('error', tParams), { variant })
    } else {
      enqueueSnackbar(t('success', tParams), {
        variant: enabled ? 'success' : 'warning',
      })
    }
  }

  return { snackbar }
}
