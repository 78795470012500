import { useTranslation } from 'react-i18next'

import { TooltipProps } from 'components/Tooltip'
import { Props, Return } from './types'

export const useDataForAdUnitBidderSwitch = ({
  paramsKind,
  isDisabledByAuth,
  isDisabledByYieldbirdRequested,
  isDisabledByYieldbirdDenied,
  isDisabledByRequiredParams,
}: Props): Return => {
  const { t } = useTranslation(['features/globalSetup'], {
    keyPrefix: 'prebid.bidders.edit.bidParamsAndConnection.tooltips',
  })

  const isDisabled: boolean =
    isDisabledByAuth ||
    isDisabledByYieldbirdRequested ||
    isDisabledByYieldbirdDenied ||
    isDisabledByRequiredParams

  let tooltip: TooltipProps | undefined = undefined
  if (isDisabledByYieldbirdRequested) {
    tooltip = { content: t('yieldbirdRequested') }
  } else if (isDisabledByRequiredParams) {
    tooltip = { content: t('missingParams', { kind: paramsKind.toUpperCase() }) }
  }

  return {
    isDisabled,
    tooltip,
  }
}
