import { useMutation } from '@apollo/client'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ProtectionContext } from 'features/globalSetup/contexts/ProtectionContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { EditSchema } from '../../schemas/editSchema'
import { Data, filteringRuleFromDTO, UPDATE_FILTERING_RULE } from './api'
import { UseUpdateFilteringRule } from './types'

export const useUpdateFilteringRule = (): UseUpdateFilteringRule => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.protection.form.actions.update',
  })
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(ProtectionContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutate] = useMutation(UPDATE_FILTERING_RULE, {
    onCompleted: ({ updateFilteringRule }: Data): void => {
      setLoading(false)

      if (updateFilteringRule) {
        const { filteringRule, errors } = updateFilteringRule

        if (errors && errors.length > 0) {
          setErrors(mapGraphQLErrors(errors))
          enqueueSnackbar(t('error'), { variant: 'error' })
        } else if (filteringRule) {
          setErrors({})
          enqueueSnackbar(
            t('success', {
              filteringRuleName: filteringRuleFromDTO(filteringRule).name,
            }),
            { variant: 'success' },
          )
        }
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: EditSchema): void => {
    setLoading(true)

    mutate({
      variables: {
        input: {
          ...(data.applicableDomains === 'all' ? _.omit(data, ['domainIds']) : data),
        },
      },
    })
  }

  return { update, errors }
}
