import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { WorkspacePrebidConfiguration } from 'features/globalSetup/types/workspacePrebidConfiguration'
import { GET_WORKSPACE_PREBID_CONFIGURATION } from './api/query'
import { fromDTO } from './mapper'
import {
  UseGetWorkspacePrebidConfiguration,
  UseGetWorkspacePrebidConfigurationParams,
} from './types'

export const useGetWorkspacePrebidConfiguration = ({
  workspaceId,
}: UseGetWorkspacePrebidConfigurationParams): UseGetWorkspacePrebidConfiguration => {
  const [workspacePrebidConfiguration, setWorkspacePrebidConfiguration] =
    useState<WorkspacePrebidConfiguration>()

  useEffect(() => {
    if (workspaceId) {
      query({ variables: { workspaceId } })
    }
  }, [workspaceId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query] = useLazyQuery(GET_WORKSPACE_PREBID_CONFIGURATION, {
    onCompleted: ({ workspacePrebidConfiguration }) => {
      if (workspacePrebidConfiguration) {
        setWorkspacePrebidConfiguration(fromDTO(workspacePrebidConfiguration))
      }
    },
  })

  return { workspacePrebidConfiguration }
}
