import { Box, Grid, InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip'
import { LIST_COLUMN_BREAKPOINTS } from 'features/inventory/viewport/constants'

export const ListHeaders = (): JSX.Element => {
  const { t } = useTranslation('features/viewport')

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.name}
      >
        <InputLabel>{t('list.header.name')}</InputLabel>
      </Grid>

      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.minimumWidth}
      >
        <InputLabel sx={{ display: 'flex' }}>
          {t('list.header.minimumWidth.label')}
          <Tooltip content={t('list.header.minimumWidth.tooltip')} />
        </InputLabel>
      </Grid>

      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.bannerSizes}
      >
        <InputLabel>{t('list.header.bannerSizes')}</InputLabel>
      </Grid>

      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.nativeEnabled}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <InputLabel>{t('list.header.nativeEnabled')}</InputLabel>
      </Grid>

      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.playerSize}
      >
        <InputLabel>{t('list.header.playerSize')}</InputLabel>
      </Grid>

      <Grid
        item
        lg={1}
      >
        <Box />
      </Grid>
    </Grid>
  )
}
