import { Box } from '@mui/material'

import { ViewportFormAdd, ViewportFormEdit } from 'features/inventory/viewport/forms/ViewportForm'
import { ViewportListItem } from 'features/inventory/viewport/types/viewportListItem'
import { FormVariant } from 'types/formVariant'

export const ListRows = ({
  rows,
  variant,
}: {
  rows: ViewportListItem[]
  variant: FormVariant
}): JSX.Element => (
  <>
    {rows.map(
      (row: ViewportListItem): JSX.Element => (
        <Box
          key={row.id}
          sx={{ width: '100%' }}
        >
          {variant === 'edit' ? <ViewportFormEdit data={row} /> : <ViewportFormAdd data={row} />}
        </Box>
      ),
    )}
  </>
)
