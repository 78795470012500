import { TableCell, TableRow } from '@mui/material'

import { CellCenteredProps } from './types'

export const CellCentered = ({ columnsCount, children }: CellCenteredProps): JSX.Element => (
  <TableRow>
    <TableCell
      align='center'
      colSpan={columnsCount}
      sx={{ border: 'none' }}
    >
      {children}
    </TableCell>
  </TableRow>
)
