import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Loading from 'components/Loading'
import Paper from 'components/Paper'
import ProductAdUnitsTable from '../../components/ProductAdUnitsTable'
import { AddEditDomainContext } from '../../contexts/AddEditDomainContext'
import { Schema } from './schema'
import { BiddersSection } from './sections/BiddersSection'
import { ModulesSection } from './sections/ModulesSection'
import { TimeoutSection } from './sections/TimeoutSection'

export const Form = (): JSX.Element => {
  const { t } = useTranslation(['features/domain', 'common'])
  const { domain } = useContext(AddEditDomainContext)
  const { control } = useFormContext<Schema>()

  if (!domain) {
    return <Loading />
  }

  return (
    <>
      <Paper>
        <SectionHeader
          title={t('form.prebidStack.enabled.title')}
          subtitle={t('form.prebidStack.enabled.description')}
          titleEndAdornment={
            <Switch
              control={control}
              name='prebidStackEnabled'
            />
          }
        />
      </Paper>

      <TimeoutSection />

      <Controller
        control={control}
        name='domainBiddersAttributes'
        render={() => <BiddersSection />}
      />

      <ModulesSection />

      <Controller
        control={control}
        name='adUnitsAttributes'
        render={() => (
          <ProductAdUnitsTable
            domainId={domain.id}
            product='prebidStack'
          />
        )}
      />
    </>
  )
}
