import { useMutation } from '@apollo/client'
import { useState } from 'react'

import { useSnackbar } from '../useSnackbar'
import { UPDATE_WORKSPACE_PREBID_MODULE_ENABLED } from './api/mutation'
import {
  UseUpdateWorkspacePrebidModuleEnabled,
  UseUpdateWorkspacePrebidModuleEnabledProps,
} from './types'

export const useUpdateWorkspacePrebidModuleEnabled = ({
  initialChecked,
  id,
  prebidModuleName,
}: UseUpdateWorkspacePrebidModuleEnabledProps): UseUpdateWorkspacePrebidModuleEnabled => {
  const { snackbar } = useSnackbar(prebidModuleName)
  const [checked, setChecked] = useState<boolean>(initialChecked)

  const [mutate] = useMutation(UPDATE_WORKSPACE_PREBID_MODULE_ENABLED)

  const update = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value: boolean = event.target.checked

    const variables = { input: { id, enabled: value } }
    const { data } = await mutate({ variables })
    const { errors } = { ...data?.updateWorkspacePrebidModuleEnabled }

    if (errors && errors.length > 0) {
      snackbar(value, 'error')
    } else {
      setChecked(value)
      snackbar(value)
    }
  }

  return { checked, update }
}
