import { RouteObject } from 'react-router-dom'

import AddPage from 'features/inventory/page/pages/AddPage'
import EditPage from 'features/inventory/page/pages/EditPage'
import PageList from 'features/inventory/page/pages/PageList'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const pageRoutes: RouteObject[] = [
  {
    path: ROUTES.INVENTORY.PAGE.LIST,
    element: (
      <AuthorizedRoute
        access='PagePage-list'
        page={<PageList />}
      />
    ),
  },
  {
    path: ROUTES.INVENTORY.PAGE.ADD,
    element: (
      <AuthorizedRoute
        access='PagePage-add'
        page={<AddPage />}
      />
    ),
  },
  {
    path: ROUTES.INVENTORY.PAGE.EDIT,
    element: (
      <AuthorizedRoute
        access='PagePage-edit'
        page={<EditPage />}
      />
    ),
  },
]
