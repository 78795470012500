import { Row } from '@tanstack/react-table'
import { generatePath } from 'react-router-dom'

import { ROUTES } from 'routes'
import { UserActionsCell } from '../../Cell/UserActionsCell'
import { useDeleteUser } from '../../hooks/useDeleteUser'
import { EmployeeForTable } from '../types'

export const ActionsCell = ({ id, email }: Row<EmployeeForTable>['original']): JSX.Element => {
  const { deleteUser, loading } = useDeleteUser({
    email,
    refetchQueries: ['getEmployeesForTable'],
  })

  return (
    <UserActionsCell
      editPath={generatePath(ROUTES.USER.EMPLOYEE.EDIT, { id })}
      email={email}
      id={id}
      loading={loading}
      onDelete={() => deleteUser(id)}
    />
  )
}
