import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Row } from '@tanstack/react-table'
import { ReactNode } from 'react'

const RowExpanderCell = <T,>({ row }: { row: Row<T> }): ReactNode =>
  row.getCanExpand() && (
    <IconButton
      onClick={row.getToggleExpandedHandler()}
      data-cy='expand-icon'
      size='small'
    >
      {row.getIsExpanded() ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    </IconButton>
  )

export default RowExpanderCell
