import { FetchResult, useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { gamConnectionOrderFromDTO } from 'features/workspace/mappers/gamConnectionOrderFromDTO'
import { GamConnectionOrder } from 'features/workspace/types/gamConnectionOrder'
import { CREATE_GOOGLE_AD_MANAGER_CONNECTION } from './api/mutation'
import { Data } from './api/types'
import { UseCreateConnection } from './types'

export const useCreateConnection = (
  setConnection: Dispatch<SetStateAction<GamConnectionOrder | undefined>>,
): UseCreateConnection => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'general.googleAdManagerConnection.connectionStatus',
  })

  const [mutate, { loading }] = useMutation(CREATE_GOOGLE_AD_MANAGER_CONNECTION, {
    onCompleted: ({ createGamConnectionOrder: { gamConnectionOrder, errors } }: Data): void => {
      if (errors && errors.length > 0) {
        enqueueSnackbar(t('notification.error'), { variant: 'error' })
      } else if (gamConnectionOrder) {
        setConnection(gamConnectionOrderFromDTO(gamConnectionOrder))
      }
    },
  })

  const create = (): Promise<FetchResult<Data>> =>
    mutate({
      variables: {
        input: { workspaceId },
      },
    })

  return { create, loading }
}
