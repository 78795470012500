import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, Select, TextField } from 'components/Form'
import Paper from 'components/Paper'
import { CSManager } from 'features/workspace/types/csManager'
import { useGetCSManagersForSelect } from './hooks/useGetCSManagersForSelect'
import { CommonSchema } from './schemas/common'

export const CommonForm = (): JSX.Element => {
  const { t } = useTranslation('features/workspace')
  const { control } = useFormContext<CommonSchema>()
  const { csManagers } = useGetCSManagersForSelect()

  return (
    <Paper>
      <BoxForm>
        <TextField
          control={control}
          name='name'
          label={t('form.common.name.label')}
          placeholder={t('form.common.name.placeholder')}
        />

        <TextField
          control={control}
          name='networkCode'
          label={t('form.common.networkCode.label')}
          placeholder={t('form.common.networkCode.placeholder')}
        />

        <TextField
          control={control}
          name='crmId'
          label={t('form.common.crmId.label')}
          placeholder={t('form.common.crmId.placeholder')}
        />

        <TextField
          control={control}
          name='pipedriveLink'
          label={t('form.common.pipedriveLink.label')}
          placeholder={t('form.common.pipedriveLink.placeholder')}
        />

        <Select<CommonSchema, CSManager>
          control={control}
          label={t('form.common.csManager')}
          name='csManagerId'
          options={csManagers}
          optional
        />
      </BoxForm>
    </Paper>
  )
}
