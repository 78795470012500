import { useMutation } from '@apollo/client'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { updateUser } from 'features/user'
import { fromDTO, toDTO } from './mapper'
import { UPDATE_CURRENT_WORKSPACE } from './mutation'
import { Data, Props, UpdateStatus, UseUpdateCurrentWorkspace, Variables } from './types'

export const useUpdateCurrentWorkspace = ({ userId }: Props): UseUpdateCurrentWorkspace => {
  const { t } = useTranslation('features/workspace')
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const enqueueError = (): SnackbarKey =>
    enqueueSnackbar(t('updateCurrentWorkspace.error'), { variant: 'error' })

  const [updateCurrentWorkspace] = useMutation(UPDATE_CURRENT_WORKSPACE, {
    refetchQueries: ['getWorkspaceProducts'],
    onCompleted: ({ updateCurrentWorkspace: { user, errors } }: Data): void => {
      if (errors && errors.length > 0) {
        enqueueError()
      } else if (user !== null) {
        const { currentWorkspace, currentWorkspaceRole, workspaceProducts } = fromDTO(user)

        dispatch(updateUser({ currentWorkspace, currentWorkspaceRole, workspaceProducts }))
      }
    },
    onError: (): void => {
      enqueueError()
    },
  })

  const update = async (id?: Variables['currentWorkspaceId']): Promise<UpdateStatus> => {
    const { data, errors: gqlErrors } = await updateCurrentWorkspace({
      variables: {
        input: toDTO({
          id: userId,
          currentWorkspaceId: id,
        }),
      },
    })

    const dataErrors = data?.updateCurrentWorkspace.errors

    return (dataErrors && dataErrors.length > 0) || gqlErrors !== undefined ? 'failure' : 'success'
  }

  return { update }
}
