import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { mapper } from './api/mapper'
import { UPDATE_DOMAIN_PREBID_STACK } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateDomainPrebidStack } from './types'

export const useUpdateDomainPrebidStack = (): UseUpdateDomainPrebidStack => {
  const { t } = useTranslation('features/domain')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(AddEditDomainContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate] = useMutation(UPDATE_DOMAIN_PREBID_STACK, {
    refetchQueries: ['domain'],
    onCompleted: ({ updateDomainPrebidStack: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: Schema): Promise<FetchResult<Data>> => {
    setLoading(true)

    return mutate({
      variables: {
        input: mapper(data),
      },
    })
  }

  return { update, errors }
}
