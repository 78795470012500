import { Theme, ThemeOptions } from '@mui/material'
import { default as cratePaletteMui } from '@mui/material/styles/createPalette'

export const createPalette = (theme: Theme): ThemeOptions['palette'] =>
  cratePaletteMui({
    contrastThreshold: 4.5, // https://mui.com/material-ui/customization/palette/#accessibility

    background: { default: theme.palette.grey[100] },
    black: { main: '#222222' },
    white: { main: theme.palette.common.white },

    primary: { main: '#222222' }, // black
    secondary: { main: '#FF234F' }, // Yieldbird
    movement: { main: '#3E71FF' }, // blue
    cream: { main: '#faf3de' },

    info: { main: '#1681FF' },
    success: { main: '#22C55E' },
    warning: { main: '#FFAB00' },
    error: { main: '#F33308' },
  })
