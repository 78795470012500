import { useContext } from 'react'

import { Form as FormProvider } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditDomainContext } from '../../contexts/AddEditDomainContext'
import { Form } from './Form'
import { useUpdateDomainViewabilityTools } from './hooks/useUpdateDomainViewabilityTools'
import { Schema, schema, schemaDefault } from './schema'

export const ViewabilityToolsForm = (): JSX.Element => {
  const { domain, formId, loading, setTouched, setValid } = useContext(AddEditDomainContext)
  const { errors: apiErrors, update } = useUpdateDomainViewabilityTools()

  if (!domain) {
    return <Loading />
  }

  return (
    <FormProvider<Schema>
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(domain)}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <Form />)}
    </FormProvider>
  )
}
