import { Box } from '@mui/material'

import { Link } from 'components/Link'
import { FullLogotype } from 'features/layout/components/Logo'
import { ROUTES } from 'routes'

export const Logo = (): JSX.Element => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        p: 4,
      }}
    >
      <Link to={ROUTES.BASE.ROOT}>
        <FullLogotype />
      </Link>
    </Box>
  )
}
