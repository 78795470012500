import { z } from 'zod'

import { ViewportListItem } from 'features/inventory/viewport/types/viewportListItem'
import { stringifyAdUnitSize } from 'features/inventory/viewport/utils'
import { CommonSchema, commonSchema } from './commonSchema'
import { anySizeFilled, anySizeFilledError } from './validateSizes'

export const editSchema = z
  .object({
    id: z.string(),
  })
  .merge(commonSchema)
  .refine((data: CommonSchema): boolean => anySizeFilled(data), anySizeFilledError)

export type EditSchema = z.infer<typeof editSchema>

export const editSchemaDefault = (data: ViewportListItem): EditSchema => {
  const { bannerSizes, playerSize, ...viewport } = data

  return {
    ...viewport,
    bannerSizes: bannerSizes.map(size => stringifyAdUnitSize(size)),
    playerSize: playerSize.map(size => stringifyAdUnitSize(size)),
  }
}
