import { Form } from 'components/Form'
import { CommonForm } from './CommonForm'
import { useUpdateViewport } from './hooks/useUpdateViewport'
import { EditSchema, editSchema, editSchemaDefault } from './schemas/editSchema'
import { ViewportFormProps } from './types'

export const ViewportFormEdit = ({ data, data: { id } }: ViewportFormProps) => {
  const { errors: apiErrors, update } = useUpdateViewport()

  return (
    <Form<EditSchema>
      id={`viewport-form-edit-${id}`}
      onSubmit={(data: EditSchema) => update(data)}
      schema={editSchema}
      schemaDefaults={editSchemaDefault(data)}
      externalErrors={apiErrors}
    >
      {() => <CommonForm />}
    </Form>
  )
}
