import { SxProps, Theme } from '@mui/material'
import { ColumnMeta } from '@tanstack/react-table'

import { CELL_HEIGHT } from 'components/Table/constants'

export const cellWrapperStyles = <T>(meta?: ColumnMeta<T, unknown>): SxProps<Theme> => {
  const centered = Boolean(meta?.centered)
  const expandable = Boolean(meta?.expandable)

  return {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex',
    height: expandable ? '100%' : CELL_HEIGHT,
    justifyContent: centered ? 'center' : undefined,
    p: 2,
    textDecoration: 'none',
  }
}
