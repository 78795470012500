import { SvgIcon } from '@mui/material'

import { FlexBox } from 'components/Box'
import { BoxWithLogoProps, Logo } from './types'

export const BoxWithLogo = ({ logo, children }: BoxWithLogoProps) => {
  const Logo = ({ logo }: { logo: Logo }): JSX.Element => {
    const { svg, size } = logo

    if (!svg) {
      return <></>
    }

    return (
      <SvgIcon
        component={svg}
        inheritViewBox
        sx={{
          fontSize: size === 'small' ? 20 : 30,
          mr: 1,
        }}
      />
    )
  }

  return (
    <FlexBox axis='x'>
      <Logo logo={logo} />
      {children}
    </FlexBox>
  )
}
