import { mapDTO } from '@twistezo/ts-dto-mapper'

import { FilteringRuleForTable } from '../types'
import { FilteringRuleSliceDTO } from './types'

type From = FilteringRuleSliceDTO
type To = FilteringRuleForTable

export const fromDTO = (from: From): To => {
  return mapDTO<From, To>({ from }).transform((filteringRule: From): To => {
    const { id, name, blockedCount, enabled, applicableDomains, updatedAt } = filteringRule

    return {
      id,
      name,
      blockedCount,
      enabled,
      applicableDomains,
      updatedAt: new Date(updatedAt),
    }
  })
}
