import { mapDTO } from '@twistezo/ts-dto-mapper'

import { composeUsername } from 'features/user/utils/composeUsername'
import { WorkspaceUserForTable } from '../../../types'
import { WorkspaceUserForTableDTO } from './types'

export const fromDTO = (from: WorkspaceUserForTableDTO): WorkspaceUserForTable =>
  mapDTO<WorkspaceUserForTableDTO, WorkspaceUserForTable>({ from }).transform(dto => {
    const { email, id, firstName, lastName, currentWorkspaceRole, status, updatedAt } = dto

    return {
      role: currentWorkspaceRole || undefined,
      email,
      id,
      status,
      updatedAt: new Date(updatedAt),
      username: composeUsername({ firstName, lastName }),
    }
  })
