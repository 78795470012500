import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceSlice } from 'features/globalSetup/contexts/GeneralPageContext'
import { WorkspaceSliceDTO } from './types'

export const fromDTO = (from: WorkspaceSliceDTO) =>
  mapDTO<WorkspaceSliceDTO, WorkspaceSlice>({ from }).transform(data => ({
    ...data,
    priceGeniusAlwaysOn: data.priceGeniusAlwaysOn ?? undefined,
  }))
