import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { domainFromDTO } from 'features/inventory/mappers/domain'
import { Domain } from 'features/inventory/types/domain'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { mapGraphQLErrors } from 'providers/graphql'
import { browserRelativeRedirect } from 'providers/router/utils'
import { mergeCloseAction } from 'providers/snackbar/mergeCloseAction'
import { ROUTES } from 'routes'
import { AddSchema } from '../../schemas/addSchema'
import { CREATE_DOMAIN } from './mutation'
import { Data, UseCreateDomain } from './types'

export const useCreateDomain = (): UseCreateDomain => {
  const { workspaceId } = useWorkspaceParam()
  const navigate = useNavigate()
  const { t } = useTranslation('features/domain')
  const { t: tCommon } = useTranslation('common')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(AddEditDomainContext)
  const [errors, setErrors] = useState<FieldErrors<AddSchema>>({})

  const [mutateCreateDomain] = useMutation(CREATE_DOMAIN, {
    onCompleted: ({ createDomain: { domain, errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.create'), { variant: 'error' })
      } else if (domain) {
        setErrors({})
        navigate(generatePath(ROUTES.INVENTORY.DOMAIN.LIST, { workspaceId }))
        enqueueSuccessSnackbar(domainFromDTO(domain))
      }
    },
    onError: (): void => setLoading(false),
  })

  const enqueueSuccessSnackbar = (domain: Domain): void => {
    const { id, name } = domain

    enqueueSnackbar(t('form.success.create', { domainName: name }), {
      variant: 'success',
      action: snackbarId =>
        mergeCloseAction(
          <Button
            color='white'
            onClick={() => handleClickEdit(id)}
          >
            {tCommon('form.action.edit').toUpperCase()}
          </Button>,
          snackbarId,
        ),
    })
  }

  const handleClickEdit = (id: Domain['id']): void =>
    browserRelativeRedirect(
      generatePath(ROUTES.INVENTORY.DOMAIN.EDIT, {
        workspaceId,
        id,
        tab: 'general',
      }),
    )

  const createDomain = (data: AddSchema) => {
    setLoading(true)

    return mutateCreateDomain({ variables: { input: data } })
  }

  return { createDomain, errors }
}
