import { RouteObject } from 'react-router-dom'

import AddAdUnit from 'features/inventory/ad-unit/pages/AddAdUnit'
import AdUnitList from 'features/inventory/ad-unit/pages/AdUnitList'
import EditAdUnit from 'features/inventory/ad-unit/pages/EditAdUnit'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const adUnitRoutes: RouteObject[] = [
  {
    path: ROUTES.INVENTORY.AD_UNIT.LIST,
    element: (
      <AuthorizedRoute
        access='AdUnitPage-list'
        page={<AdUnitList />}
      />
    ),
  },
  {
    path: ROUTES.INVENTORY.AD_UNIT.ADD,
    element: (
      <AuthorizedRoute
        access='AdUnitPage-add'
        page={<AddAdUnit />}
      />
    ),
  },
  {
    path: ROUTES.INVENTORY.AD_UNIT.EDIT,
    element: (
      <AuthorizedRoute
        access='AdUnitPage-edit'
        page={<EditAdUnit />}
      />
    ),
  },
]
