import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Filter, { FilterValue } from 'components/Filter'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { BiddersTable } from './components/BiddersTable'

export const BiddersSection = (): JSX.Element => {
  const { t } = useTranslation(['features/domain', 'common'])
  const [filters, setFilters] = useState<FilterValue[]>([])

  const BiddersFilter = (
    <Filter
      id='biddersFilter'
      onChange={setFilters}
      placeholder={t('common:filter.search')}
      values={filters}
    />
  )

  return (
    <Paper>
      <TableHeader
        title={t('form.prebidStack.bidders.list.title')}
        description={t('form.prebidStack.bidders.list.description')}
        filter={BiddersFilter}
        bottomSpacing
      />
      <BiddersTable filters={filters} />
    </Paper>
  )
}
