import { kebabCase } from 'change-case'
import { generatePath } from 'react-router-dom'

import { Product, productTypeValues } from 'features/product/types/product'
import { ROUTES } from 'routes'
import { PerformanceSubItemsProps } from './types'

export const performanceSubItems = ({ ability, t, workspaceId }: PerformanceSubItemsProps) =>
  productTypeValues.map((product: Product) => ({
    title: t(`product.${product}`),
    to: generatePath(`${ROUTES.PERFORMANCE.ROOT}/${kebabCase(product)}`, { workspaceId }),
    hidden: !ability.can('show', `PerformanceProduct-${product}`),
  }))
