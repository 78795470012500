import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { FilteringRuleSliceDTO, fromDTO, GET_FILTERING_RULES } from './api'
import { FilteringRuleForTable, UseGetFilteringRules, UseGetFilteringRulesProps } from './types'

export const useGetFilteringRules = ({
  filters,
  first,
  last,
  sort,
}: UseGetFilteringRulesProps): UseGetFilteringRules => {
  const { workspaceId } = useWorkspaceParam()
  const [filteringRules, setFilteringRules] = useState<FilteringRuleForTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_FILTERING_RULES, {
    variables: {
      first,
      last,
      terms: filters,
      workspaceId,
      sort,
    },
    fetchPolicy: 'cache-and-network',

    onCompleted: ({ filteringRules: { nodes: rules, totalCount: count } }): void => {
      if (rules) {
        setFilteringRules(
          rules.map((rule: FilteringRuleSliceDTO): FilteringRuleForTable => fromDTO(rule)),
        )
      }

      setCount(count)
    },
  })

  return {
    filteringRules,
    loading,
    count,
  }
}
