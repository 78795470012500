import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceBidderDTO } from 'features/globalSetup/api/types/workspaceBidderDTO'
import { EditSchema } from '../../../schemas/editSchema'
import { Variables } from './types'

type Props = {
  from: EditSchema
  workspaceBidderId: WorkspaceBidderDTO['id']
}

export const toDTO = ({ from, workspaceBidderId }: Props) =>
  mapDTO<EditSchema, Variables>({ from }).transform((data: EditSchema): Variables => {
    const { accountType, bidCpmAdjustment, bidderCode, bidderSid, consentMode, priority } = data

    return {
      bidCpmAdjustment: bidCpmAdjustment / 100,
      bidderCode: accountType === 'yieldbird' ? undefined : bidderCode,
      bidderSid: bidderSid || null,
      consentMode,
      priority,
      workspaceBidderId,
      status: accountType === 'yieldbird' ? data.status : undefined,
      enabled: accountType === 'yieldbird' && data.status !== 'available' ? false : undefined,
    }
  })
