import { z } from 'zod'

import { userWorkspaceRoles } from 'features/user/types/userWorkspaceRole'
import { Variables } from './types'

export const schema = z.object({
  email: z.string().email(),
  role: z.enum(userWorkspaceRoles),
  userId: z.string(),
  workspaceId: z.string(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({ user, workspaceId }: Variables): Schema => {
  const { id: userId, currentWorkspaceRole } = user

  if (!currentWorkspaceRole) {
    throw new Error('User workspace role should exist')
  }

  return {
    ...user,
    role: currentWorkspaceRole,
    userId,
    workspaceId,
  }
}
