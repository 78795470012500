import { CSSObject } from '@mui/material'

import { ProductStatusColor } from './types'

export const productStatusColor = ({ status, theme }: ProductStatusColor): CSSObject['color'] => {
  const {
    palette: {
      error: { main: error },
      primary: { main: primary },
      success: { main: success },
      warning: { main: warning },
    },
  } = theme

  switch (status) {
    case 'trial':
      return error
    case 'trialInSetup':
      return warning
    case 'proInSetup':
      return warning
    case 'pro':
      return success
    default:
      return primary
  }
}
