import { FormControl, Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BoxForm } from 'components/Form'
import Paper from 'components/Paper'
import { UserWorkspaceForForm } from '../types'
import { AddFormContainer } from './AddFormContainer'
import { NextFormContext, NextFormContextType } from './contexts/NextFormContext'
import { UserWorkspacesContext, UserWorkspacesContextType } from './contexts/UserWorkspacesContext'
import { EditFormContainer } from './EditFormContainer'
import { UserEditPublisherFormProps, UserWorkspaceForFormSlice } from './types'

export const UserEditPublisherForm = ({ user }: UserEditPublisherFormProps) => {
  const { t } = useTranslation('features/user')
  const [userWorkspaces, setUserWorkspaces] = useState<UserWorkspaceForForm[]>(user.userWorkspaces)
  const [showNextInputs, setShowNextInputs] = useState(false)

  const append = (userWorkspace: UserWorkspaceForForm) => {
    setUserWorkspaces([...userWorkspaces, userWorkspace])
  }

  const remove = (workspaceId: UserWorkspaceForFormSlice['workspaceId']) => {
    setUserWorkspaces(
      userWorkspaces.filter(
        (selectedUserWorkspace: UserWorkspaceForForm): boolean =>
          selectedUserWorkspace.workspaceId !== workspaceId,
      ),
    )
  }

  const update = (updatedUserWorkspace: UserWorkspaceForFormSlice) => {
    const userWorkspace: UserWorkspaceForFormSlice | undefined = userWorkspaces.find(
      (userWorkspaceTemp: UserWorkspaceForFormSlice) =>
        userWorkspaceTemp.workspaceId === updatedUserWorkspace.workspaceId,
    )

    if (userWorkspace) {
      userWorkspace.role = updatedUserWorkspace.role

      setUserWorkspaces(userWorkspaces)
    }
  }

  const userWorkspaceContextProps: UserWorkspacesContextType = {
    userWorkspaces,
    append,
    remove,
    update,
  }

  const addFormContextProps: NextFormContextType = {
    showNextInputs,
    setShowNextInputs,
  }

  return (
    <UserWorkspacesContext.Provider value={userWorkspaceContextProps}>
      <Paper>
        <BoxForm>
          <Stack
            direction='row'
            gap={2}
          >
            <FormControl>{t('form.publisher.common.workspaceId.label')}</FormControl>
            <FormControl>{t('form.publisher.common.role.label')}</FormControl>
          </Stack>

          {userWorkspaces.map(
            (userWorkspace: UserWorkspaceForForm, index: number): JSX.Element => (
              <EditFormContainer
                key={`EditFormContainer-${userWorkspace.workspaceId}`}
                id={index}
                userWorkspace={userWorkspace}
              />
            ),
          )}

          <NextFormContext.Provider value={addFormContextProps}>
            <AddFormContainer user={user} />
          </NextFormContext.Provider>
        </BoxForm>
      </Paper>
    </UserWorkspacesContext.Provider>
  )
}
