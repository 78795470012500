import {
  AccessorKeyColumnDef,
  ColumnDef,
  GroupColumnDef,
  RowData,
  TableMeta,
} from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { CellData, SubRows } from './types'

export const getSubRowsHandler = <T extends RowData>(row: RowData): T[] | undefined => {
  const subRows: T[] | undefined = (row as SubRows<T>)?.subRows

  return subRows !== undefined && subRows.length > 0 ? subRows : []
}

const composeRowData = <T extends RowData>(data: CellData, row: T): T => ({
  ...row!,
  [data.columnId]: data.value,
})

const updateRows = <T extends RowData>(data: CellData[], row: T, index: number): T => {
  const item: CellData | undefined = data.find((row: CellData): boolean => row.rowIndex === index)

  return item ? composeRowData(item, row) : row
}

export const updateData =
  <T extends RowData>(onUpdateData?: Dispatch<SetStateAction<T[]>>): TableMeta<T>['updateData'] =>
  (data: CellData[]): void =>
    onUpdateData?.((prevData: T[]) =>
      prevData.map((row: T, index: number): T => updateRows(data, row, index)),
    )

export const isGroupColumn = <T>(c: ColumnDef<T>): c is GroupColumnDef<T, unknown> => {
  const nestedColumns = (c as GroupColumnDef<T, unknown>).columns
  return nestedColumns !== undefined && nestedColumns.length > 0
}

export const collectLeftPinnedColumns = <T>(columns: ColumnDef<T, unknown>[]): string[] => {
  type Column = ColumnDef<T, unknown>
  type GroupColumn = GroupColumnDef<T, unknown>
  type ColumnAccessor = AccessorKeyColumnDef<T, unknown>

  const collectNested = (c: Column): string[] => {
    const { columns } = c as Required<GroupColumn>

    const grouped = columns.map((c: Column) => {
      const { accessorKey } = c as ColumnAccessor
      return accessorKey as string
    })

    return grouped || []
  }

  const collect = (c: Column): string => {
    const { id, accessorKey } = c as ColumnAccessor
    return id || (accessorKey as string)
  }

  const onlyPinned: Column[] = columns.filter((c: Column) => Boolean(c.meta?.isPinned))

  const pinnedWithNestedGroups: string[] = onlyPinned.flatMap((c: Column): string | string[] =>
    isGroupColumn(c) ? collectNested(c) : collect(c),
  )

  const dashed: string[] = pinnedWithNestedGroups.map((e: string) => e.replace('.', '_'))

  return dashed
}
