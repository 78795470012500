import { adUnitsAttributesMapper } from 'features/inventory/domain/components/ProductAdUnitsTable/utils'
import { Schema } from '../../schema'
import { Variables } from './types'

export const mapper = (data: Schema): Variables => {
  const { adThreshold, adUnitsAttributes, refreshInterval } = data

  return {
    ...data,
    adThreshold: adThreshold / 100,
    refreshInterval: refreshInterval * 1000,
    adUnitsAttributes: adUnitsAttributesMapper<'refresherEnabled'>(
      adUnitsAttributes,
      'refresherEnabled',
    ),
  }
}
