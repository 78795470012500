import { DevTool } from '@hookform/devtools'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { LeaveDialog } from './components/LeaveDialog'
import { FormProps } from './types'

export const Form = <T extends FieldValues>({
  children,
  devTools,
  externalErrors,
  id,
  onSubmit,
  onTouched,
  onValid,
  schema,
  schemaDefaults,
  withLeaveDialog = true,
}: FormProps<T>) => {
  const methods = useForm<z.infer<typeof schema>>({
    defaultValues: schemaDefaults,
    mode: 'onChange',
    resolver: zodResolver(schema),
    errors: externalErrors,
  })

  const {
    control,
    formState: { isDirty, isSubmitSuccessful, isSubmitted, isSubmitting, isValid },
    getValues,
    handleSubmit,
    reset,
  } = methods

  // Pass `touched` state for use outside the form
  useEffect(() => {
    if (onTouched !== undefined) {
      onTouched(isDirty)
    }
  }, [isDirty]) // eslint-disable-line react-hooks/exhaustive-deps

  // Pass `valid` state for use outside the form
  useEffect(() => {
    if (onValid !== undefined) {
      onValid(isValid)
    }
  }, [isValid]) // eslint-disable-line react-hooks/exhaustive-deps

  // Reset `isDirty` state after successful submit
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues(), {
        keepErrors: true,
      })
    }
  }, [isSubmitSuccessful]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {withLeaveDialog && <LeaveDialog {...{ isDirty, isSubmitted, isSubmitting }} />}

      <FormProvider {...methods}>
        <form
          id={id}
          onSubmit={handleSubmit(onSubmit)}
        >
          {children(methods)}
        </form>

        {devTools && <DevTool control={control} />}
      </FormProvider>
    </>
  )
}
