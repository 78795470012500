import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { Data, UPDATE_DOMAIN_UNFILLED_RECOVERY } from './api'
import { mapper } from './mapper'
import { UseUpdateDomainUnfilledRecovery } from './types'

export const useUpdateDomainUnfilledRecovery = (): UseUpdateDomainUnfilledRecovery => {
  const { t } = useTranslation('features/domain')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(AddEditDomainContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutateUpdateDomainUnfilledRecovery] = useMutation(UPDATE_DOMAIN_UNFILLED_RECOVERY, {
    onCompleted: ({ updateDomainUnfilledRecovery: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const updateDomainUnfilledRecovery = (data: Schema) => {
    setLoading(true)

    return mutateUpdateDomainUnfilledRecovery({
      variables: {
        input: mapper(data),
      },
    })
  }

  return { updateDomainUnfilledRecovery, errors }
}
