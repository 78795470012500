import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { UserSlice } from 'features/user/forms/UserEmployeeForm/types'
import { GET_EMPLOYEE, userFromDTO } from './api'
import { UseGetEmployeeProps, UseGetEmployeeType } from './types'

export const useGetEmployee = ({ id }: UseGetEmployeeProps): UseGetEmployeeType => {
  const [user, setUser] = useState<UserSlice>()

  useEffect(() => {
    if (id) {
      query({ variables: { id } })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_EMPLOYEE, {
    onCompleted: ({ node: user }) => {
      if (user) {
        setUser(userFromDTO(user))
      }
    },
  })

  return { user, loading }
}
