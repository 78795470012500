import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as RadioGroupRoot,
  Stack,
} from '@mui/material'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import { FlexBox } from 'components/Box'
import Tooltip from 'components/Tooltip'
import { RadioGroupOption, RadioGroupProps } from './types'

export const RadioGroup = <T extends FieldValues>({
  control,
  disabled,
  label,
  name,
  options,
  tooltip: tooltipProps,
  ...radioGroupProps
}: RadioGroupProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name as FieldPath<T>}
    render={({ field, fieldState: { error } }) => (
      <FormControl error={Boolean(error)}>
        <FlexBox axis='x'>
          <FormLabel id={name}>{label}</FormLabel>

          {tooltipProps && <Tooltip {...tooltipProps} />}
        </FlexBox>

        <RadioGroupRoot
          {...field}
          {...radioGroupProps}
          id={name}
        >
          {options.map(
            (option: RadioGroupOption): JSX.Element => (
              <Box key={option.value}>
                <Stack
                  direction='row'
                  sx={{ mr: 2 }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    disabled={disabled}
                    label={option.label}
                    value={option.value}
                  />

                  {option.tooltip && (
                    <FlexBox axis='xy'>
                      <Tooltip {...option.tooltip} />
                    </FlexBox>
                  )}
                </Stack>

                {field.value === option.value && option.onSelectedContent && (
                  <Box py={1}>{option.onSelectedContent}</Box>
                )}
              </Box>
            ),
          )}
        </RadioGroupRoot>

        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    )}
  />
)
