import i18next from 'i18next'
import { z } from 'zod'

import { validateSizes } from './validateSizes'

const sizeError = i18next.t('features/viewport:list.error.sizeFormat')

export const commonSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  minimumWidth: z.number().min(0),
  bannerSizes: z
    .array(z.string())
    .refine((sizes: string[]): boolean => validateSizes(sizes), sizeError),
  nativeEnabled: z.boolean(),
  playerSize: z
    .array(z.string())
    .max(1)
    .refine((sizes: string[]): boolean => validateSizes(sizes), sizeError),
})

export type CommonSchema = z.infer<typeof commonSchema>
