import { Divider } from '@mui/material'

import { Form as FormProvider } from 'components/Form'
import Loading from 'components/Loading'
import Paper from 'components/Paper'
import { AdminOptionFormHeader } from 'features/performance'
import { DOCUMENTATION_URL } from './constants'
import { Form } from './Form'
import { defaults, Schema, schema } from './schema'
import { FormContainerProps } from './types'
import { useUpdateFinanceReportUrl } from './useUpdateFinanceReportUrl'

export const FormContainer = ({ data }: FormContainerProps): JSX.Element => {
  const { errors, loading, update } = useUpdateFinanceReportUrl()

  return (
    <FormProvider<Schema>
      id='PerformanceForm'
      schema={schema}
      schemaDefaults={defaults(data)}
      onSubmit={update}
      externalErrors={errors}
    >
      {() => (
        <Paper sx={{ backgroundColor: theme => theme.palette.cream.main }}>
          <AdminOptionFormHeader
            documentationUrl={DOCUMENTATION_URL}
            loading={loading}
          />

          <Divider sx={{ marginBottom: 3 }} />

          {loading ? <Loading /> : <Form />}
        </Paper>
      )}
    </FormProvider>
  )
}
