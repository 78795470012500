import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { DELETE_AD_UNIT } from './api/mutation'
import { UseDeleteAdUnit, UseDeleteAdUnitProps } from './types'

export const useDeleteAdUnit = ({ path }: UseDeleteAdUnitProps): UseDeleteAdUnit => {
  const { t } = useTranslation('features/adUnit')
  const { enqueueSnackbar } = useSnackbar()

  const [mutateDeleteAdUnit, { loading }] = useMutation(DELETE_AD_UNIT, {
    refetchQueries: ['getAdUnitsForTable'],
    onCompleted: ({ deleteAdUnit }): void => {
      if (deleteAdUnit) {
        const { errors } = deleteAdUnit

        if (errors && errors.length > 0) {
          enqueueSnackbar(
            t('delete.action.error', { adUnitName: path, interpolation: { escapeValue: false } }),
            { variant: 'error' },
          )
        }
      } else {
        enqueueSnackbar(
          t('delete.action.success', { adUnitName: path, interpolation: { escapeValue: false } }),
          { variant: 'success' },
        )
      }
    },
  })

  const deleteAdUnit: UseDeleteAdUnit['deleteAdUnit'] = id =>
    mutateDeleteAdUnit({ variables: { input: { id } } })

  return { deleteAdUnit, loading }
}
