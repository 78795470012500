import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { mapGraphQLErrors } from 'providers/graphql'
import { UserWorkspacesContext } from '../../contexts/UserWorkspacesContext'
import { RemoveSchema } from '../../schemas'
import { Data, REMOVE_CUSTOMER_FROM_WORKSPACE } from './api'
import { UseRemovePublisherFromWorkspace, UseRemovePublisherFromWorkspaceProps } from './types'

export const useRemovePublisherFromWorkspace = ({
  workspaceId,
}: UseRemovePublisherFromWorkspaceProps): UseRemovePublisherFromWorkspace => {
  const { t } = useTranslation('features/user')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<RemoveSchema>>({})
  const { remove } = useContext(UserWorkspacesContext)

  const [mutateRemovePublisherFromWorkspace] = useMutation(REMOVE_CUSTOMER_FROM_WORKSPACE, {
    onCompleted: ({ removePublisherFromWorkspace: { errors } }: Data): void => {
      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.publisher.remove.error'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.publisher.remove.success'), {
          variant: 'success',
        })

        remove(workspaceId)
      }
    },
  })

  const removePublisherFromWorkspace = (data: RemoveSchema) =>
    mutateRemovePublisherFromWorkspace({ variables: { input: data } })

  return { removePublisherFromWorkspace, errors }
}
