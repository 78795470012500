import { CellContext } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'

import { Select } from 'components/Form'
import { useGetPrioritiesForSelect } from 'features/globalSetup'
import { DomainBidderPriority } from 'features/globalSetup/types/bidderPriority'
import { DomainBidderSlice } from 'features/inventory/domain/forms/PrebidStackForm'
import { Schema } from 'features/inventory/domain/forms/PrebidStackForm/schema'
import { useAbility } from 'providers/casl'

export const PriorityCell = ({
  row: {
    original: { id, workspaceBidderPriority },
  },
}: CellContext<DomainBidderSlice, unknown>): JSX.Element => {
  const ability = useAbility()
  const { control } = useFormContext<Schema>()

  const priorities = useGetPrioritiesForSelect({ workspaceBidderPriority, variant: 'domainBidder' })

  return (
    <Select<Schema, DomainBidderPriority>
      control={control}
      name={`domainBiddersAttributes.${id}.priority`}
      disabled={!ability.can('update', 'GlobalSetupFeature-bidder')}
      variant='outlined'
      size='small'
      options={priorities}
    />
  )
}
