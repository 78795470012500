import { enqueueSnackbar, VariantType } from 'notistack'
import { useTranslation } from 'react-i18next'

import { FilteringRule } from 'features/globalSetup/types/filteringRule'

export const useSnackbar = (filteringRule: FilteringRule['name']) => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.protection.list.toggleFilteringRule',
  })

  const snackbar = (enabled: boolean, variant?: VariantType): void => {
    const tParams = {
      filteringRule,
      action: enabled ? 'enabled' : 'disabled',
    }

    if (variant === 'error') {
      enqueueSnackbar(t('error', tParams), { variant })
    } else {
      enqueueSnackbar(t('success', tParams), {
        variant: enabled ? 'success' : 'warning',
      })
    }
  }

  return { snackbar }
}
