import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAuthenticate } from 'features/user/hooks/useAuthenticate'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { CONFIRM_USER, Data } from './api'
import { UseConfirmUser } from './types'

export const useConfirmUser = (): UseConfirmUser => {
  const { t } = useTranslation('features/user')
  const { authenticate } = useAuthenticate()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate] = useMutation(CONFIRM_USER, {
    onCompleted: ({ confirmUser: { user, accessToken, refreshToken, errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
      } else if (user && accessToken && refreshToken) {
        setErrors({})
        authenticate({ user, accessToken, refreshToken })
        enqueueSnackbar(t('completeSetup.form.success'), {
          variant: 'success',
        })
      }
    },
    onError: (): void => setLoading(false),
  })

  const confirm = (data: Schema) => {
    setLoading(true)

    return mutate({ variables: { input: data } })
  }

  return { confirm, errors, loading }
}
