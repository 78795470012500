import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { SelectOption } from 'components/Select'
import { CommonForm } from 'features/globalSetup/forms/Prebid/Bidders/CommonForm'
import { useGetBiddersForSelect } from 'features/globalSetup/forms/Prebid/Bidders/sections/hooks/useGetBiddersForSelect'
import { BidderSlice } from 'features/globalSetup/forms/Prebid/Bidders/types'
import { EditSchema } from './schemas/editSchema'

export const EditBidderFormBody = (): JSX.Element => {
  const { getValues, reset, watch } = useFormContext<EditSchema>()

  const { accountType, bidderId } = watch()
  const bidders = useGetBiddersForSelect(accountType)

  const updateBidderDefaults = (): void => {
    if (bidders.length > 0) {
      const bidder: BidderSlice | undefined = bidders.find(
        (bidder: SelectOption<BidderSlice>) => bidder.value === bidderId,
      )?.rawData

      reset({ ...getValues(), bidderId: bidder?.id ?? '', bidderCode: bidder?.code ?? '' })
    }
  }

  useEffect(() => {
    updateBidderDefaults()
  }, [bidderId]) // eslint-disable-line react-hooks/exhaustive-deps

  return <CommonForm variant='edit' />
}
