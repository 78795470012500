import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'

import { demandToAccount } from 'features/globalSetup/api/mappers/demandToAccount'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { mapGraphQLErrors } from 'providers/graphql'
import { ROUTES } from 'routes'
import { commonSchemaParametersToDTO } from '../../components/ParamsFormBox/mappers/commonSchemaParametersToDTO'
import { AddSchema } from '../../schemas/addSchema'
import { CREATE_PREBID_MODULE } from './api/mutation'
import { Data } from './api/types'
import { UseCreatePrebidModule, UseCreatePrebidModuleProps } from './types'
import { useSnackbar } from './useSnackbar'

export const useCreatePrebidModule = ({
  moduleType,
  setLoading,
}: UseCreatePrebidModuleProps): UseCreatePrebidModule => {
  const { workspaceId } = useWorkspaceParam()
  const navigate = useNavigate()

  const { snackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<AddSchema>>({})

  const [mutate] = useMutation(CREATE_PREBID_MODULE, {
    onCompleted: ({
      createWorkspacePrebidModule: { workspacePrebidModule, errors },
    }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors) as FieldErrors<AddSchema>)

        snackbar('error', moduleType)
      } else if (workspacePrebidModule) {
        setErrors({})

        snackbar('success', moduleType, demandToAccount(workspacePrebidModule.prebidModule.demand))

        navigate(
          generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.ROOT, {
            workspaceId,
            tab: moduleType,
          }),
        )
      }
    },
    onError: (): void => setLoading(false),
  })

  const create = (data: AddSchema) => {
    setLoading(true)

    return mutate({
      variables: {
        input: {
          prebidModuleId: data.prebidModuleId,
          params: commonSchemaParametersToDTO(data),
        },
      },
    })
  }

  return { create, errors }
}
