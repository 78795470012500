import { mapDTO } from '@twistezo/ts-dto-mapper'

import { ViewportListItemDTO } from 'features/inventory/viewport/api/types/viewportListItemDTO'
import { ViewportListItem } from 'features/inventory/viewport/types/viewportListItem'

export const viewportListItemFromDTO = (from: ViewportListItemDTO): ViewportListItem =>
  mapDTO<ViewportListItemDTO, ViewportListItem>({ from }).transform(viewportDTO => ({
    ...viewportDTO,
    bannerSizes: viewportDTO.bannerSizes ? viewportDTO.bannerSizes : [],
    playerSize: viewportDTO.playerSize ? viewportDTO.playerSize : [],
  }))
