import i18next from 'i18next'

import { CommonSchema } from './commonSchema'

export const anySizeFilled = ({ bannerSizes, nativeEnabled, playerSize }: CommonSchema): boolean =>
  bannerSizes.length > 0 || nativeEnabled === true || playerSize.length > 0

export const anySizeFilledError = {
  message: i18next.t('features/viewport:list.error.sizeRequired'),
  path: ['bannerSizes'],
}

export const validateSizes = (sizes: string[]): boolean => sizes.every(validateSize)

export const validateSize = (size: string): boolean => /^\d+x\d+$/.test(size)
