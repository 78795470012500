import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { commonSchemaParametersToDTO } from '../../components/ParamsFormBox/mappers/commonSchemaParametersToDTO'
import { EditSchema } from '../../schemas/editSchema'
import { UPDATE_WORKSPACE_PREBID_MODULE } from './api/mutation'
import { Data } from './api/types'
import { Props, UseUpdateWorkspacePrebidModule } from './types'

export const useUpdateWorkspacePrebidModule = ({
  moduleType,
}: Props): UseUpdateWorkspacePrebidModule => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: `prebid.modules.${moduleType}.edit`,
  })
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(PrebidModulesContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutate] = useMutation(UPDATE_WORKSPACE_PREBID_MODULE, {
    onCompleted: ({
      updateWorkspacePrebidModule: { workspacePrebidModule, errors },
    }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors) as FieldErrors<EditSchema>)
        enqueueSnackbar(t('error'), { variant: 'error' })
      } else if (workspacePrebidModule) {
        setErrors({})
        enqueueSnackbar(t('success'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update: UseUpdateWorkspacePrebidModule['update'] = (id, data) => {
    setLoading(true)

    return mutate({
      variables: {
        input: {
          id,
          params: commonSchemaParametersToDTO(data),
        },
      },
    })
  }

  return { update, errors }
}
