import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { AdUnitParameter } from 'features/globalSetup/types/adUnitBidder'
import { AdUnitParamInputCell } from '../../cells/AdUnitParamInputCell'
import { AdUnitsForTable } from '../../types'
import { UseParamsColumnsProps } from './types'

export const useParamsColumns = ({
  paramsInitial,
  kind,
}: UseParamsColumnsProps): ColumnDef<AdUnitsForTable> => {
  const { t } = useTranslation(['features/globalSetup'], {
    keyPrefix: 'prebid.bidders.edit.bidParamsAndConnection.adUnitsTable',
  })

  if (paramsInitial === undefined) {
    return {
      id: 'hidden',
      meta: { hidden: true },
    }
  }

  const columns: ColumnDef<AdUnitsForTable>[] = Object.values(paramsInitial).map(
    (value: AdUnitParameter): ColumnDef<AdUnitsForTable> => {
      const { label, name, required, tooltip } = value

      let header = label
      if (required) {
        header += '*'
      }

      return {
        header,
        accessorKey: `${kind}Params.${name}`,
        cell: context => (
          <AdUnitParamInputCell
            cellContext={context}
            kind={kind}
          />
        ),
        meta: { tooltip },
      }
    },
  )

  return {
    header: t(`params.group`, { kind: kind.toUpperCase() }),
    columns,
  }
}
