import i18next from 'i18next'
import { z } from 'zod'

import { pubAdsServiceRefresh } from 'features/inventory/domain/types/pubAdsServiceRefresh'
import { targetingActionTypes } from 'features/inventory/domain/types/targetingActionType'
import { Domain } from 'features/inventory/types/domain'
import { Ability } from 'providers/casl'
import { commonSchema } from './commonSchema'

const failsafeTimeoutError = i18next.t(
  'features/domain:form.general.advancedSettings.customFailsafeTimeoutEnabled.failsafeTimeout.error',
)

export const editSchema = z
  .object({
    id: z.string(),
    customCodeAttributes: z
      .object({
        codeAfter: z.string().optional(),
        codeBefore: z.string().optional(),
        comment: z.string().optional(),
      })
      .optional(),
    customCodeEnabled: z.boolean(),
    customFailsafeTimeoutEnabled: z.boolean(),
    failsafeTimeout: z.number().min(200, failsafeTimeoutError).max(20000, failsafeTimeoutError),
    margin: z.number().min(0),
    mobileScaling: z.number().min(0).multipleOf(0.01),
    overriddenByYieldbird: z.boolean().optional(),
    pubAdsServiceRefresh: z.enum(pubAdsServiceRefresh),
    targetingActionType: z.enum(targetingActionTypes),
  })
  .merge(commonSchema)

export type EditSchema = z.infer<typeof editSchema>

export const editSchemaDefault = (
  {
    id,
    name,
    customCode: { codeAfter, codeBefore, comment },
    customCodeEnabled,
    customFailsafeTimeoutEnabled,
    failsafeTimeout,
    margin,
    mobileScaling,
    overriddenByYieldbird,
    pubAdsServiceRefresh,
    targetingActionType,
  }: Domain,
  ability: Ability,
): EditSchema => ({
  id,
  name,
  customCodeAttributes: {
    codeAfter,
    codeBefore,
    comment,
  },
  customCodeEnabled,
  customFailsafeTimeoutEnabled,
  failsafeTimeout,
  margin: margin ?? 200,
  mobileScaling: mobileScaling ?? 2.0,
  overriddenByYieldbird: ability.can('read', 'DomainField-overriddenByYieldbird')
    ? overriddenByYieldbird
    : undefined,
  pubAdsServiceRefresh: pubAdsServiceRefresh ?? 'controlledByYieldbird',
  targetingActionType: targetingActionType ?? 'onPageLoad',
})
