import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { DELETE_DOMAIN } from './mutation'
import { UseDeleteDomain, UseDeleteDomainProps } from './types'

export const useDeleteDomain = ({ name }: UseDeleteDomainProps): UseDeleteDomain => {
  const { t } = useTranslation('features/domain')
  const { enqueueSnackbar } = useSnackbar()

  const [mutateDeleteDomain, { loading }] = useMutation(DELETE_DOMAIN, {
    refetchQueries: ['getDomains'],
    onCompleted: ({ deleteDomain }): void => {
      if (deleteDomain) {
        const { errors } = deleteDomain

        if (errors && errors.length > 0) {
          enqueueSnackbar(t('delete.action.error', { domainName: name }), { variant: 'error' })
        }
      } else {
        enqueueSnackbar(t('delete.action.success', { domainName: name }), { variant: 'success' })
      }
    },
  })

  const deleteDomain: UseDeleteDomain['deleteDomain'] = id =>
    mutateDeleteDomain({ variables: { input: { id } } })

  return { deleteDomain, loading }
}
