import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Dialog from 'components/Dialog'
import { useUpdateProduct } from '../../hooks/useUpdateProduct'
import { DialogProps } from '../../types'

export const RequestDialog = ({
  newStatus,
  isOpen,
  setOpen,
  workspaceProduct,
}: DialogProps): JSX.Element => {
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/product')

  const { id, product, status: currentStatus } = workspaceProduct
  const { update } = useUpdateProduct(workspaceProduct)

  const handleConfirm = (): void => {
    update({ id, status: newStatus })
    setOpen(false)
  }

  const Actions = (): JSX.Element => (
    <Button
      onClick={handleConfirm}
      variant='contained'
    >
      {tC('actions.confirm')}
    </Button>
  )

  return (
    <Dialog
      actions={<Actions />}
      content={t('dialog.status.other.description', {
        product: t(`product.${product}`),
        currentStatus: t(`status.${currentStatus}`),
        newStatus: t(`status.${newStatus}`),
      })}
      isOpen={isOpen}
      setOpen={setOpen}
      title={t('dialog.status.other.title')}
    />
  )
}
