import { memo } from 'react'

import { Cell } from './Cell'
import { CellProps } from './types'

export const MemoizedCell = memo(
  Cell,
  <T,>(prevProps: CellProps<T>, nextProps: CellProps<T>): boolean => {
    const [
      {
        cell: { id: prevId, getValue: prevValue },
      },
      {
        cell: { id: nextId, getValue: nextValue },
      },
    ] = [prevProps, nextProps]

    if (prevId.includes('expander') || nextId.includes('expander')) {
      // do not memoize expander for sub-rows
      return false
    }

    return prevId === nextId && prevValue() === nextValue()
  },
) as typeof Cell
