import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SupplyChainForTable } from '../types'
import { SupplyChainSliceDTO } from './types'

type From = SupplyChainSliceDTO
type To = SupplyChainForTable

export const fromDTO = (from: From): To => {
  return mapDTO<From, To>({ from }).transform((supplyChain: From): To => {
    const { id, name, asi, defaultSid, updatedAt } = supplyChain
    return {
      id,
      name,
      asi,
      defaultSid,
      updatedAt: new Date(updatedAt),
    }
  })
}
