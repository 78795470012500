import { AbilityBuilder, AbilityClass, PureAbility } from '@casl/ability'

import { User } from 'features/user'
import { productByStatus } from './abilities/productByStatus'
import { userByGroup } from './abilities/userByGroup'
import { userByRole } from './abilities/userByRole'
import { userByWorkspaceRole } from './abilities/userByWorkspaceRole'
import { Ability } from './types/ability'

export const abilityBuilder = (user: User | null): Ability => {
  const { can, build } = new AbilityBuilder(PureAbility as AbilityClass<Ability>)

  if (user) {
    userByGroup(user, can)
    userByRole(user, can)
    userByWorkspaceRole(user, can)
    productByStatus(user, can)
  }

  return build()
}
