import { mapDTO } from '@twistezo/ts-dto-mapper'

import { AdUnitForPageTransferList } from '../adUnitForPageTransferList'
import { AdUnitForPageTransferListDTO } from './types'

export const adUnitForPageTransferListFromDTO = (
  from: AdUnitForPageTransferListDTO,
): AdUnitForPageTransferList =>
  mapDTO<AdUnitForPageTransferListDTO, AdUnitForPageTransferList>({ from }).transform(
    adUnitDTO => ({
      value: adUnitDTO.id,
      label: adUnitDTO.path,
    }),
  )
