import { Stack, TextField as TextFieldRoot } from '@mui/material'
import { ChangeEvent } from 'react'
import { Controller, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'

import Tooltip from 'components/Tooltip'
import { NumberFieldProps } from './types'

export const NumberField = <T extends FieldValues>({
  control,
  name,
  tooltip: tooltipProps,
  useTooltipForErrors,
  type = 'integer',
  ...standardNumberFieldProps
}: NumberFieldProps<T>): JSX.Element => {
  const handleInputOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<T, FieldPath<T>>,
  ) => void field.onChange(Number(event.target.value) || 0)

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field, fieldState: { error } }) => {
        const hasError: boolean = Boolean(error)
        const errorMessage: string = error?.message || ''

        return (
          <Stack direction='row'>
            <TextFieldRoot
              {...field}
              {...standardNumberFieldProps}
              inputProps={{ step: type === 'integer' ? 1 : 0.01 }}
              id={name}
              onChange={event => handleInputOnChange(event, field)}
              sx={{
                ...standardNumberFieldProps.sx,
              }}
              type='number'
              value={field.value.toString()}
              error={hasError}
              helperText={!useTooltipForErrors && errorMessage}
            />

            {tooltipProps && <Tooltip {...tooltipProps} />}

            {hasError && useTooltipForErrors && (
              <Tooltip
                content={errorMessage}
                variant='error'
              />
            )}
          </Stack>
        )
      }}
    />
  )
}
