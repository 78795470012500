import { useContext } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'

import { BoxForm, NumberField } from 'components/Form'
import TextField, { TextFieldProps } from 'components/Form/TextField'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { WorkspacePrebidModuleParameter } from 'features/globalSetup/types/workspacePrebidModule'
import { ParamsSchema } from '../../schemas/paramsSchema'
import { errorProps } from '../../utils'
import { ParamsFormBoxProps } from './types'
import { prepareValue } from './utils'

export const ParamsFormBox = ({ parametersSchema }: ParamsFormBoxProps): JSX.Element => {
  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<ParamsSchema>()

  const parameters: WorkspacePrebidModuleParameter[] = workspacePrebidModule?.parameters || []

  return (
    <BoxForm>
      {parametersSchema.map((prebidModuleParameter: PrebidModuleParameter): JSX.Element => {
        const { name, type, label, tooltip } = prebidModuleParameter
        const errorAccessor: FieldError | undefined = errors?.[name]

        register(name, { value: prepareValue(name, parameters) })

        const props: TextFieldProps<ParamsSchema> = {
          key: name,
          control,
          name: name,
          label,
          tooltip,
        }

        return type === 'string' ? (
          <TextField
            {...props}
            {...(errorAccessor && errorProps(errorAccessor))}
          />
        ) : (
          <NumberField
            {...props}
            type={type}
            {...(errorAccessor && errorProps(errorAccessor))}
          />
        )
      })}
    </BoxForm>
  )
}
