import { ContentCopy as ContentCopyIcon } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ContentCopyProps } from './types'

export const ContentCopy = ({ text }: ContentCopyProps): JSX.Element => {
  const { t } = useTranslation('features/performance')
  const [title, setTitle] = useState<string>('')

  const handleCopyToClipboard = () => {
    window.navigator.clipboard.writeText(text)
    setTitle(t('components.contentCopy.copied'))
  }

  return (
    <Tooltip
      title={title}
      placement='top'
    >
      <ContentCopyIcon
        onClick={handleCopyToClipboard}
        sx={{ cursor: 'copy' }}
      />
    </Tooltip>
  )
}
