import { adUnitsAttributesMapper } from 'features/inventory/domain/components/ProductAdUnitsTable/utils'
import { DomainBidderSlice } from 'features/inventory/domain/forms/PrebidStackForm'
import { Schema } from '../../../schema'
import { DomainBidderAttributeDTO, Variables } from './types'

export const mapper = (data: Schema): Variables => {
  const { adUnitsAttributes, domainBiddersAttributes, ...result } = data

  return {
    ...result,
    domainBiddersAttributes: domainBiddersAttributesSchemaToDTO(domainBiddersAttributes),
    adUnitsAttributes: adUnitsAttributesMapper<'prebidStackEnabled'>(
      adUnitsAttributes,
      'prebidStackEnabled',
    ),
  }
}

const domainBiddersAttributesSchemaToDTO = (
  domainBiddersAttributes: Schema['domainBiddersAttributes'],
): DomainBidderAttributeDTO[] =>
  Object.entries(domainBiddersAttributes).map(
    ([key, value]: [
      key: DomainBidderSlice['id'],
      value: Pick<DomainBidderSlice, 'priority' | 'csEnabled' | 's2sEnabled'>,
    ]): DomainBidderAttributeDTO => ({
      id: key,
      ...value,
    }),
  )
