import { mapDTO } from '@twistezo/ts-dto-mapper'

import { adUnitViewabilityToolsFromDTO } from 'features/inventory/ad-unit/api/mappers/adUnitViewabilityTools'
import { AdUnitDTO } from 'features/inventory/api/types/adUnitDTO'
import { PageDTO } from 'features/inventory/api/types/pageDTO'
import { domainFromDTO } from 'features/inventory/mappers/domain'
import { pageForAdUnitTransferListFromDTO } from '../../useGetPagesForAdUnitTransferList/api/mapper'
import { PageForAdUnitTransferList } from '../../useGetPagesForAdUnitTransferList/types'
import { AdUnitForForm } from '../types'

export const adUnitForGeneralFormFromDTO = (from: AdUnitDTO): AdUnitForForm =>
  mapDTO<AdUnitDTO, AdUnitForForm>({ from }).transform(adUnitDTO => ({
    ...adUnitDTO,
    adUnitViewabilityTool: adUnitViewabilityToolsFromDTO(adUnitDTO.adUnitViewabilityTool),
    divId: adUnitDTO.divId || undefined,
    name: adUnitDTO.name || undefined,
    domain: domainFromDTO(adUnitDTO.domain),
    pages: adUnitDTO.pages.map(
      (page: PageDTO): PageForAdUnitTransferList => pageForAdUnitTransferListFromDTO(page),
    ),
    rawId: adUnitDTO.adUnitId || undefined,
  }))
