import { Button, SelectChangeEvent, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { EmployeeBox } from 'components/Box'
import { Select } from 'components/Select'
import { ProductStatus } from 'features/product'
import { ProductProps } from 'features/product/types/productProps'
import { productStatusColor } from 'features/product/utils/productStatusColor'
import { useAbility } from 'providers/casl'
import { RequestDialog } from './components/RequestDialog'
import { TrialDialog } from './components/TrialDialog'
import { useSelectStatus } from './hooks/useSelectStatus'

export const SelectStatus = ({ workspaceProduct }: ProductProps): JSX.Element => {
  const ability = useAbility()
  const theme = useTheme()
  const { t } = useTranslation('features/product')

  const { product, status: currentStatus } = workspaceProduct
  const { isOpen, setOpen, isTrial, isTrialRequested, options, newStatus, setNewStatus } =
    useSelectStatus({ currentStatus })

  const handleChange = (event: SelectChangeEvent<ProductStatus>): void => {
    setOpen(true)
    setNewStatus(event.target.value as ProductStatus)
  }

  const EditDuration = (): JSX.Element => (
    <Button
      data-cy='edit-duration'
      onClick={() => setOpen(true)}
      sx={{
        mt: 1,
        width: 'fit-content',
      }}
    >
      {t('editDuration')}
    </Button>
  )

  return (
    <EmployeeBox data-cy={`select-status-${product}`}>
      <Select<ProductStatus>
        id={product}
        label={t(`product.${product}`)}
        labelColor={({ value }) => productStatusColor({ status: value, theme })}
        name={product}
        onChange={handleChange}
        options={options}
        value={currentStatus}
        disabled={!ability.can('update', 'ProductField-status')}
      />

      {isTrial && <EditDuration />}

      {isTrialRequested ? (
        <TrialDialog
          newStatus={newStatus}
          isOpen={isOpen}
          setOpen={setOpen}
          workspaceProduct={workspaceProduct}
        />
      ) : (
        <RequestDialog
          newStatus={newStatus}
          isOpen={isOpen}
          setOpen={setOpen}
          workspaceProduct={workspaceProduct}
        />
      )}
    </EmployeeBox>
  )
}
