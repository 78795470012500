import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { Workspace } from 'features/workspace'
import { Ability } from 'providers/casl'
import { variablesToOmit } from 'providers/graphql/utils'
import { AddYieldbirdSchema } from '../../../schemas/addSchema'
import { Variables as VariablesDTO } from './types'

type From = AddYieldbirdSchema & { workspaceId: Workspace['id'] }
type Variables = Pick<
  From,
  'bidCpmAdjustment' | 'bidderId' | 'bidderSid' | 'consentMode' | 'priority' | 'workspaceId'
>
type OmitableVariables = (keyof Pick<From, 'bidCpmAdjustment' | 'bidderSid' | 'consentMode'>)[]

export const toDTO = (from: From, ability: Ability) =>
  mapDTO<From, VariablesDTO>({ from }).transform((data: From): VariablesDTO => {
    const { bidCpmAdjustment } = data

    const variables: Variables = {
      bidCpmAdjustment: bidCpmAdjustment / 100,
      ..._.pick(data, ['bidderId', 'bidderSid', 'consentMode', 'priority', 'workspaceId']),
    }

    const omitVariables: OmitableVariables = variablesToOmit({
      bidCpmAdjustment: ability.can(
        'update',
        'GlobalSetupField-prebid-bidders-yieldbird-bidCpmAdjustment',
      ),
      bidderSid: ability.can('update', 'GlobalSetupField-prebid-bidders-yieldbird-bidderSid'),
      consentMode: ability.can('update', 'GlobalSetupField-prebid-bidders-yieldbird-consentMode'),
    })

    const omittedVariables = _.omit(variables, omitVariables)
    const { bidderId, workspaceId } = variables

    return {
      ...omittedVariables,
      bidderId,
      workspaceId,
    }
  })
