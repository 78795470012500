import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { useEnableBidderColumns } from './columns/useEnableBidderColumns'
import { useParamsColumns } from './columns/UseParamsColumns'
import { AdUnitsForTable, UseColumnsProps } from './types'

export const useColumns = ({
  csParamsInitial,
  s2sParamsInitial,
  requiredParams,
  workspaceStatus,
}: UseColumnsProps): ColumnDef<AdUnitsForTable>[] => {
  const { t } = useTranslation(['features/globalSetup'], {
    keyPrefix: 'prebid.bidders.edit.bidParamsAndConnection.adUnitsTable',
  })

  const csParamsColumns: ColumnDef<AdUnitsForTable> = useParamsColumns({
    paramsInitial: csParamsInitial,
    kind: 'cs',
  })
  const s2sParamsColumns: ColumnDef<AdUnitsForTable> = useParamsColumns({
    paramsInitial: s2sParamsInitial,
    kind: 's2s',
  })
  const enableBidderColumns: ColumnDef<AdUnitsForTable> = useEnableBidderColumns({
    s2sParamsInitial,
    requiredParams,
    workspaceStatus,
  })

  return [
    {
      header: t('adUnits.group'),
      columns: [
        {
          accessorKey: 'adUnit.path',
          header: t('adUnits.adUnitPath'),
          size: COLUMN_SIZE.WIDER,
        },
        {
          accessorKey: 'adUnit.divId',
          header: t('adUnits.divId'),
          size: COLUMN_SIZE.STANDARD,
        },
      ],
      meta: { isPinned: true },
    },
    enableBidderColumns,
    csParamsColumns,
    s2sParamsColumns,
    {
      header: t('lastModified'),
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
    },
  ]
}
