import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { UserSignLayoutPage } from 'features/layout/pages/UserSignLayoutPage'
import { BoxHeader } from 'features/user/components/BoxHeader'
import { UserRequestPasswordResetForm } from 'features/user/forms/UserRequestPasswordResetForm'

export const UserRequestPasswordResetPage = (): JSX.Element => {
  const { t } = useTranslation('features/user')

  return (
    <UserSignLayoutPage>
      <BoxHeader
        title={t('userRequestPassword.title')}
        description={<Typography>{t('userRequestPassword.description')}</Typography>}
      />

      <UserRequestPasswordResetForm />
    </UserSignLayoutPage>
  )
}
