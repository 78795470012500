import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { GET_AD_UNIT } from 'features/inventory/api/queries/adUnit'
import { useAbility } from 'providers/casl'
import { adUnitForGeneralFormFromDTO } from './api/mapper'
import { AdUnitForForm, UseGetAdUnit, UseGetAdUnitProps } from './types'

export const useGetAdUnit = ({ id }: UseGetAdUnitProps): UseGetAdUnit => {
  const [adUnit, setAdUnit] = useState<AdUnitForForm>()
  const ability = useAbility()

  useEffect(() => {
    if (id) {
      queryAdUnit({
        variables: {
          id,
        },
      })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [queryAdUnit, { loading }] = useLazyQuery(GET_AD_UNIT(ability), {
    onCompleted: ({ node: adUnit }) => {
      if (adUnit) {
        setAdUnit(adUnitForGeneralFormFromDTO(adUnit))
      }
    },
  })

  return { adUnit, loading }
}
