import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SelectOption } from 'components/Select'
import { demandToAccount } from 'features/globalSetup/api/mappers/demandToAccount'
import { prebidModuleLogos } from 'features/globalSetup/forms/Prebid/Modules/logos/prebidModuleLogos'
import { PrebidModuleSlice } from '../types'
import { PrebidModuleSliceDTO } from './types'

type From = PrebidModuleSliceDTO
type To = SelectOption<PrebidModuleSlice>

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((data: From): To => {
    const { id, name, demand } = data

    return {
      label: name,
      value: id,
      logo: prebidModuleLogos[name],
      rawData: {
        id,
        name,
        account: demandToAccount(demand),
      },
    }
  })
