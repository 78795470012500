import { MoreVert } from '@mui/icons-material'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { TableFormSwitchCell } from 'components/Table/Form'
import { AdUnitsAttributesSchema } from './schema'
import { AdUnitForProductTable, AdUnitsTableProduct } from './types'

export const useColumns = (product: AdUnitsTableProduct): ColumnDef<AdUnitForProductTable>[] => {
  const { t } = useTranslation('features/adUnit')
  const { t: tProduct } = useTranslation('features/product')

  return [
    {
      accessorKey: 'name',
      header: t('list.header.adUnitName'),
    },
    {
      accessorKey: 'divId',
      header: t('list.header.divId'),
    },
    {
      accessorKey: 'path',
      header: t('list.header.adUnitPath'),
    },
    {
      accessorKey: `${product}Enabled`,
      cell: ({
        cell: { getValue },
        row: {
          original: { id },
        },
      }) => (
        <TableFormSwitchCell<AdUnitsAttributesSchema>
          name={`adUnitsAttributes.${id}`}
          value={getValue()}
        />
      ),
      header: tProduct(`product.${product}`),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('list.header.lastModified'),
    },
    {
      id: 'actions',
      cell: () => <MoreVert />,
      header: t('list.header.actions'),
      meta: {
        centered: true,
        nonClickable: true,
      },
      size: COLUMN_SIZE.ACTIONS,
    },
  ]
}
