import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, To } from 'react-router-dom'

import Filter, { FilterValue } from 'components/Filter'
import { useMergeFormData } from 'components/Form'
import Paper from 'components/Paper'
import { Table, TableHeader, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import {
  AdUnitForProductTable,
  AdUnitsAttributes,
  AdUnitSortBy,
  ProductAdUnitsTableProps,
  Schemas,
} from './types'
import { useColumns } from './useColumns'
import { useGetAdUnits } from './useGetAdUnits'

export const ProductAdUnitsTable = ({
  domainId,
  product,
}: ProductAdUnitsTableProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation(['features/domain', 'features/product'])
  const columns = useColumns(product)
  const [filters, setFilters] = useState<FilterValue[]>([])
  const { page, setPage, rowsPerPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })
  const { sort, props: sortProps } = useSort<AdUnitForProductTable, AdUnitSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const {
    adUnits,
    count,
    loading: adUnitsLoading,
  } = useGetAdUnits({
    domainId,
    filters,
    ...calculatePagination({ page, rowsPerPage }),
    sort,
  })

  const adUnitsAttributes: AdUnitsAttributes = _.chain(adUnits)
    .keyBy('id')
    .mapValues(`${product}Enabled`)
    .value()

  useMergeFormData<Schemas>({
    data: adUnitsAttributes,
    path: 'adUnitsAttributes',
  })

  const productName = t(`features/product:product.${product}`)

  const handleRowClick = (row: AdUnitForProductTable): To =>
    generatePath(ROUTES.INVENTORY.AD_UNIT.EDIT, {
      workspaceId,
      id: row.id,
      tab: _.kebabCase(product),
    })

  const TableFilter = (
    <Filter
      id='productAdUnitFilter'
      onChange={setFilters}
      placeholder={t('form.common.adUnitList.filter')}
      values={filters}
    />
  )

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Paper data-cy='product-ad-unit-table'>
      <TableHeader
        title={t('form.common.adUnitList.title')}
        description={t('form.common.adUnitList.description', { product: productName })}
        filter={TableFilter}
        bottomSpacing
      />
      <Table<AdUnitForProductTable>
        columns={columns}
        data={adUnits}
        loading={adUnitsLoading}
        onClickRow={handleRowClick}
        pagination={paginationProps}
        sort={sortProps}
      />
    </Paper>
  )
}
