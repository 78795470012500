import { SxProps } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { NumberField, TextField } from 'components/Form'
import { TextFieldProps } from 'components/Form/TextField'
import { Schema } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { AdUnitParameter } from 'features/globalSetup/types/adUnitBidder'
import { INPUT_WIDTH } from 'providers/material-ui/theme/constants'
import { AdUnitBidderParamFieldPath, Props } from './types'

export const AdUnitParamInputCell = ({
  kind,
  cellContext: {
    cell: { getValue },
    row: {
      original: { id: adUnitBidderId },
    },
  },
}: Props): JSX.Element | undefined => {
  const { control, register, getValues } = useFormContext<Schema>()

  const { name, type, value } = getValue() as AdUnitParameter

  const fieldPath: AdUnitBidderParamFieldPath = `adUnitBidders.${adUnitBidderId}.${kind}Params.${name}`

  if (getValues(fieldPath) === undefined) {
    register(fieldPath, { value })
  }

  const inputProps: TextFieldProps<Schema> = {
    key: `${kind}-${name}`,
    control,
    name: fieldPath,
    useTooltipForErrors: true,
  }

  const styles: SxProps = { minWidth: INPUT_WIDTH / 2 }

  return type === 'string' ? (
    <TextField
      {...inputProps}
      sx={styles}
    />
  ) : (
    <NumberField
      {...inputProps}
      type={type}
      sx={styles}
    />
  )
}
