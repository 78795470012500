import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { CommonForm } from './CommonForm'
import { useCreateAdUnit } from './hooks/useCreateAdUnit'
import { AddSchema, addSchema, addSchemaDefault } from './schemas/addSchema'

export const GeneralFormAdd = (): JSX.Element => {
  const { formId, loading, setTouched, setValid } = useContext(AddEditAdUnitContext)
  const { errors: apiErrors, createAdUnit } = useCreateAdUnit()

  return (
    <Form<AddSchema>
      id={formId}
      onSubmit={data => createAdUnit(data)}
      onTouched={setTouched}
      onValid={setValid}
      schema={addSchema}
      schemaDefaults={addSchemaDefault}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <CommonForm variant='add' />)}
    </Form>
  )
}
