import { ColumnSort, SortingState } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { StringKeyOf } from 'type-fest'

import { SortInputKey, SortProps, UseTableSort } from './types'

const inputToSortingState = <T>(input?: SortInputKey<T>) => {
  const { by, direction } = input || {}

  if (by) {
    return [
      {
        id: by,
        desc: direction === 'desc',
      },
    ]
  } else return []
}

export const useTableSort = <T>(sort?: SortProps<T>): UseTableSort => {
  const { input, defaultInput } = sort || {}
  const initialState: SortingState = inputToSortingState<T>(input)
  const defaultState: SortingState = inputToSortingState<T>(defaultInput)

  const [sorting, setSorting] = useState<SortingState>(initialState)

  useEffect(() => {
    if (sort) {
      if (sorting.length > 0) {
        const { id, desc }: ColumnSort = sorting[0]

        sort.onSort({
          by: id as StringKeyOf<T>,
          direction: desc ? 'desc' : 'asc',
        })
      } else {
        setSorting(defaultState)
      }
    }
  }, [sorting]) // eslint-disable-line react-hooks/exhaustive-deps

  return { sorting, setSorting }
}
