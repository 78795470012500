import _ from 'lodash'
import { useEffect, useState } from 'react'

import { FilterValue } from 'components/Filter'
import { clean } from 'utils/string'
import { UseFilteredData } from './types'

export const useFilteredData = <T,>({ filters, filterBy, data }: UseFilteredData<T>): T[] => {
  const [filteredData, setFilteredData] = useState<T[]>(data)

  const includes = (item: T, key: keyof T, filter: FilterValue): boolean => {
    const value = item[key]

    if (_.isString(value) && value.length > 0) {
      const cleanValue = clean(value)
      const cleanFilter = clean(filter)

      return cleanValue.includes(cleanFilter)
    } else {
      return false
    }
  }

  const filter = (): T[] =>
    data.filter((item: T): boolean =>
      filters.some((filter: FilterValue): boolean =>
        filterBy.some((key: keyof T): boolean => includes(item, key, filter)),
      ),
    )

  useEffect(() => {
    if (filters.length > 0) {
      setFilteredData(filter())
    } else {
      setFilteredData(data)
    }
  }, [filters]) // eslint-disable-line react-hooks/exhaustive-deps

  return filteredData
}
