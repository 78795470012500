import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { ProtectionContext } from 'features/globalSetup/contexts/ProtectionContext'
import { CommonForm } from './CommonForm'
import { useUpdateFilteringRule } from './hooks/useUpdateFilteringRule'
import { editSchema, EditSchema, editSchemaDefault } from './schemas/editSchema'

export const EditFilteringRuleForm = () => {
  const { filteringRule, formId, setTouched, setValid } = useContext(ProtectionContext)
  const { update, errors: apiErrors } = useUpdateFilteringRule()

  if (filteringRule == undefined) {
    return <Loading />
  }

  return (
    <Form<EditSchema>
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={editSchema}
      schemaDefaults={editSchemaDefault(filteringRule)}
      externalErrors={apiErrors}
    >
      {() => <CommonForm />}
    </Form>
  )
}
