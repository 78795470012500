import { OpenInNew } from '@mui/icons-material'

import { Link } from 'components/Link'
import { WorkspaceForTable } from '../types'

export const PipedriveNavLink = ({ url }: { url: WorkspaceForTable['pipedriveLink'] }) => (
  <Link
    to={url}
    newTab
  >
    <OpenInNew />
  </Link>
)
