export const URL = {
  KNOWLEDGE_BASE: {
    BASIC_SETTINGS: 'https://knowledge-base.yieldbird.com/refresher/basic-settings',
    TARGETING:
      'https://knowledge-base.yieldbird.com/refresher/targeting-criteria-for-refreshed-inventory',
  },
  GOOGLE_REFRESH_POLICY: 'https://support.google.com/admanager/answer/6286179',
} as const

export const SHOW_HINT_BELOW = {
  AD_THRESHOLD: 50,
  REFRESH_INTERVAL: 30,
}
