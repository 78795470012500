import { generatePath } from 'react-router-dom'

import { RelatedTimeoutItem } from 'components/RelatedTimeoutSettings'
import { ROUTES } from 'routes'
import { RelatedPrebidTimeoutProps } from './types'

export const RelatedPrebidTimeout = ({
  domain,
  workspacePrebidConfiguration,
}: RelatedPrebidTimeoutProps): JSX.Element =>
  domain.domainPrebidStack.customPrebidTimeoutEnabled ? (
    <RelatedTimeoutItem
      message='customPrebidTimeout'
      value={domain.domainPrebidStack.prebidTimeout}
      url={generatePath(ROUTES.INVENTORY.DOMAIN.EDIT, {
        workspaceId: domain.workspace.id,
        id: domain.id,
        tab: 'prebidStack',
      })}
    />
  ) : (
    <RelatedTimeoutItem
      message='prebidTimeout'
      value={workspacePrebidConfiguration.prebidTimeout}
      url={generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, {
        workspaceId: domain.workspace.id,
      })}
    />
  )
