import { CSSObject } from '@mui/material'

import { YieldbirdStatusColor } from 'features/globalSetup/forms/Prebid/Bidders/types'
import { Account } from 'features/globalSetup/types/workspaceBidder'

export const isYieldbirdBidder = (accountType: Account): boolean => accountType === 'yieldbird'

export const yieldbirdStatusColor = ({
  status,
  theme,
}: YieldbirdStatusColor): CSSObject['color'] => {
  const {
    palette: {
      error: { main: error },
      success: { main: success },
      warning: { main: warning },
    },
  } = theme

  switch (status) {
    case 'available': {
      return success
    }
    case 'denied': {
      return error
    }
    case 'requested': {
      return warning
    }
  }
}
