import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { Workspace } from 'features/workspace'
import { AddThirdPartyProviderSchema } from '../../../schemas/addSchema'
import { Variables as VariablesDTO } from './types'

type From = AddThirdPartyProviderSchema & { workspaceId: Workspace['id'] }
type Variables = Pick<
  From,
  | 'bidCpmAdjustment'
  | 'bidderCode'
  | 'bidderId'
  | 'bidderSid'
  | 'consentMode'
  | 'priority'
  | 'supplyChain'
  | 'workspaceId'
>

export const toDTO = (from: From): VariablesDTO =>
  mapDTO<From, VariablesDTO>({ from }).transform((data: From): VariablesDTO => {
    const variables: Variables = _.pick(data, [
      'bidCpmAdjustment',
      'bidderCode',
      'bidderId',
      'bidderSid',
      'consentMode',
      'priority',
      'supplyChain',
      'workspaceId',
    ])

    const { bidCpmAdjustment, bidderSid, supplyChain, ...rest } = variables

    return {
      ...rest,
      bidderSid: bidderSid || null,
      bidCpmAdjustment: bidCpmAdjustment / 100,
      supplyChainId: supplyChain,
    }
  })
