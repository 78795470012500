import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_WORKSPACE_PREBID_MODULE: TypedDocumentNode<Data, Variables> = gql`
  query getWorkspacePrebidModule($id: ID!) {
    node(id: $id) {
      ... on WorkspacePrebidModule {
        id
        params

        prebidModule {
          id
          name
          demand
        }
      }
    }
  }
`
