import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { UserManagementFormContext } from '../../contexts/UserManagementFormContext'
import { CommonForm } from './CommonForm'
import { useUpdateEmployee } from './hooks/useUpdateEmployee'
import { EditSchema, editSchema, editSchemaDefault } from './schemas/edit'
import { UserEditEmployeeFormProps } from './types'

export const UserEditEmployeeForm = ({ user }: UserEditEmployeeFormProps): JSX.Element => {
  const { formId, loading, setTouched, setValid } = useContext(UserManagementFormContext)
  const { updateEmployee, errors: apiErrors } = useUpdateEmployee()

  return (
    <Form<EditSchema>
      id={formId}
      onSubmit={updateEmployee}
      onTouched={setTouched}
      onValid={setValid}
      schema={editSchema}
      schemaDefaults={editSchemaDefault(user)}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <CommonForm variant='edit' />)}
    </Form>
  )
}
