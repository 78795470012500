import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { SelectOption } from 'components/Select'
import { Account } from 'features/globalSetup/types/workspaceBidder'
import { BidderSlice } from '../../../types'
import { BidderSliceDTO, fromDTO, GET_BIDDERS } from './api'
import { UseGetBiddersForSelect } from './types'

export const useGetBiddersForSelect = (accountType: Account): UseGetBiddersForSelect => {
  const [options, setOptions] = useState<SelectOption<BidderSlice>[]>([])

  useQuery(GET_BIDDERS, {
    variables: { accountType },

    onCompleted: ({ bidders: { nodes } }): void => {
      if (nodes && nodes.length > 0) {
        setOptions(nodes.map((bidder: BidderSliceDTO) => fromDTO(bidder, accountType)))
      }
    },
  })

  return options
}
