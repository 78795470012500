import { useContext } from 'react'

import { Form as FormProvider } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { Form } from './Form'
import { Schema, schema, schemaDefault } from './schema'
import { useUpdateAdUnitViewabilityTools } from './useUpdateAdUnitViewabilityTools'

export const FormContainer = (): JSX.Element => {
  const { adUnit, formId, loading, setTouched, setValid } = useContext(AddEditAdUnitContext)
  const { errors: apiErrors, update } = useUpdateAdUnitViewabilityTools()

  if (!adUnit) {
    return <Loading />
  }

  return (
    <FormProvider<Schema>
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(adUnit.adUnitViewabilityTool)}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <Form />)}
    </FormProvider>
  )
}
