import { kebabCase } from 'change-case'

import { Product } from '../types/product'
import { WorkspaceProduct } from '../types/workspaceProduct'
import { productsLogos } from './productsLogos'
import { ProductConfig, ProductParams } from './types'

export const getProductWithConfig = (
  workspaceProduct: WorkspaceProduct,
): WorkspaceProduct & ProductParams => ({
  ...workspaceProduct,
  ...productsConfig[workspaceProduct.product],
})

const composeLearnMoreUrl = (product: Product): string =>
  `https://knowledge-base.yieldbird.com/${kebabCase(product)}`

const productsConfig: ProductConfig = {
  prebidStack: {
    learnMoreUrl: composeLearnMoreUrl('prebidStack'),
    logo: productsLogos.prebidStack,
  },
  priceGenius: {
    learnMoreUrl: composeLearnMoreUrl('priceGenius'),
    logo: productsLogos.priceGenius,
  },
  refresher: {
    learnMoreUrl: composeLearnMoreUrl('refresher'),
    logo: productsLogos.refresher,
  },
  unfilledRecovery: {
    learnMoreUrl: composeLearnMoreUrl('unfilledRecovery'),
    logo: productsLogos.unfilledRecovery,
  },
  viewabilityTools: {
    learnMoreUrl: composeLearnMoreUrl('viewabilityTools'),
    logo: productsLogos.viewabilityTools,
  },
} as const
