import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { SelectOption } from 'components/Select'
import { fromDTO } from './api/mapper'
import { GET_PREBID_MODULES_FOR_SELECT } from './api/query'
import { PrebidModuleSliceDTO } from './api/types'
import {
  PrebidModuleSlice,
  UseGetPrebidModulesForSelect,
  UseGetPrebidModulesForSelectProps,
} from './types'

export const useGetPrebidModulesForSelect = ({
  moduleType,
}: UseGetPrebidModulesForSelectProps): UseGetPrebidModulesForSelect => {
  const [options, setOptions] = useState<SelectOption<PrebidModuleSlice>[]>([])

  useQuery(GET_PREBID_MODULES_FOR_SELECT, {
    variables: { moduleType },

    onCompleted: ({ prebidModules: { nodes: modules } }): void => {
      if (modules && modules.length > 0) {
        setOptions(
          modules.map(
            (module: PrebidModuleSliceDTO): SelectOption<PrebidModuleSlice> => fromDTO(module),
          ),
        )
      }
    },
  })

  return { options }
}
