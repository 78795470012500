import { Typography } from '@mui/material'

export const Space = () => (
  <Typography
    sx={{ fontSize: 'inherit' }}
    component='span'
  >
    &nbsp;
  </Typography>
)
