import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, RadioGroup, Switch, TagsField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { URL } from '../../constants'
import { Schema } from '../../schema'
import { useMatchTypeRadioGroup } from './useMatchTypeRadioGroup'

export const TargetingSection = (): JSX.Element => {
  const { t } = useTranslation(['features/domain', 'common'])
  const { control } = useFormContext<Schema>()
  const matchTypeOptions = useMatchTypeRadioGroup()

  return (
    <Paper>
      <SectionHeader
        title={t('form.refresher.targeting.title')}
        titleEndAdornment={
          <Switch
            control={control}
            name='targetingEnabled'
          />
        }
        subtitle={t('form.refresher.targeting.subtitle')}
        subtitleEndAdornment={
          <Link
            to={URL.KNOWLEDGE_BASE.TARGETING}
            newTab
          >
            {t('common:learnMore')}
          </Link>
        }
        bottomSpacing
      />

      <BoxForm>
        <RadioGroup
          control={control}
          label={t('form.refresher.targeting.matchType.label')}
          name='matchType'
          options={matchTypeOptions}
          row
        />

        <TagsField
          control={control}
          label={t('form.refresher.targeting.advertiserIds.label')}
          name='advertiserIds'
          tooltip={{ content: t('form.refresher.targeting.advertiserIds.tooltip') }}
        />

        <TagsField
          control={control}
          label={t('form.refresher.targeting.orderIds.label')}
          name='orderIds'
          tooltip={{ content: t('form.refresher.targeting.orderIds.tooltip') }}
        />

        <TagsField
          control={control}
          label={t('form.refresher.targeting.lineItemIds.label')}
          name='lineItemIds'
          tooltip={{ content: t('form.refresher.targeting.lineItemIds.tooltip') }}
        />
      </BoxForm>
    </Paper>
  )
}
