import { gql, TypedDocumentNode } from '@apollo/client'

import { PAGE_FOR_AD_UNIT_TRANSFER_LIST_FRAGMENT } from 'features/inventory/api/fragments/pageForAdUnitTransferList'
import { AdUnitDTO } from 'features/inventory/api/types/adUnitDTO'

export const AD_UNIT_UPDATE_FRAGMENT: TypedDocumentNode<AdUnitDTO> = gql`
  ${PAGE_FOR_AD_UNIT_TRANSFER_LIST_FRAGMENT}

  fragment AdUnitUpdateFragment on AdUnit {
    divId
    id
    name
    outOfPage
    outOfPageType
    pages {
      ...PageForAdUnitTransferList
    }
    path
  }
`
