import { UserDTO } from 'features/user/api/types/userDTO'
import { Username } from 'features/user/types/username'

export const composeUsername = ({
  firstName,
  lastName,
}: Pick<UserDTO, 'firstName' | 'lastName'>): Username => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  } else if (firstName) {
    return firstName
  } else if (lastName) {
    return lastName
  } else {
    return undefined
  }
}
