import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import {
  PrebidModulesContext,
  PrebidModulesContextType,
} from 'features/globalSetup/contexts/PrebidModulesContext'
import { AddContainer } from 'features/globalSetup/forms/Prebid/Modules/UserIdsForm'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'

export const UserIdsAdd = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation(['features/globalSetup', 'common'])

  const contextProps: PrebidModulesContextType = useCommonFormContextProps({
    formId: 'prebid-modules-user-ids-form-add',
  })

  return (
    <PrebidModulesContext.Provider value={contextProps}>
      <PrebidModulesContext.Consumer>
        {(ctxProps: PrebidModulesContextType): JSX.Element => (
          <PageHeader
            actions={<SaveButton {...ctxProps} />}
            backTo={generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.USER_ID.LIST, { workspaceId })}
            title={t('prebid.modules.userId.add.title')}
          />
        )}
      </PrebidModulesContext.Consumer>

      <AddContainer />
    </PrebidModulesContext.Provider>
  )
}
