import { Stack, SxProps, TextField as TextFieldRoot } from '@mui/material'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import Tooltip from 'components/Tooltip'
import { TextFieldProps } from './types'

const prepareValue = <T extends FieldValues>(
  fieldValue: string,
  componentValue: TextFieldProps<T>['value'],
): string => {
  if (fieldValue !== undefined) {
    return fieldValue
  } else if (typeof componentValue === 'string' && componentValue !== undefined) {
    return componentValue
  } else return ''
}

export const TextField = <T extends FieldValues>({
  control,
  name,
  tooltip: tooltipProps,
  useTooltipForErrors,
  ...textFieldProps
}: TextFieldProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name as FieldPath<T>}
    render={({ field, fieldState: { error } }) => {
      const hasError: boolean = Boolean(error)
      const errorMessage: string = error?.message || ''

      const { fullWidth } = textFieldProps
      const containerSxProps: SxProps = fullWidth ? { width: '100%' } : {}

      return (
        <Stack
          direction='row'
          sx={containerSxProps}
        >
          <TextFieldRoot
            {...field}
            {...textFieldProps}
            id={name}
            sx={{
              backgroundColor: theme => theme.palette.white.main,
              ...textFieldProps.sx,
            }}
            value={prepareValue(field.value, textFieldProps.value)}
            error={hasError}
            helperText={!useTooltipForErrors && errorMessage}
          />

          {tooltipProps && <Tooltip {...tooltipProps} />}

          {hasError && useTooltipForErrors && (
            <Tooltip
              content={errorMessage}
              variant='error'
            />
          )}
        </Stack>
      )
    }}
  />
)
