import { Box, Tooltip } from '@mui/material'
import { CellContext } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Switch'
import { WorkspacePrebidModuleSlice } from 'features/globalSetup/pages/Prebid/Modules/hooks/useGetWorkspacePrebidModules'
import { useUpdateWorkspacePrebidModuleEnabled } from 'features/globalSetup/pages/Prebid/Modules/hooks/useUpdateWorkspacePrebidModuleEnabled'
import { useAbility } from 'providers/casl'

export const EnabledCell = ({
  getValue,
  row: {
    original: {
      id,
      prebidModule: { name: prebidModuleName },
      requested,
    },
  },
}: CellContext<WorkspacePrebidModuleSlice, unknown>) => {
  const ability = useAbility()
  const { t } = useTranslation('features/globalSetup')

  const { checked, update } = useUpdateWorkspacePrebidModuleEnabled({
    id,
    initialChecked: Boolean(getValue()),
    prebidModuleName,
  })

  return (
    <Tooltip
      title={
        requested ? (
          <Box sx={{ whiteSpace: 'pre-line' }}>
            {t('prebid.modules.vendorSpecific.list.requestedTooltip')}
          </Box>
        ) : undefined
      }
    >
      <div>
        <Switch
          name={`workspace-prebid-vendor-specific-enabled-${id}`}
          checked={checked}
          onChange={update}
          disabled={requested || !ability.can('update', 'GlobalSetupFeature-vendorSpecific')}
        />
      </div>
    </Tooltip>
  )
}
