import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainPrebidStackDTO } from '../types/domainPrebidStackDTO'

export const DOMAIN_PREBID_STACK_FRAGMENT: TypedDocumentNode<DomainPrebidStackDTO> = gql`
  fragment DomainPrebidStackFragment on DomainPrebidStack {
    createdAt
    domainId
    id
    customPrebidTimeoutEnabled
    prebidStackEnabled
    prebidTimeout
    updatedAt
  }
`
