import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { fromDTO, sortToDTO } from './api/mapper'
import { GET_AD_UNIT_BIDDERS } from './api/query'
import { AdUnitBidderSliceDTO } from './api/types'
import { AdUnitBidderSlice, Props, UseGetAdUnitBidders } from './types'

export const useGetAdUnitBidders = ({
  domainBidder,
  first,
  last,
  filters,
  sort,
}: Props): UseGetAdUnitBidders => {
  const [adUnitBidders, setAdUnitBidders] = useState<AdUnitBidderSlice[]>([])
  const [count, setCount] = useState<number>(0)

  const {
    domain: { id: domainId },
    workspaceBidder: { id: workspaceBidderId },
    paramsSchema,
  } = domainBidder

  useEffect(() => {
    query({
      variables: {
        workspaceBidderId,
        domainId,
        first,
        last,
        terms: filters,
        sort: sortToDTO(sort),
      },
    })
  }, [domainId, first, last, filters, sort]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_AD_UNIT_BIDDERS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ adUnitBidders: { nodes, totalCount: count } }): void => {
      if (nodes) {
        setAdUnitBidders(
          nodes.map((adUnitBidder: AdUnitBidderSliceDTO) => fromDTO(adUnitBidder, paramsSchema)),
        )
        setCount(count)
      }
    },
  })

  return {
    adUnitBidders,
    loading,
    count,
  }
}
