import { Form } from 'components/Form'
import { CommonForm } from './CommonForm'
import { useCreateViewport } from './hooks/useCreateViewport'
import { AddSchema, addSchema, addSchemaDefault } from './schemas/addSchema'
import { ViewportFormProps } from './types'

export const ViewportFormAdd = ({ data, data: { id } }: ViewportFormProps): JSX.Element => {
  const { errors: apiErrors, create } = useCreateViewport(id)

  return (
    <Form<AddSchema>
      id={`viewport-form-add-${id}`}
      onSubmit={(data: AddSchema) => create(data)}
      schema={addSchema}
      schemaDefaults={addSchemaDefault(data)}
      externalErrors={apiErrors}
    >
      {() => <CommonForm />}
    </Form>
  )
}
