import { Stack } from '@mui/material'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import {
  RelatedTimeoutSettings,
  RelatedWorkspacePrebidTimeout,
} from 'components/RelatedTimeoutSettings'
import { useGetWorkspacePrebidConfiguration } from 'features/globalSetup'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { Schema } from '../../schema'
import { KNOWLEDGE_BASE_URL } from './constants'
import { RelatedFailsafeTimeout } from './RelatedFailsafeTimeout'

export const TimeoutSection = (): JSX.Element => {
  const { t } = useTranslation(['features/domain', 'common'])
  const { workspaceId } = useWorkspaceParam()
  const { domain } = useContext(AddEditDomainContext)
  const { workspacePrebidConfiguration } = useGetWorkspacePrebidConfiguration({
    workspaceId,
  })
  const { control, watch } = useFormContext<Schema>()
  const { customPrebidTimeoutEnabled } = watch()

  return (
    <Paper>
      <SectionHeader
        title={t('form.prebidStack.timeouts.title')}
        subtitle={t('form.prebidStack.timeouts.description')}
        bottomSpacing
      />

      <Stack
        direction='row'
        alignItems='flex-start'
        spacing={2}
      >
        <BoxForm>
          <Switch
            control={control}
            label={t('form.prebidStack.timeouts.customPrebidTimeoutEnabled.label')}
            labelPlacement='start'
            name='customPrebidTimeoutEnabled'
            tooltip={{
              content: t('form.prebidStack.timeouts.customPrebidTimeoutEnabled.tooltip'),
              link: {
                title: t('common:learnMore'),
                url: KNOWLEDGE_BASE_URL,
              },
            }}
          />

          {customPrebidTimeoutEnabled && (
            <NumberField
              control={control}
              label={t('form.prebidStack.timeouts.prebidTimeout.label')}
              name='prebidTimeout'
            />
          )}
        </BoxForm>

        {customPrebidTimeoutEnabled && domain && workspacePrebidConfiguration && (
          <RelatedTimeoutSettings
            items={[
              <RelatedFailsafeTimeout
                domain={domain}
                key='relatedFailsafeTimeout'
              />,
              <RelatedWorkspacePrebidTimeout
                {...{ workspaceId, workspacePrebidConfiguration }}
                key='relatedWorkspacePrebidTimeout'
              />,
            ]}
          />
        )}
      </Stack>
    </Paper>
  )
}
