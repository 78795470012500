import { useTranslation } from 'react-i18next'

import { SelectOption } from 'components/Select'
import { Status, statuses } from 'features/globalSetup/types/workspaceBidder'

export const useGetYieldbirdStatusesForSelect = (): SelectOption<Status>[] => {
  const { t } = useTranslation('features/globalSetup')

  return statuses.map(
    (status: Status): SelectOption<Status> => ({
      label: t(`prebid.bidders.form.yieldbirdStatuses.${status}`),
      value: status,
    }),
  )
}
