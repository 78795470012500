import { gql, TypedDocumentNode } from '@apollo/client'

import { VIEWPORT_FRAGMENT } from 'features/inventory/viewport/api/fragment'
import { Data, Variables } from './types'

export const GET_VIEWPORTS: TypedDocumentNode<Data, Variables> = gql`
  ${VIEWPORT_FRAGMENT}

  query getViewports($workspaceId: ID!) {
    viewports(workspaceId: $workspaceId) {
      nodes {
        ...ViewportFragment
      }
    }
  }
`
