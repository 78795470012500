import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import Loading from 'components/Loading'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes/routePaths'
import Iframe from '../../components/Iframe'
import { FormContainer } from '../../forms/FormContainer'
import { useGetWorkspaceProduct } from './hooks/useGetWorkspaceProduct'
import { usePerformancePageParams } from './hooks/usePerformancePageParams/usePerformancePageParams'

export const PerformancePage = (): JSX.Element => {
  const { workspaceId, product } = usePerformancePageParams()
  const ability = useAbility()
  const { workspaceProduct } = useGetWorkspaceProduct({
    id: workspaceId,
    product,
  })

  const { t } = useTranslation(['features/performance', 'common'])

  if (!workspaceId || !product || !ability.can('access', `PerformanceProduct-${product}`)) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!workspaceProduct) {
    return <Loading />
  }

  const hasAccess: boolean =
    ability.can('read', 'Performance-adminOption') &&
    ability.can('update', 'Performance-adminOption')

  return (
    <>
      {hasAccess && (
        <FormContainer
          key={workspaceProduct.id}
          workspaceProduct={workspaceProduct}
        />
      )}

      {workspaceProduct.performanceReportUrl ? (
        <Iframe src={workspaceProduct.performanceReportUrl} />
      ) : (
        <Paper>
          <Typography>{t('common:report.notReady')}</Typography>
        </Paper>
      )}
    </>
  )
}
