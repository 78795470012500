import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { UserSignLayoutPage } from 'features/layout/pages/UserSignLayoutPage'
import { BoxHeader } from 'features/user/components/BoxHeader'
import { UserConfirmResetPasswordForm } from 'features/user/forms/UserConfirmResetPasswordForm'
import { ROUTES } from 'routes'
import { UserConfirmResetPasswordPageParams } from './types'

export const UserConfirmResetPasswordPage = (): JSX.Element => {
  const { t } = useTranslation('features/user')
  const { resetPasswordToken } = useParams<UserConfirmResetPasswordPageParams>()

  if (!resetPasswordToken) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  }

  return (
    <UserSignLayoutPage>
      <BoxHeader
        title={t('confirmResetPassword.title')}
        description={<Typography>{t('confirmResetPassword.description')}</Typography>}
      />

      <UserConfirmResetPasswordForm resetPasswordToken={resetPasswordToken} />
    </UserSignLayoutPage>
  )
}
