export const DESCRIPTION_URL = 'https://knowledge-base.yieldbird.com/yieldbird-platform/viewports'

export const LIST_COLUMN_BREAKPOINTS = {
  name: 2,
  minimumWidth: 2,
  bannerSizes: 4,
  nativeEnabled: 1,
  playerSize: 2,
  actionIcons: 1,
}
