import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { KNOWLEDGE_BASE_URL } from '../../constants'
import { DownloadCell } from './components/DownloadCell'
import { DomainsForTable } from './types'

export const useColumns = (): ColumnDef<DomainsForTable>[] => {
  const { t } = useTranslation(['features/tag', 'common'])

  return [
    {
      accessorKey: 'name',
      header: t('list.header.domainName'),
    },
    {
      id: 'openTags',
      cell: ({ row }) => (
        <DownloadCell
          {...row.original}
          tagKind='open_tag'
        />
      ),
      header: t('list.header.openTags.title'),
      meta: {
        nonClickable: true,
        tooltip: {
          content: t('list.header.openTags.tooltip.content'),
          link: {
            title: t('common:learnMore'),
            url: KNOWLEDGE_BASE_URL.OPEN_TAGS,
          },
        },
      },
    },
    {
      id: 'smartTags',
      cell: ({ row }) => (
        <DownloadCell
          {...row.original}
          tagKind='smart_tag'
        />
      ),
      header: t('list.header.smartTags.title'),
      meta: {
        nonClickable: true,
        tooltip: {
          content: t('list.header.smartTags.tooltip'),
          link: {
            title: t('common:learnMore'),
            url: KNOWLEDGE_BASE_URL.SMART_TAGS,
          },
        },
      },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('list.header.lastModified'),
    },
  ]
}
