import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import Filter from 'components/Filter'
import { TextLink } from 'components/Link'
import { TableHeader as TableHeaderRoot } from 'components/Table'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { TableHeaderProps } from './types'

export const TableHeader = ({ filter: { filters, setFilters } }: TableHeaderProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.bidderSids',
  })

  const TableFilter: JSX.Element = (
    <Filter
      id='schain-bidder-sids-filter'
      onChange={setFilters}
      placeholder={t('filter')}
      values={filters}
    />
  )

  const DescriptionLink = () => {
    const link = {
      title: t('description.link'),
      url: generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.ROOT, { workspaceId }),
    }

    return <TextLink link={link} />
  }

  return (
    <TableHeaderRoot
      title={t('title')}
      description={t('description.info')}
      descriptionEndAdornment={<DescriptionLink />}
      filter={TableFilter}
      bottomSpacing
    />
  )
}
