import { useMutation } from '@apollo/client'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { EditSchema } from '../../schemas/editSchema'
import { UPDATE_AD_UNIT } from './api/mutation'
import { Data, Variables } from './api/types'
import { UseUpdateAdUnit } from './types'

export const useUpdateAdUnit = (): UseUpdateAdUnit => {
  const { t } = useTranslation('features/adUnit')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading, setTouched } = useContext(AddEditAdUnitContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutateUpdateAdUnit] = useMutation(UPDATE_AD_UNIT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ updateAdUnit: { errors } }: Data): void => {
      setLoading(false)
      setTouched(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const mapper = (data: EditSchema): Variables => {
    const { divId, name } = data

    return {
      ..._.omit(data, 'domainId', 'path', 'rawId', 'unfilledRecoveryAdUnit'),
      divId: divId || null,
      name: name || null,
    }
  }

  const updateAdUnit = (data: EditSchema) => {
    setLoading(true)

    return mutateUpdateAdUnit({
      variables: {
        input: mapper(data),
      },
    })
  }

  return { errors, updateAdUnit }
}
