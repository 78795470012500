import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { demandToAccount } from 'features/globalSetup/api/mappers/demandToAccount'
import { WorkspacePrebidModuleSortBy } from 'features/globalSetup/types/workspacePrebidModule'
import { WorkspacePrebidModuleSlice } from '../types'
import { WorkspacePrebidModuleSliceDTO, WorkspacePrebidModuleSortByDTO } from './types'

export const workspacePrebidModulesFromDTO = (from: WorkspacePrebidModuleSliceDTO) =>
  mapDTO<WorkspacePrebidModuleSliceDTO, WorkspacePrebidModuleSlice>({ from }).transform(
    workspacePrebidModuleDTO => {
      const { enabled, prebidModule, id, requested, updatedAt } = workspacePrebidModuleDTO

      return {
        enabled,
        prebidModule: {
          ...prebidModule,
          account: demandToAccount(prebidModule.demand),
        },
        id,
        requested,
        updatedAt: new Date(updatedAt),
      }
    },
  )

export const sortToDTO = (
  sort: SortInput<WorkspacePrebidModuleSortBy>,
): SortInput<WorkspacePrebidModuleSortByDTO> => {
  const { by } = sort

  if (by === 'prebidModule.account') {
    return { ...sort, by: 'prebidModuleDemand' }
  } else if (by === 'prebidModule.name') {
    return { ...sort, by: 'prebidModuleName' }
  } else {
    return { ...sort, by }
  }
}
