import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import {
  WorkspaceUserContext,
  WorkspaceUserContextType,
} from 'features/globalSetup/contexts/WorkspaceUserContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { FormContainer } from './FormContainer'

export const WorkspaceUserAddPage = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { t: tU } = useTranslation('features/user')
  const { t } = useTranslation('features/globalSetup')

  const contextProps: WorkspaceUserContextType = useCommonFormContextProps({
    formId: 'workspace-user-form-add',
  })

  return (
    <WorkspaceUserContext.Provider value={contextProps}>
      <WorkspaceUserContext.Consumer>
        {(ctxProps: WorkspaceUserContextType): JSX.Element => (
          <PageHeader
            actions={
              <SaveButton
                {...ctxProps}
                text={tU('form.common.action.sendInvitation')}
              />
            }
            backTo={generatePath(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, {
              workspaceId,
              tab: 'user-management',
            })}
            title={t('general.userManagement.add.header')}
          />
        )}
      </WorkspaceUserContext.Consumer>

      <FormContainer />
    </WorkspaceUserContext.Provider>
  )
}
