import { RouteObject } from 'react-router-dom'

import { PrebidBiddersPage } from 'features/globalSetup/pages/Prebid/Bidders'
import { AddBidderPage } from 'features/globalSetup/pages/Prebid/Bidders/AddBidderPage'
import { EditBidderPage } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage'
import { PrebidDefaultsPage } from 'features/globalSetup/pages/Prebid/Defaults/PrebidDefaultsPage'
import { AnalyticsAdaptersAdd } from 'features/globalSetup/pages/Prebid/Modules/AnalyticsAdapters/AnalyticsAdaptersAdd'
import { AnalyticsAdaptersEdit } from 'features/globalSetup/pages/Prebid/Modules/AnalyticsAdapters/AnalyticsAdaptersEdit'
import { PrebidModulesPage } from 'features/globalSetup/pages/Prebid/Modules/PrebidModulesPage'
import { UserIdsAdd } from 'features/globalSetup/pages/Prebid/Modules/UserIds/UserIdsAdd'
import { UserIdsEdit } from 'features/globalSetup/pages/Prebid/Modules/UserIds/UserIdsEdit'
import { VendorSpecificAdd } from 'features/globalSetup/pages/Prebid/Modules/VendorSpecific/VendorSpecificAdd'
import { VendorSpecificEdit } from 'features/globalSetup/pages/Prebid/Modules/VendorSpecific/VendorSpecificEdit'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const prebidRoutes: RouteObject[] = [
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-defaults'
        page={<PrebidDefaultsPage />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.ROOT,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-bidders'
        page={<PrebidBiddersPage />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.ADD,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-bidders-add'
        page={<AddBidderPage />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-bidders-edit'
        page={<EditBidderPage />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT_SPECIFIC,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-bidders-edit'
        page={<EditBidderPage />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.MODULES.ROOT,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-modules'
        page={<PrebidModulesPage />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.MODULES.USER_ID.ADD,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-modules-user-ids-add'
        page={<UserIdsAdd />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.MODULES.USER_ID.EDIT,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-modules-user-ids-edit'
        page={<UserIdsEdit />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.MODULES.VENDOR_SPECIFIC.ADD,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-modules-vendor-specific-add'
        page={<VendorSpecificAdd />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.MODULES.VENDOR_SPECIFIC.EDIT,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-modules-vendor-specific-edit'
        page={<VendorSpecificEdit />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.MODULES.ANALYTICS.ADD,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-modules-analytics-add'
        page={<AnalyticsAdaptersAdd />}
      />
    ),
  },
  {
    path: ROUTES.GLOBAL_SETUP.PREBID.MODULES.ANALYTICS.EDIT,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-prebid-modules-analytics-edit'
        page={<AnalyticsAdaptersEdit />}
      />
    ),
  },
]
