import { TableCell } from '@mui/material'
import { ColumnMeta } from '@tanstack/react-table'

import { ExpandableCell } from '../ExpandableCell'
import { LinkCell } from '../LinkCell'
import { CellProps } from './types'
import { useCell } from './useCell'

export const Cell = <T,>({ cell, onClickRow }: CellProps<T>): JSX.Element => {
  const { Content, isClickable, row, styles } = useCell({ cell })
  const columnMeta: ColumnMeta<T, unknown> | undefined = cell.column.columnDef.meta

  const Clickable = (): JSX.Element => (
    <LinkCell
      row={row}
      columnMeta={columnMeta}
      onClick={onClickRow}
    >
      {Content}
    </LinkCell>
  )

  const Expandable = (): JSX.Element => (
    <ExpandableCell columnMeta={columnMeta}>{Content}</ExpandableCell>
  )

  return (
    <TableCell
      key={cell.id}
      sx={styles}
      data-cy={`table-cell-${cell.id}`}
    >
      {isClickable ? <Clickable /> : <Expandable />}
    </TableCell>
  )
}
