import { DefaultOptions } from '@apollo/client'

const defaultOptions: DefaultOptions = {
  query: {
    errorPolicy: 'none',
  },
  mutate: {
    errorPolicy: 'none',
  },
}

export default defaultOptions
