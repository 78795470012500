import { useState } from 'react'
import { StringKeyOf } from 'type-fest'

import { SortInput, SortInputKey, SortProps, UseSort } from './types'

export const useSort = <TableData, ApiSortBy>(
  initialSortInput: SortInput<ApiSortBy>,
  sortableColumns: ApiSortBy[],
): UseSort<TableData, ApiSortBy> => {
  const [apiSortInput, setApiSortInput] = useState<SortInput<ApiSortBy>>(initialSortInput)

  const tableSortProps: SortProps<TableData> = {
    input: apiSortInput as SortInputKey<TableData>,
    defaultInput: initialSortInput as SortInputKey<TableData>,

    onSort: (input: SortInputKey<TableData>): void =>
      setApiSortInput({
        ...input,
        by: input.by.replace('_', '.'),
      } as SortInput<ApiSortBy>),
    sortableColumns: sortableColumns as StringKeyOf<TableData>[],
  }

  return { sort: apiSortInput, props: tableSortProps }
}
