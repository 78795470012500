import { mapDTO } from '@twistezo/ts-dto-mapper'

import { CSManagerDTO } from '../api/types/workspaceDTO'
import { CSManager } from '../types/csManager'

export const csManagerFromDTO = (from: CSManagerDTO): CSManager =>
  mapDTO<CSManagerDTO, CSManager>({ from }).transform(csManagerDTO => {
    const { firstName, lastName } = csManagerDTO

    return {
      ...csManagerDTO,
      firstName: firstName || '',
      lastName: lastName || '',
    }
  })
