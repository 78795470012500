import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN_PREBID_STACK: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateDomainPrebidStack($input: UpdateDomainPrebidStackInput!) {
    updateDomainPrebidStack(input: $input) {
      domainPrebidStack {
        id
        customPrebidTimeoutEnabled
        prebidStackEnabled
        prebidTimeout
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`
