import { KeyboardArrowDown, Logout } from '@mui/icons-material'
import { Box, Button, Divider, ListItem, Menu, Typography } from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { ListItemButton } from 'components/List'
import { useAuthUser, useSignOut } from 'features/user'
import { useMenu } from 'hooks/useMenu'

export const UserMenu = (): JSX.Element => {
  const { t } = useTranslation('features/layout')
  const { user } = useAuthUser()
  const { loading, signOut } = useSignOut()
  const { anchor, isOpen, open, close } = useMenu()

  const { email, firstName, lastName, currentWorkspaceRole, role } = user

  const userName = () => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    } else if (firstName && _.isEmpty(lastName)) {
      return firstName
    } else return email
  }

  const userRole = currentWorkspaceRole
    ? t('userMenu.role', { role: currentWorkspaceRole })
    : _.startCase(role)

  const MenuElements = (): JSX.Element => (
    <>
      <ListItem data-cy='user-menu-items'>
        <Box>
          <Typography gutterBottom>{userName()}</Typography>
          <TypographyGreyedOut text={email} />
          <TypographyGreyedOut text={userRole} />
        </Box>
      </ListItem>

      <Divider sx={{ mb: 1 }} />

      <ListItemButton
        disabled={loading}
        icon={<Logout />}
        onClick={signOut}
        text={t('userMenu.logOut')}
      />
    </>
  )

  const TypographyGreyedOut = ({ text }: { text: string }): JSX.Element => (
    <Typography
      color='grey'
      gutterBottom
    >
      {text}
    </Typography>
  )

  return (
    <>
      <Button
        data-cy='menu-button-username'
        endIcon={<KeyboardArrowDown />}
        onClick={open}
      >
        <Typography variant='button'>{userName()}</Typography>
      </Button>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <MenuElements />
      </Menu>
    </>
  )
}
