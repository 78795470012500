import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { WorkspaceFormContext } from 'features/workspace/contexts/WorkspaceFormContext'
import { CommonForm } from './CommonForm'
import { useCreateWorkspace } from './hooks/useCreateWorkspace'
import { AddSchema, addSchema, addSchemaDefault } from './schemas/add'

export const WorkspaceAddForm = (): JSX.Element => {
  const { formId, loading, setTouched, setValid } = useContext(WorkspaceFormContext)
  const { errors: apiErrors, create } = useCreateWorkspace()

  return (
    <Form<AddSchema>
      id={formId}
      onSubmit={create}
      onTouched={setTouched}
      onValid={setValid}
      schema={addSchema}
      schemaDefaults={addSchemaDefault}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <CommonForm />)}
    </Form>
  )
}
