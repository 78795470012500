import { Edit, MoreVert } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { ListItemButton } from 'components/List'
import { canUpdate } from 'features/globalSetup'
import { DeleteItem } from 'features/globalSetup/pages/Prebid/Modules/components/DeleteItem'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { ActionsCellProps } from './types'

export const ActionsCell = ({ id, prebidModule }: ActionsCellProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { anchor, isOpen, open, close } = useMenu()

  const EditItem = (): JSX.Element => (
    <ListItemButton
      icon={<Edit />}
      text={t('form.action.edit')}
      onClick={() =>
        navigate(
          generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.VENDOR_SPECIFIC.EDIT, {
            id,
            workspaceId,
          }),
        )
      }
      data-cy='actions-edit'
    />
  )

  const MenuItems = (): JSX.Element[] => {
    const items: JSX.Element[] = []

    if (canUpdate({ account: prebidModule.account, ability, moduleType: 'vendorSpecific' })) {
      items.push(<EditItem key='edit' />)
    }

    if (ability.can('delete', 'GlobalSetupFeature-vendorSpecific')) {
      if (items.length > 0) {
        items.push(
          <Divider
            key='divider'
            sx={{ my: 1 }}
          />,
        )
      }
      items.push(
        <DeleteItem
          id={id}
          prebidModule={prebidModule}
          key='delete'
        />,
      )
    }

    return items
  }

  return MenuItems().length ? (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <MenuItems />
      </Menu>
    </>
  ) : (
    <></>
  )
}
