import { useState } from 'react'

import { UseMenu } from './types'

export const useMenu = (): UseMenu => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const isOpen = Boolean(anchor)

  const open: UseMenu['open'] = event => {
    setAnchor(event.currentTarget)
  }
  const close: UseMenu['close'] = () => {
    setAnchor(null)
  }

  return {
    anchor,
    isOpen,
    open,
    close,
  }
}
