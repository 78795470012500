import { Typography } from '@mui/material'

export const Line = ({ text }: { text: string }): JSX.Element => (
  <Typography
    variant='body2'
    sx={{ whiteSpace: 'pre-wrap' }}
  >
    {text}
  </Typography>
)
