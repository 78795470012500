import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Form } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { BidderEditParams } from '../../hooks/useTabs'
import { FormBody } from './components/FormBody'
import { defaults, schema, Schema } from './form/schema'
import { useUpdateDomainBidder } from './hooks/useUpdateDomainBidder'

export const BidParamsAndConnection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.edit.bidParamsAndConnection',
  })
  const { domainBidderId, domainId } = useParams<BidderEditParams>()

  const { formId, setTouched, setValid } = useContext(WorkspaceBidderContext)
  const { update, errors: apiErrors } = useUpdateDomainBidder()

  return (
    <Paper>
      <SectionHeader
        title={t('title')}
        subtitle={t('description')}
        bottomSpacing
      />

      <Form<Schema>
        id={formId}
        onSubmit={update}
        onTouched={setTouched}
        onValid={setValid}
        schema={schema}
        schemaDefaults={defaults({ domainBidderId, domainId })}
        externalErrors={apiErrors}
      >
        {() => <FormBody />}
      </Form>
    </Paper>
  )
}
