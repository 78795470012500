import { z } from 'zod'

import { commonSchema } from './commonSchema'

export const addSchema = commonSchema

export type AddSchema = z.infer<typeof commonSchema>

export const addSchemaDefault: AddSchema = {
  divId: undefined,
  domainId: '',
  name: undefined,
  path: '',
  outOfPage: false,
  outOfPageType: 'standard',
  pageIds: [],
}
