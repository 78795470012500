import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Row } from '@tanstack/react-table'
import _ from 'lodash'
import { ReactNode } from 'react'

import { SelectOption } from 'components/Select'
import { UserWorkspaceRole, userWorkspaceRoles } from 'features/user/types/userWorkspaceRole'
import { useAbility } from 'providers/casl'
import { useUpdatePublisher } from '../hooks/useUpdatePublisher'
import { PublisherForTable } from '../types'

export const UserRoleCell = ({ original, getParentRow }: Row<PublisherForTable>): ReactNode => {
  const ability = useAbility()
  const { role, id: workspaceId } = original
  const { email, id: userId } = { ...getParentRow()?.original }
  const { update } = useUpdatePublisher({ email })

  const show = role && workspaceId && userId

  if (!show) return null

  const canManage: boolean =
    ability.can('update', 'Publisher') && ability.can('delete', 'Publisher')

  const options: SelectOption<UserWorkspaceRole>[] = userWorkspaceRoles.map(
    (role: UserWorkspaceRole): SelectOption<UserWorkspaceRole> => ({
      value: role,
      label: _.startCase(role),
    }),
  )

  const handleChange = (event: SelectChangeEvent<UserWorkspaceRole>): void =>
    void update({ workspaceId, userId, role: event.target.value as UserWorkspaceRole })

  return (
    <Select
      variant='standard'
      size='small'
      value={role}
      onChange={handleChange}
      disabled={!canManage}
    >
      {options.map(
        (option: SelectOption<UserWorkspaceRole>): JSX.Element => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ),
      )}
    </Select>
  )
}
