import { Delete, Edit, MoreVert, Send } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ListItemButton } from 'components/List'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { useResendInvitation } from '../hooks/useResendInvitation'
import { UserActionsCellProps } from './types'

export const UserActionsCell = ({
  disabled,
  editPath,
  email,
  id,
  loading,
  onDelete,
}: UserActionsCellProps): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation('features/user')
  const { t: tC } = useTranslation('common')
  const navigate = useNavigate()
  const { anchor, isOpen, open, close } = useMenu()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const { resend } = useResendInvitation()

  const handleResend = (): void => {
    close()
    resend(id)
  }

  const handleDelete = (): void => {
    close()
    setIsDialogOpen(true)
  }

  const ResendInvitationItem = (): JSX.Element => (
    <ListItemButton
      icon={<Send />}
      text={t('resendInvitation.button')}
      onClick={handleResend}
      data-cy='actions-resend-invitation'
    />
  )

  const EditItem = (): JSX.Element => (
    <ListItemButton
      icon={<Edit />}
      text={tC('form.action.edit')}
      onClick={() => navigate(editPath)}
      data-cy='actions-edit'
    />
  )

  const DeleteItem = (): JSX.Element => (
    <ListItemButton
      icon={<Delete />}
      text={tC('form.action.delete')}
      onClick={handleDelete}
      data-cy='actions-delete'
    />
  )

  return (
    <>
      <IconButton
        onClick={open}
        disabled={disabled}
      >
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        {ability.can('create', 'WorkspaceUser') && <ResendInvitationItem />}
        {ability.can('update', 'WorkspaceUser') && <EditItem />}

        {ability.can('delete', 'WorkspaceUser') && [
          <Divider
            key='divider'
            sx={{ my: 1 }}
          />,
          <DeleteItem key='delete' />,
        ]}
      </Menu>

      <DeleteDialog
        elementName={email}
        dialog={{ isOpen: isDialogOpen, setOpen: setIsDialogOpen }}
        onDelete={onDelete}
        loading={loading}
        i18nResource={t}
      />
    </>
  )
}
