import _ from 'lodash'
import { useEffect, useState } from 'react'
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form'

type Props<T> = {
  data: Partial<T>
  path: Path<T>
  resetDependency?: unknown
  resetValue?: PathValue<T, Path<T>>
}

/**
 * ### Use case
 *
 * When you wrap `<Table>` with `<Form>` and your `data` is updated in async way
 * e.g. from API while paginating or filtering the table
 * and you want to keep the form data between these changes.
 */
export const useMergeFormData = <T extends FieldValues>({
  data,
  path,
  resetDependency,
  resetValue,
}: Props<T>): void => {
  const [resetTrigger, setResetTrigger] = useState<boolean>(false)

  useEffect(() => {
    setResetTrigger(true)
  }, [resetDependency])

  const { setValue, watch, resetField } = useFormContext<T>()

  const formData: T = watch()[path]
  const mergedWithForm: T = _.mergeWith(data, formData)

  useEffect(() => {
    if (_.isEmpty(data) || _.isEqual(formData, mergedWithForm)) return

    if (resetTrigger) {
      resetField(path, { defaultValue: resetValue })
      setResetTrigger(false)
    } else {
      setValue(path, mergedWithForm as PathValue<T, Path<T>>)
    }
  }, [formData, mergedWithForm]) // eslint-disable-line react-hooks/exhaustive-deps
}
