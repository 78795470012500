import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { BiddersForTable } from '../../types'
import { fromDTO } from './api/mapper'
import { GET_WORKSPACE_BIDDERS } from './api/query'
import { BiddersForTableDTO } from './api/types'
import { UseGetBidders, UseGetBiddersProps } from './types'

export const useGetBidders = ({ filters, first, last }: UseGetBiddersProps): UseGetBidders => {
  const { workspaceId } = useWorkspaceParam()
  const [bidders, setBidders] = useState<BiddersForTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_WORKSPACE_BIDDERS, {
    variables: {
      first,
      last,
      terms: filters,
      workspaceId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ workspaceBidders: { nodes: bidders, totalCount: count } }): void => {
      if (bidders) {
        setBidders(bidders.map((bidder: BiddersForTableDTO): BiddersForTable => fromDTO(bidder)))
        setCount(count)
      }
    },
  })

  return { bidders, loading, count }
}
