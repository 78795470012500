import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import Filter, { FilterValue } from 'components/Filter'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { WorkspaceUserTable } from 'features/user'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'

export const WorkspaceUserList = (): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/globalSetup')
  const [filters, setFilters] = useState<FilterValue[]>([])

  const navigate = useNavigate()

  const AddUserButton = (): JSX.Element => (
    <Button
      variant='contained'
      data-cy='add-user-button'
      onClick={() => navigate(generatePath(ROUTES.GLOBAL_SETUP.GENERAL.USER.ADD, { workspaceId }))}
    >
      {tC('actions.addNew')}
    </Button>
  )

  const UsersFilter = (
    <Filter
      id='usersFilter'
      onChange={setFilters}
      placeholder={tC('filter.search')}
      values={filters}
    />
  )

  return (
    <Paper>
      <TableHeader
        title={t('general.tab.userManagement')}
        actions={ability.can('create', 'WorkspaceUser') ? <AddUserButton /> : undefined}
        filter={UsersFilter}
        bottomSpacing
      />
      <WorkspaceUserTable filters={filters} />
    </Paper>
  )
}
