import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { NumberField, RadioGroupOption } from 'components/Form'
import {
  TargetingActionType,
  targetingActionTypes,
} from 'features/inventory/domain/types/targetingActionType'
import { useAbility } from 'providers/casl'
import { EditSchema } from '../../../../schemas/editSchema'

export const useTargetingActionTypeOptions = (): RadioGroupOption[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/domain')
  const { control } = useFormContext<EditSchema>()

  const disabled = !ability.can('update', 'DomianFeature-advancedSettings')

  const TargetingActionTypeSubforms = (
    <Stack direction='row'>
      <NumberField
        control={control}
        label={t('form.general.advancedSettings.margin.label')}
        name='margin'
        tooltip={{ content: t('form.general.advancedSettings.margin.tooltip') }}
        disabled={disabled}
      />

      <NumberField
        control={control}
        label={t('form.general.advancedSettings.mobileScaling.label')}
        name='mobileScaling'
        tooltip={{ content: t('form.general.advancedSettings.mobileScaling.tooltip') }}
        type='float'
        sx={{ ml: 2 }}
        disabled={disabled}
      />
    </Stack>
  )

  return targetingActionTypes.map(
    (targetingActionType: TargetingActionType): RadioGroupOption => ({
      label: t(
        `form.general.advancedSettings.targetingActionType.values.${targetingActionType}.title`,
      ),
      value: targetingActionType,
      tooltip: {
        content: t(
          `form.general.advancedSettings.targetingActionType.values.${targetingActionType}.tooltip`,
        ),
      },
      onSelectedContent:
        targetingActionType === 'onPageLoadAndLazyLoad' ? TargetingActionTypeSubforms : undefined,
    }),
  )
}
