import { KeyboardArrowDown } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { SelectOption } from 'components/Select'
import { useAuthUser } from 'features/user'
import useUpdateCurrentWorkspace from 'features/workspace/hooks/useUpdateCurrentWorkspace'
import { useMenu } from 'hooks/useMenu'
import { ROUTES } from 'routes'
import { Option } from './Option'
import { WorkspaceSlice } from './types'
import { initialOptions, toOptionProps, toSelectOptions } from './utils'

export const WorkspaceSelect = (): JSX.Element => {
  const {
    user: { currentWorkspace, id: userId, workspaces },
  } = useAuthUser()
  const navigate = useNavigate()

  const workspacesOptions = toSelectOptions(workspaces)
  const { update } = useUpdateCurrentWorkspace({ userId })

  const { anchor, isOpen, open, close } = useMenu()
  const [selectedWorkspace, setSelectedWorkspace] = useState<SelectOption<WorkspaceSlice>>(
    initialOptions(workspacesOptions, currentWorkspace?.id),
  )

  const handleUpdate = async (option: SelectOption<WorkspaceSlice>): Promise<void> => {
    const { value } = option

    const status = await update(value)
    if (status === 'failure') return

    setSelectedWorkspace(option)
    navigate(generatePath(ROUTES.PRODUCT.LIST, { workspaceId: value }), { replace: true })
    close()
  }

  return (
    <>
      <Button
        endIcon={<KeyboardArrowDown />}
        onClick={open}
        data-cy='workspace-select-menu'
      >
        <Option {...toOptionProps(selectedWorkspace)} />
      </Button>

      <Menu
        anchorEl={anchor}
        open={isOpen}
        onClose={close}
      >
        {workspacesOptions.map(
          (option: SelectOption<WorkspaceSlice>): JSX.Element => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedWorkspace.value}
              onClick={() => void handleUpdate(option)}
              data-cy={option.label}
            >
              <Option {...toOptionProps(option)} />
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  )
}
