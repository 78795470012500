import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceUserDTO } from 'features/globalSetup/api/types/workspaceUser'
import { WorkspaceUser } from 'features/globalSetup/types/workspaceUser'

export const fromDTO = (from: WorkspaceUserDTO): WorkspaceUser =>
  mapDTO<WorkspaceUserDTO, WorkspaceUser>({ from }).transform(
    ({ id, email, currentWorkspaceRole }: WorkspaceUserDTO): WorkspaceUser => ({
      id,
      email,
      currentWorkspaceRole: currentWorkspaceRole || undefined,
    }),
  )
