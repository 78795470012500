import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspacePrebidModuleDTO } from 'features/globalSetup/api/types/workspacePrebidModule'

export const WORKSPACE_PREBID_MODULE_FRAGMENT: TypedDocumentNode<WorkspacePrebidModuleDTO> = gql`
  fragment WorkspacePrebidModuleFragment on WorkspacePrebidModule {
    id
    enabled
    prebidModule {
      name
      demand
    }
    requested
    updatedAt
  }
`
