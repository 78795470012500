import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EmployeeBox } from 'components/Box'
import { BoxForm, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { PubAdsServiceRefreshInput } from '../../components/PubAdsServiceRefreshInput'
import { TargetingActionTypeInput } from '../../components/TargetingActionTypeInput'
import { EditSchema } from '../../schemas/editSchema'
import { FailsafeTimeout } from './FailsafeTimeout'

export const AdvancedSettings = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/domain', 'common'])
  const { control } = useFormContext<EditSchema>()

  return (
    <Paper>
      <SectionHeader
        title={t('form.general.advancedSettings.title')}
        subtitle={t('form.general.advancedSettings.subtitle')}
        bottomSpacing
      />

      <BoxForm>
        <TargetingActionTypeInput />

        <PubAdsServiceRefreshInput />

        {ability.can('update', 'DomainField-overriddenByYieldbird') && (
          <EmployeeBox>
            <Switch
              control={control}
              name='overriddenByYieldbird'
              label={t('form.general.advancedSettings.overriddenByYieldbird.label')}
              labelPlacement='start'
            />
          </EmployeeBox>
        )}

        <FailsafeTimeout />
      </BoxForm>
    </Paper>
  )
}
