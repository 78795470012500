import { RouteObject } from 'react-router-dom'

import { AmazonPage } from 'features/globalSetup/features/amazon'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const amazonRoutes: RouteObject[] = [
  {
    path: ROUTES.GLOBAL_SETUP.AMAZON.ROOT,
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-amazon'
        page={<AmazonPage />}
      />
    ),
  },
]
