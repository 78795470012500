import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { EditBidderForm } from 'features/globalSetup/forms/Prebid/Bidders/EditBidderForm'
import { EnabledSwitch } from './components/EnabledSwitch'

export const GeneralPage = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.edit.general',
  })
  const { workspaceBidder } = useContext(WorkspaceBidderContext)

  if (!workspaceBidder) {
    throw Error('Workspace Bidder should be accessible')
  }

  const { enabled, id, bidderCode, accountType, status } = workspaceBidder

  return (
    <>
      <Paper>
        <SectionHeader
          title={t('title')}
          subtitle={t('description', { bidderCode })}
          titleEndAdornment={
            <EnabledSwitch
              enabled={enabled}
              bidderCode={bidderCode}
              id={id}
              accountType={accountType}
              status={status}
            />
          }
        />
      </Paper>

      <EditBidderForm />
    </>
  )
}
