import { mapDTO } from '@twistezo/ts-dto-mapper'

import { User } from 'features/user'
import { WorkspaceDTO, workspaceFromDTO } from 'features/workspace'
import { UserDTO } from '../types/userDTO'

export const userFromDTO = (from: UserDTO): User =>
  mapDTO<UserDTO, User>({ from }).transform(userDTO => {
    const {
      createdAt,
      currentWorkspace,
      currentWorkspaceRole,
      firstName,
      lastName,
      updatedAt,
      userWorkspaces,
      workspaceProducts,
      workspaces,
    } = userDTO

    return {
      ...userDTO,
      createdAt: new Date(createdAt),
      currentWorkspace: currentWorkspace ? workspaceFromDTO(currentWorkspace) : undefined,
      currentWorkspaceRole: currentWorkspaceRole || undefined,
      firstName: firstName || '',
      lastName: lastName || '',
      updatedAt: new Date(updatedAt),
      userWorkspaces: userWorkspaces || undefined,
      workspaceProducts: workspaceProducts || undefined,
      workspaces: workspaces.map((workspaceDTO: WorkspaceDTO) => workspaceFromDTO(workspaceDTO)),
    }
  })
