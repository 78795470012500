import { Autocomplete, AutocompleteRenderInputParams, Chip, TextField } from '@mui/material'
import { useContext } from 'react'
import { FieldValues } from 'react-hook-form'

import { FilterableListBoxContext } from 'components/FilterableListBox'
import { ChipsInputProps } from './types'

export const ChipsInput = <T extends FieldValues>({
  name,
  onChange,
  disabled,
  value,
}: ChipsInputProps<T>): JSX.Element => {
  const { filter } = useContext(FilterableListBoxContext)

  const isFiltered = (option: string): boolean => !filter || option.includes(filter)

  return (
    <Autocomplete
      disableClearable
      freeSolo
      id={name}
      multiple
      className='ChipsListFilterable'
      onChange={(_, values: string[]): void => onChange(values)}
      open={false}
      options={[]}
      fullWidth
      disabled={disabled}
      value={value}
      renderInput={(props: AutocompleteRenderInputParams) => <TextField {...props} />}
      renderTags={(value, getTagProps) =>
        value.map(
          (option: string, index: number): JSX.Element =>
            isFiltered(option) ? (
              <Chip
                {...getTagProps({ index })}
                key={option}
                label={option}
              />
            ) : (
              <></>
            ),
        )
      }
    />
  )
}
