import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, RadioGroup, Select } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Hint from 'components/Hint'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { SelectOption } from 'components/Select'
import { useAccountTypeOptions } from '../../hooks/useAccountTypeOptions'
import {
  PrebidModuleSlice,
  useGetPrebidModulesForSelect,
} from '../../hooks/useGetPrebidModulesForSelect'
import { AddSchema } from '../../schemas/addSchema'
import { SELECT_HINT_LEARN_MORE } from '../constants'

export const MainSection = (): JSX.Element => {
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.analytics.add.analytics',
  })
  const accountTypeOptions = useAccountTypeOptions()
  const { control, watch } = useFormContext<AddSchema>()
  const { accountType } = watch()

  const { options } = useGetPrebidModulesForSelect({ moduleType: 'analytics' })
  const filteredOptions = options.filter(
    (option: SelectOption<PrebidModuleSlice>): boolean => option.rawData?.account === accountType,
  )

  return (
    <Paper>
      <SectionHeader
        title={t('header.title')}
        subtitle={t('header.subtitle')}
        bottomSpacing
      />

      <BoxForm>
        <RadioGroup
          control={control}
          name='accountType'
          label={t('account.label')}
          options={accountTypeOptions}
          row
        />

        <Box>
          <Select
            control={control}
            name='prebidModuleId'
            label={t('prebidModuleId.label')}
            options={filteredOptions}
          />

          <Hint
            content={t('prebidModuleId.hint')}
            contentEndAdornment={
              <Link
                to={SELECT_HINT_LEARN_MORE}
                newTab
              >
                {tc('learnMore')}
              </Link>
            }
          />
        </Box>
      </BoxForm>
    </Paper>
  )
}
