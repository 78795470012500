import { Button, TextField } from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Loading from 'components/Loading'
import Paper from 'components/Paper'
import { KNOWLEDGE_BASE } from 'features/globalSetup/constants'
import { GeneralPageContext } from 'features/globalSetup/contexts/GeneralPageContext'
import { GamConnectionOrder } from 'features/workspace/types/gamConnectionOrder'
import { useAbility } from 'providers/casl'
import { ConnectionStatus } from './components/ConnectionStatus'
import { useCreateConnection } from './hooks/useCreateConnection'
import { useGetConnection } from './hooks/useGetConnection'

export const GoogleAdManagerConnection = (): JSX.Element => {
  const ability = useAbility()
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation(['features/globalSetup'], {
    keyPrefix: 'general.googleAdManagerConnection',
  })

  const {
    workspace: { networkCode },
  } = useContext(GeneralPageContext)

  const [connection, setConnection] = useState<GamConnectionOrder | undefined>(undefined)
  const { loading: loadingCurrentConnection } = useGetConnection(setConnection)
  const { create, loading: loadingCreatedConnection } = useCreateConnection(setConnection)

  const GoogleAdManagerIdSection = (): JSX.Element => (
    <Paper>
      <SectionHeader
        title={t('networkCode.header.title')}
        subtitle={t('networkCode.header.description')}
        bottomSpacing
      />

      <TextField
        disabled
        id='networkCode'
        label={t('networkCode.inputLabel')}
        value={networkCode}
      />
    </Paper>
  )

  const ConnectionStatusSection = (): JSX.Element => {
    const disabled =
      loadingCreatedConnection ||
      (connection && connection.status !== 'failed') ||
      !ability.can('update', 'GlobalSetupFeature-connect')

    return (
      <Paper>
        <SectionHeader
          title={t('connectionStatus.header.title')}
          subtitle={t('connectionStatus.header.description')}
          subtitleEndAdornment={
            <TextLink
              link={{
                title: tC('learnMore'),
                url: KNOWLEDGE_BASE.GOOGLE_AD_MANAGER_CONNECTION,
              }}
            />
          }
          bottomSpacing
        />

        {connection && <ConnectionStatus {...connection} />}

        <Button
          disabled={disabled}
          onClick={create}
          variant='contained'
        >
          {t('connectionStatus.button')}
        </Button>
      </Paper>
    )
  }

  return (
    <>
      <GoogleAdManagerIdSection />
      {loadingCurrentConnection ? <Loading /> : <ConnectionStatusSection />}
    </>
  )
}
