import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import { PrebidModuleAccount, prebidModuleAccounts } from 'features/globalSetup/types/prebidModule'

export const useAccountTypeOptions = (): RadioGroupOption[] => {
  const { t } = useTranslation('features/globalSetup')

  return prebidModuleAccounts.map(
    (value: PrebidModuleAccount): RadioGroupOption => ({
      label: t(`prebid.modules.userId.add.userId.account.values.${value}`),
      value,
    }),
  )
}
