import _ from 'lodash'

import { AdUnitParametersDTO } from 'features/globalSetup/api/types/adUnitBidderDTO'
import { AdUnitParameter, AdUnitParameters } from 'features/globalSetup/types/adUnitBidder'
import { DomainBidder, DomainBidderParameter } from 'features/globalSetup/types/domainBidder'
import { Primitive } from 'types/primitive'
import { withoutOuterQuotes } from 'utils/string'

export const mergeDomainBidderSchema = (
  adUnitParams: AdUnitParametersDTO,
  domainParamsSchema: DomainBidder['paramsSchema'],
): AdUnitParameters =>
  Object.fromEntries(
    domainParamsSchema.map(
      (schema: DomainBidderParameter): [keyof AdUnitParameters, AdUnitParameter] => {
        const { name, type } = schema

        return [
          name,
          {
            ...schema,
            value: parseValue(adUnitParams[name], type),
          },
        ]
      },
    ),
  )

const parseValue = (value: unknown, type: Primitive): string | number => {
  let parsedValue: string | number

  if (_.isNil(value)) {
    parsedValue = ''
  } else if (type === 'string') {
    parsedValue = withoutOuterQuotes(JSON.stringify(value))
  } else {
    parsedValue = value as number
  }

  return parsedValue
}
