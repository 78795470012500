import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainViewabilityTools } from '../../types/domainViewabilityTools'
import { DomainViewabilityToolsDTO } from '../types/domainViewabilityToolsDTO'

export const domainViewabilityToolsFromDTO = (
  from: DomainViewabilityToolsDTO,
): DomainViewabilityTools =>
  mapDTO<DomainViewabilityToolsDTO, DomainViewabilityTools>({ from }).transform(dvtDTO => {
    const { fetchMargin, renderMargin, slowingFactor, trafficAffected, createdAt, updatedAt } =
      dvtDTO

    return {
      ...dvtDTO,
      fetchMargin: fetchMargin * 100,
      renderMargin: renderMargin * 100,
      slowingFactor: slowingFactor * 100,
      trafficAffected: trafficAffected * 100,
      createdAt: new Date(createdAt),
      updatedAt: new Date(updatedAt),
    }
  })
