import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAuthUser } from 'features/user'
import { Workspace } from 'features/workspace'
import useUpdateCurrentWorkspace from 'features/workspace/hooks/useUpdateCurrentWorkspace'
import { useAbility } from 'providers/casl'
import { SidebarItem } from '../types'
import { configAdmin } from './configAdmin'
import { configGeneral } from './configGeneral'
import { ConfigGeneralProps } from './types'

export const useConfig = (): readonly SidebarItem[] => {
  const { t } = useTranslation('features/layout')
  const { t: tProduct } = useTranslation('features/product')
  const navigate = useNavigate()

  const ability = useAbility()
  const {
    user: { currentWorkspace, id },
    helpers: { isEmployeeView },
  } = useAuthUser()
  const { update } = useUpdateCurrentWorkspace({ userId: id })

  const configGeneralProps = (
    currentWorkspaceId: Workspace['id'],
    networkCode: Workspace['networkCode'],
  ): ConfigGeneralProps => ({
    workspaceId: currentWorkspaceId,
    networkCode,
    ability,
    updateCurrentWorkspace: update,
    navigate,
    t,
    tProduct,
  })

  if (isEmployeeView) {
    return configAdmin(t)
  } else if (currentWorkspace !== undefined) {
    const { id: workspaceId, networkCode } = currentWorkspace

    return configGeneral(configGeneralProps(workspaceId, networkCode))
  } else {
    throw Error('There are no more sidebar configs')
  }
}
