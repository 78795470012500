import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import {
  SupplyChainContext,
  SupplyChainContextType,
} from 'features/globalSetup/contexts/SupplyChainContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { AddSupplyChainForm } from './AddSupplyChainForm'

export const SupplyChainAdd = () => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation(['features/globalSetup'], {
    keyPrefix: 'supplyChain.general.common',
  })

  const contextProps: SupplyChainContextType = useCommonFormContextProps({
    formId: 'supply-chain-form-add',
  })

  return (
    <SupplyChainContext.Provider value={contextProps}>
      <SupplyChainContext.Consumer>
        {(ctxProps: SupplyChainContextType): JSX.Element => (
          <PageHeader
            backTo={generatePath(ROUTES.GLOBAL_SETUP.SCHAIN.LIST, {
              workspaceId,
            })}
            actions={<SaveButton {...ctxProps} />}
            title={t('title')}
          />
        )}
      </SupplyChainContext.Consumer>

      <AddSupplyChainForm />
    </SupplyChainContext.Provider>
  )
}
