import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { WorkspaceUser } from 'features/globalSetup/types/workspaceUser'
import { fromDTO } from './api/mapper'
import { GET_WORKSPACE_USER } from './api/query'
import { UseGetWorkspaceUser, UseGetWorkspaceUserProps } from './types'

export const useGetWorkspaceUser = ({ id }: UseGetWorkspaceUserProps): UseGetWorkspaceUser => {
  const [user, setUser] = useState<WorkspaceUser>()

  useEffect(() => {
    if (id) {
      query({ variables: { id } })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_WORKSPACE_USER, {
    onCompleted: ({ node: user }) => {
      if (user) {
        setUser(fromDTO(user))
      }
    },
  })

  return { user, loading }
}
