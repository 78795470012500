import { Menu as MenuIcon } from '@mui/icons-material'
import { Box, IconButton, Toolbar } from '@mui/material'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import Loading from 'components/Loading'
import Sidebar, { useConfig } from 'features/layout/components/Sidebar'
import { SidebarItem } from 'features/layout/components/Sidebar/types'
import UserMenu from 'features/layout/components/UserMenu'
import { OUTLET_MAX_WIDTH, SIDEBAR_SPACING } from 'features/layout/contants'
import { useAuthUser } from 'features/user'
import { useMatchWorkspaceUrlParam } from 'features/workspace/hooks/useMatchWorkspaceUrlParam'
import InactiveWorkspace from 'features/workspace/pages/InactiveWorkspace'
import { AppBarStyled } from './components/AppBarStyled'
import { Logo } from './components/Logo'
import { WorkspaceSelectContainer } from './components/WorkspaceSelectContainer'

export const LayoutPage = (): JSX.Element => {
  const {
    helpers: { canUseWorkspace },
  } = useAuthUser()
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false)
  const sidebarConfig: readonly SidebarItem[] = useConfig()

  const { loading } = useMatchWorkspaceUrlParam()
  if (loading) return <Loading variant='full-screen' />

  const SidebarToggle = (): JSX.Element => (
    <IconButton onClick={() => void setSidebarCollapsed(!sidebarCollapsed)}>
      <MenuIcon />
    </IconButton>
  )

  return (
    <>
      <AppBarStyled>
        <Toolbar>
          <SidebarToggle />
          <Logo />
          <WorkspaceSelectContainer />
          <Box sx={{ flexGrow: 1 }} />
          <UserMenu />
        </Toolbar>
      </AppBarStyled>

      <Sidebar
        config={sidebarConfig}
        collapsedX={sidebarCollapsed}
        onCollapsedX={setSidebarCollapsed}
      />

      <Box
        sx={{
          ml: sidebarCollapsed ? SIDEBAR_SPACING.MIN : SIDEBAR_SPACING.MAX,
          maxWidth: OUTLET_MAX_WIDTH,
        }}
      >
        {canUseWorkspace ? <Outlet /> : <InactiveWorkspace />}
      </Box>
    </>
  )
}
