import { Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Loading from 'components/Loading'
import Paper from 'components/Paper'
import { ViewportListContext } from 'features/inventory/viewport/contexts/ViewportListContext'
import { useAbility } from 'providers/casl'
import { useGetViewports } from './hooks/useGetViewports'
import { useTempViewports } from './hooks/useTempViewports'
import { ListHeaders } from './ListHeaders'
import { ListRows } from './ListRows'

export const ViewportList = () => {
  const ability = useAbility()
  const { t } = useTranslation('features/viewport')
  const { viewports, loading } = useGetViewports()
  const { viewports: tempViewports, add, update } = useTempViewports()

  const showHeaders = viewports.length > 0 || tempViewports.length > 0

  if (loading) {
    return <Loading />
  }

  return (
    <Paper>
      <ViewportListContext.Provider
        value={{
          update,
          viewports,
        }}
      >
        <Grid
          container
          rowGap={1}
        >
          {showHeaders && <ListHeaders />}
          <ListRows
            rows={viewports}
            variant='edit'
          />
          <ListRows
            rows={tempViewports}
            variant='add'
          />
        </Grid>

        {ability.can('create', 'Viewport') && (
          <Button
            data-cy='add-viewport'
            onClick={add}
            sx={{ mt: 1, width: 'fit-content' }}
          >
            {t('list.addNext')}
          </Button>
        )}
      </ViewportListContext.Provider>
    </Paper>
  )
}
