import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { workspaceFromDTO } from './api/mapper'
import { GET_WORKSPACE_FOR_FORM } from './api/query'
import { UseGetWorkspace, UseGetWorkspaceProps, WorkspaceSlice } from './types'

export const useGetWorkspace = ({ id }: UseGetWorkspaceProps): UseGetWorkspace => {
  const [workspace, setWorkspace] = useState<WorkspaceSlice>()

  useEffect(() => {
    if (id) {
      query({ variables: { id } })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_WORKSPACE_FOR_FORM, {
    onCompleted: ({ node: workspace }) => {
      if (workspace) {
        setWorkspace(workspaceFromDTO(workspace))
      }
    },
  })

  return { workspace, loading }
}
