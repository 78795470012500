import { Box, useTheme } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { BoxForm, NumberField, RadioGroup, Select, StackForm, TextField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Hint from 'components/Hint'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { useIsFieldDisabled } from 'features/globalSetup/forms/Prebid/Bidders/hooks/useIsFieldDisabled'
import { CommonFormProps } from 'features/globalSetup/forms/Prebid/Bidders/types'
import { yieldbirdStatusColor } from 'features/globalSetup/forms/Prebid/Bidders/utils'
import { useGetPrioritiesForSelect } from 'features/globalSetup/hooks/useGetPrioritiesForSelect'
import { Status } from 'features/globalSetup/types/workspaceBidder'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { SELECT_HINT_LEARN_MORE } from './constants'
import { useAccountTypeOptions } from './hooks/useAccountTypeOptions'
import { useGetYieldbirdStatusesForSelect } from './hooks/useGetYieldbirdStatusesForSelect/useGetYieldbirdStatusesForSelect '
import { CommonSchema } from './schemas/commonSchema'
import { useGetBiddersForSelect } from './sections/hooks/useGetBiddersForSelect'
import { useGetConsentModesForSelect } from './sections/hooks/useGetConsentModesForSelect'
import { useGetSupplyChainsForSelect } from './sections/hooks/useGetSupplyChainsForSelect'

export const CommonForm = ({ variant }: CommonFormProps) => {
  const { workspaceId } = useWorkspaceParam()
  const theme = useTheme()
  const { isFieldDisabled } = useIsFieldDisabled()
  const { control, watch } = useFormContext<CommonSchema>()
  const { t: tc } = useTranslation(['features/globalSetup', 'common'])
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.add',
  })

  const { accountType } = watch()
  const accountTypeOptions = useAccountTypeOptions()
  const statuses = useGetYieldbirdStatusesForSelect()
  const bidders = useGetBiddersForSelect(accountType)
  const consentModes = useGetConsentModesForSelect()
  const priorities = useGetPrioritiesForSelect({ variant: 'workspaceBidder' })
  const supplyChains = useGetSupplyChainsForSelect()

  return (
    <Paper>
      <SectionHeader
        title={t('basicAttributes.header.title')}
        subtitle={t('basicAttributes.header.subtitle')}
        bottomSpacing
      />

      <BoxForm>
        <RadioGroup
          control={control}
          name='accountType'
          label={tc('common.accountType.label')}
          options={accountTypeOptions}
          tooltip={{
            content: tc('common.accountType.tooltip'),
          }}
          row
          disabled={variant === 'edit'}
        />

        {accountType === 'yieldbird' && variant === 'edit' && (
          <Select
            control={control}
            name='status'
            label={tc('common.status.label')}
            options={statuses}
            labelColor={({ value }: { value: Status }) =>
              yieldbirdStatusColor({ status: value, theme })
            }
            disabled={isFieldDisabled('status')}
            tooltip={{
              content: tc('common.status.tooltip'),
            }}
          />
        )}

        {accountType === 'thirdPartyProvider' && (
          <Select
            control={control}
            name='supplyChain'
            label={tc('common.supplyChain.label')}
            options={supplyChains}
            tooltip={{
              content: tc('common.supplyChain.tooltip.content'),
              link: {
                title: tc('common.supplyChain.tooltip.link'),
                url: generatePath(ROUTES.GLOBAL_SETUP.SCHAIN.LIST, { workspaceId }),
              },
            }}
            disabled={variant === 'edit'}
          />
        )}

        <StackForm>
          <Box>
            <Select
              control={control}
              name='bidderId'
              label={t('basicAttributes.bidderId.label')}
              options={bidders}
              disabled={variant === 'edit' || isFieldDisabled('bidderId')}
            />

            <Hint
              content={t('basicAttributes.bidderId.hint')}
              contentEndAdornment={
                <Link
                  to={SELECT_HINT_LEARN_MORE}
                  newTab
                >
                  {tc('common:learnMore')}
                </Link>
              }
            />
          </Box>

          <TextField
            control={control}
            label={t('basicAttributes.bidderCode.label')}
            name='bidderCode'
            placeholder={t('basicAttributes.bidderCode.label')}
            tooltip={{
              content: t('basicAttributes.bidderCode.tooltip'),
            }}
            disabled={isFieldDisabled('bidderCode')}
          />
        </StackForm>

        <StackForm>
          <TextField
            control={control}
            label={t('basicAttributes.bidderSid.label')}
            name='bidderSid'
            placeholder={t('basicAttributes.bidderSid.label')}
            tooltip={{
              content: t('basicAttributes.bidderSid.tooltip'),
            }}
            disabled={isFieldDisabled('bidderSid')}
          />

          <NumberField
            control={control}
            label={t('basicAttributes.bidCpmAdjustment.label')}
            name='bidCpmAdjustment'
            tooltip={{
              content: t('basicAttributes.bidCpmAdjustment.tooltip'),
            }}
            disabled={isFieldDisabled('bidCpmAdjustment')}
          />

          <Select
            control={control}
            name='priority'
            label={t('basicAttributes.priority.label')}
            options={priorities}
            tooltip={{
              content: t('basicAttributes.priority.tooltip'),
            }}
            disabled={isFieldDisabled('priority')}
          />

          <Select
            control={control}
            name='consentMode'
            label={t('basicAttributes.consentMode.label')}
            options={consentModes}
            tooltip={{
              content: t('basicAttributes.consentMode.tooltip'),
            }}
            disabled={isFieldDisabled('consentMode')}
          />
        </StackForm>
      </BoxForm>
    </Paper>
  )
}
