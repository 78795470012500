import { z } from 'zod'

import { WorkspaceProductSlice } from './types'

export const schema = z.object({
  id: z.string(),
  performanceReportUrl: z.string(),
  emails: z.array(z.string()),
})

export type Schema = z.infer<typeof schema>

export const schemaDefaults = (workspaceProduct: WorkspaceProductSlice): Schema => {
  const { id, performanceReportUrl, emails } = workspaceProduct

  return {
    id,
    performanceReportUrl: performanceReportUrl ?? '',
    emails,
  }
}
