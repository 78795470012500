import { ViewportListItem } from 'features/inventory/viewport/types/viewportListItem'

export const composeTemporaryViewport = (): ViewportListItem => ({
  bannerSizes: [],
  id: new Date().toISOString(),
  minimumWidth: 0,
  name: '',
  nativeEnabled: false,
  playerSize: [],
})
