import { useState } from 'react'

import { ViewportListItem } from 'features/inventory/viewport/types/viewportListItem'
import { UseTempViewports } from './types'
import { composeTemporaryViewport } from './utils'

export const useTempViewports = (): UseTempViewports => {
  const [viewports, setViewports] = useState<ViewportListItem[]>([])

  const add = (): void => setViewports([...viewports, composeTemporaryViewport()])

  const update = (id: ViewportListItem['id']): void =>
    setViewports(viewports.filter(viewport => viewport.id !== id))

  return {
    add,
    update,
    viewports,
  }
}
