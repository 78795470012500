import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, Select, SelectAutocomplete, TextField } from 'components/Form'
import Loading from 'components/Loading'
import Paper from 'components/Paper'
import { userWorkspaceRoleOptions } from 'features/user'
import { useGetWorkspaceOptions } from '../hooks/useGetWorkspaceOptions'
import { FieldArrayInput } from './components/form/FieldArrayInput'
import { Schema } from './schema'
import { UserWorkspaceAttribute } from './types'

export const UserAddPublisherForm = (): JSX.Element => {
  const { t } = useTranslation('features/user')
  const { control, register, watch } = useFormContext<Schema>()
  const { workspaceOptions, loading } = useGetWorkspaceOptions()

  const { userWorkspaceAttributes } = watch()

  if (loading) {
    return <Loading />
  }

  const addNextDisabled = (): boolean =>
    userWorkspaceAttributes.some(
      (userWorkspaceAttribute: UserWorkspaceAttribute) => !userWorkspaceAttribute.workspaceId,
    )

  const workspaceSelect = (index: number): JSX.Element => (
    <SelectAutocomplete
      key={`userWorkspaceAttributes.${index}.workspaceId`}
      control={control}
      name={register(`userWorkspaceAttributes.${index}.workspaceId`).name}
      options={workspaceOptions}
      disabledOptions={userWorkspaceAttributes.map(
        ({ workspaceId }: UserWorkspaceAttribute) => workspaceId,
      )}
    />
  )

  const roleSelect = (index: number): JSX.Element => (
    <Select
      key={`userWorkspaceAttributes.${index}.role`}
      control={control}
      name={register(`userWorkspaceAttributes.${index}.role`).name}
      options={userWorkspaceRoleOptions()}
    />
  )

  return (
    <Paper>
      <BoxForm>
        <TextField
          control={control}
          name='email'
          label={t('form.publisher.add.email.label')}
          placeholder={t('form.publisher.add.email.placeholder')}
          sx={{ mb: 2 }}
        />

        <FieldArrayInput<Schema>
          control={control}
          name='userWorkspaceAttributes'
          defaults={{ workspaceId: '', role: 'viewer' } as UserWorkspaceAttribute}
          addNextDisabled={addNextDisabled()}
          inputs={[
            {
              label: t('form.publisher.common.workspaceId.label'),
              cell: workspaceSelect,
            },
            {
              label: t('form.publisher.common.role.label'),
              cell: roleSelect,
            },
          ]}
        />
      </BoxForm>
    </Paper>
  )
}
