import { Search } from '@mui/icons-material'
import {
  Box,
  Card,
  Divider,
  FormHelperText,
  FormLabel,
  InputBase,
  Stack,
  Theme,
} from '@mui/material'
import { ChangeEvent, CSSProperties, useState } from 'react'
import { FieldError } from 'react-hook-form'

import { INPUT_WIDER_WIDTH } from 'providers/material-ui/theme/constants'
import { FilterableListBoxContext } from './context/FilterableListBoxContext'
import { FilteredListBoxRootProps } from './types'

export const FilterableListBox = ({
  children,
  placeholder,
  label,
  error,
}: FilteredListBoxRootProps): JSX.Element => {
  const [filter, setFilter] = useState<string>('')

  const ErrorSection = ({ error }: { error: FieldError }): JSX.Element => (
    <>
      <Divider />
      <FormHelperText
        sx={{
          p: 2,
          color: (theme: Theme): CSSProperties['color'] => theme.palette.error.main,
        }}
      >
        {error?.message}
      </FormHelperText>
    </>
  )

  return (
    <Stack spacing={1}>
      <FormLabel>{label}</FormLabel>

      <Box sx={{ width: INPUT_WIDER_WIDTH }}>
        <Card
          sx={{
            border: (theme: Theme): CSSProperties['border'] =>
              `1px solid ${error ? theme.palette.error.main : theme.palette.grey[400]}`,
          }}
        >
          <InputBase
            data-cy='list-box-filter'
            endAdornment={<Search />}
            fullWidth
            onChange={(event: ChangeEvent<HTMLInputElement>): void => setFilter(event.target.value)}
            placeholder={placeholder}
            sx={{ p: 2 }}
            value={filter}
          />

          <Divider />

          <FilterableListBoxContext.Provider value={{ filter }}>
            {children}
          </FilterableListBoxContext.Provider>

          {error && ErrorSection({ error })}
        </Card>
      </Box>
    </Stack>
  )
}
