import { z } from 'zod'

import { commonSchema } from './common'

export const addSchema = commonSchema

export type AddSchema = z.infer<typeof commonSchema>

export const addSchemaDefault: AddSchema = {
  name: '',
  networkCode: '',
  crmId: '',
  pipedriveLink: '',
  csManagerId: '',
}
