import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { EditSchema } from 'features/globalSetup/forms/Prebid/Bidders/schemas/editSchema'
import { mapGraphQLErrors } from 'providers/graphql'
import { toDTO } from './api/mapper'
import { UPDATE_WORKSPACE_BIDDER } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateWorkspaceBidder, UseUpdateWorkspaceBidderProps } from './types'

export const useUpdateWorkspaceBidder = ({
  id,
  bidderCode,
}: UseUpdateWorkspaceBidderProps): UseUpdateWorkspaceBidder => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.list.updateEnabled.action',
  })
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(WorkspaceBidderContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutate] = useMutation(UPDATE_WORKSPACE_BIDDER, {
    refetchQueries: ['getWorkspaceBidder'],
    onCompleted: ({ updateWorkspaceBidder: { errors } }) => {
      setLoading(false)

      const tParams = {
        bidderCode,
        action: 'updated',
      } as const

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('error', tParams), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('success', tParams), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: EditSchema): Promise<FetchResult<Data>> => {
    setLoading(true)

    return mutate({
      variables: {
        input: toDTO({
          from: data,
          workspaceBidderId: id,
        }),
      },
    })
  }

  return { update, errors }
}
