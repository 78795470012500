import { Stack, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { KNOWLEDGE_BASE_URL } from './constants'

export const InactiveWorkspace = (): JSX.Element => {
  const { t } = useTranslation('features/workspace')

  return (
    <Paper>
      <FlexBox axis='xy'>
        <Typography
          variant='h4'
          mb={2}
        >
          {t('inactive.title')}
        </Typography>

        <Typography>{t('inactive.contact')}</Typography>

        <Stack direction='row'>
          <Trans
            i18nKey='inactive.more'
            t={t}
            components={{
              url: (
                <Link
                  newTab
                  sx={{ pl: 1 }}
                  to={KNOWLEDGE_BASE_URL}
                />
              ),
            }}
          />
        </Stack>
      </FlexBox>
    </Paper>
  )
}
