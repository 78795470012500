import { generatePath, Navigate, useParams } from 'react-router-dom'

import { BoxWithLogo } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import {
  PrebidModulesContext,
  PrebidModulesContextType,
} from 'features/globalSetup/contexts/PrebidModulesContext'
import { prebidModuleLogos } from 'features/globalSetup/forms/Prebid/Modules/logos/prebidModuleLogos'
import { EditContainer } from 'features/globalSetup/forms/Prebid/Modules/UserIdsForm'
import { useGetWorkspacePrebidModule } from 'features/globalSetup/pages/Prebid/Modules/hooks/useGetWorkspacePrebidModule'
import { ROUTES } from 'routes'
import { UrlParams } from './types'

export const UserIdsEdit = (): JSX.Element => {
  const { workspaceId, id: urlWorkspacePrebidModuleId } = useParams<UrlParams>()
  const { workspacePrebidModule } = useGetWorkspacePrebidModule({ id: urlWorkspacePrebidModuleId })

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'prebid-modules-user-ids-form-edit',
  })

  if (
    !workspaceId ||
    !urlWorkspacePrebidModuleId ||
    (workspacePrebidModule && workspacePrebidModule.prebidModule.account !== 'own')
  ) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!workspacePrebidModule) {
    return <Loading />
  }

  const contextProps: PrebidModulesContextType = {
    workspacePrebidModule,
    ...commonContextProps,
  }

  const prebidModuleName: string = workspacePrebidModule.prebidModule.name

  return (
    <PrebidModulesContext.Provider value={contextProps}>
      <PrebidModulesContext.Consumer>
        {(ctxProps: PrebidModulesContextType): JSX.Element => (
          <PageHeader
            actions={<SaveButton {...ctxProps} />}
            backTo={generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.USER_ID.LIST, { workspaceId })}
            title={prebidModuleName}
            titleAdornment={{
              start: (
                <BoxWithLogo logo={{ svg: prebidModuleLogos[prebidModuleName], size: 'big' }} />
              ),
            }}
          />
        )}
      </PrebidModulesContext.Consumer>

      <EditContainer />
    </PrebidModulesContext.Provider>
  )
}
