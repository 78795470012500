import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainPrebidStack } from '../../types/domainPrebidStack'
import { DomainPrebidStackDTO } from '../types/domainPrebidStackDTO'

export const domainPrebidStackFromDTO = (from: DomainPrebidStackDTO) =>
  mapDTO<DomainPrebidStackDTO, DomainPrebidStack>({ from }).transform(domainPrebidStackDTO => {
    const { createdAt, updatedAt } = domainPrebidStackDTO

    return {
      ...domainPrebidStackDTO,
      createdAt: new Date(createdAt),
      updatedAt: new Date(updatedAt),
    }
  })
