import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import ActionsCell from './components/ActionsCell'
import { PageForTable } from './types'

export const useColumns = (): ColumnDef<PageForTable>[] => {
  const { t } = useTranslation('features/page')

  return [
    {
      accessorKey: 'domain.name',
      header: t('list.header.domainName'),
    },
    {
      accessorKey: 'name',
      header: t('list.header.pageName'),
    },
    {
      accessorKey: 'path',
      header: t('list.header.urlPath'),
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('list.header.lastModified'),
    },
    {
      cell: ({ row }) => <ActionsCell {...row.original} />,
      header: t('list.header.actions'),
      id: 'actions',
      meta: {
        centered: true,
        nonClickable: true,
      },
      size: COLUMN_SIZE.ACTIONS,
    },
  ]
}
