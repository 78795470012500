import { FlexBox } from 'components/Box'
import { Link } from 'components/Link'
import { FullLogotype, IconYieldbird } from 'features/layout/components/Logo'
import { useAuthUser } from 'features/user'
import { ROUTES } from 'routes'

export const Logo = (): JSX.Element => {
  const {
    helpers: { isEmployeeView },
  } = useAuthUser()

  return (
    <FlexBox
      axis='x'
      mx={1}
    >
      <Link
        to={ROUTES.BASE.ROOT}
        height={24}
      >
        {isEmployeeView ? <FullLogotype /> : <IconYieldbird />}
      </Link>
    </FlexBox>
  )
}
