import { useMutation } from '@apollo/client'

import { UPDATE_AD_UNITS_PRODUCT_ENABLED } from './api/mutation'
import { Variables } from './api/types'
import { useSnackbar } from './useSnackbar'

export const useSwitchAdUnitsProduct = () => {
  const { snackbar } = useSnackbar()

  const [mutate] = useMutation(UPDATE_AD_UNITS_PRODUCT_ENABLED, {
    refetchQueries: ['getAdUnitsForTable'],
  })

  const update = async (variables: Variables): Promise<void> => {
    const { productType, enabledValue } = variables
    const { data } = await mutate({ variables: { input: variables } })
    const { errors } = { ...data?.updateAdUnitsProductEnabled }

    if (errors && errors.length > 0) {
      snackbar(productType, enabledValue, 'error')
    } else {
      snackbar(productType, enabledValue)
    }
  }

  return { update }
}
