import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SelectOption } from 'components/Select'
import { SelectAutocompleteProps } from './types'

export const SelectAutocomplete = <T extends FieldValues, Data>({
  control,
  name,
  options,
  disabledOptions,
  loading,
  ...inputProps
}: SelectAutocompleteProps<T, Data>): JSX.Element => {
  const { t } = useTranslation('components')

  const values = options.map((options: SelectOption<Data>) => options.value)

  const handleGetOptionLabel = (option: string): string =>
    options.find(({ value }: SelectOption<Data>) => value === option)?.label ??
    t('select.noneOptionLabel')

  const handleGetOptionDisabled = (option: string): boolean => {
    return disabledOptions?.includes(option) ?? false
  }

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={Boolean(error)}>
          <Autocomplete
            {...field}
            onChange={(_, selectedOption: string | string[]): void =>
              field.onChange(selectedOption)
            }
            options={['', ...values]}
            loading={loading}
            renderInput={(props: AutocompleteRenderInputParams) => (
              <TextField
                data-cy={name}
                {...props}
              />
            )}
            getOptionLabel={handleGetOptionLabel}
            getOptionDisabled={handleGetOptionDisabled}
            disableClearable={true}
            disabled={inputProps.disabled}
          />

          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
