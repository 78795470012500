import { Box } from '@mui/material'
import { constantCase } from 'change-case'
import { useContext } from 'react'
import { generatePath, To } from 'react-router-dom'
import { ScreamingSnakeCase } from 'type-fest'

import { Table } from 'components/Table'
import { useSort } from 'components/Table/Sort'
import { canUpdate } from 'features/globalSetup'
import { PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { CONTAINER_BOTTOM_SPACING } from 'providers/material-ui/theme/constants'
import { ROUTES } from 'routes'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { useGetDomainPrebidModules } from './hooks/useGetDomainPrebidModules'
import { DomainPrebidModuleSlice, SortBy } from './hooks/useGetDomainPrebidModules/types'
import { ModulesTableProps } from './types'
import { useColumns } from './useColumns'

export const ModuleTable = ({ moduleType, bottomSpacing }: ModulesTableProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const { domain } = useContext(AddEditDomainContext)

  if (!domain) {
    throw new Error('Domain should be accessible')
  }

  const columns = useColumns(moduleType)
  const { sort, props: sortProps } = useSort<DomainPrebidModuleSlice, SortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )
  const { modules, loading } = useGetDomainPrebidModules({ domainId: domain.id, moduleType, sort })

  if (modules && modules.length === 0) {
    return <></>
  }

  const handleRowClick = (row: DomainPrebidModuleSlice): To | undefined => {
    const {
      prebidModule: { account },
      workspacePrebidModule: { id },
    } = row

    if (!canUpdate({ account, moduleType, ability })) {
      return undefined
    } else {
      return generatePath(
        ROUTES.GLOBAL_SETUP.PREBID.MODULES[
          constantCase(moduleType) as ScreamingSnakeCase<PrebidModuleType>
        ].EDIT,
        {
          workspaceId,
          id,
        },
      )
    }
  }

  return (
    <Box sx={{ mb: bottomSpacing ? CONTAINER_BOTTOM_SPACING : 0 }}>
      <Table
        data-cy={`domain-modules-${moduleType}-table`}
        columns={columns}
        data={modules}
        loading={loading}
        onClickRow={handleRowClick}
        sort={sortProps}
      />
    </Box>
  )
}
