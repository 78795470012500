import { Box, Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DateField } from 'components/Form/DateField'
import { useTrialDuration } from 'features/product/hooks/useTrialDuration'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { t } = useTranslation('features/product')
  const { control, watch } = useFormContext<Schema>()
  const { from, to } = watch()

  const { duration } = useTrialDuration({
    startDate: from,
    endDate: to,
  })

  return (
    <Stack
      direction='row'
      sx={{ mt: 3, gap: 3 }}
    >
      <DateField<Schema>
        data-cy='date-field-from'
        control={control}
        label={t('dialog.status.trial.from')}
        name='from'
      />

      <DateField<Schema>
        data-cy='date-field-to'
        control={control}
        label={t('dialog.status.trial.to')}
        name='to'
      />

      <Box>
        <Typography variant='h6'>{t('dialog.status.trial.duration')}</Typography>
        <Typography>{duration}</Typography>
      </Box>
    </Stack>
  )
}
