import { useEffect } from 'react'
import { generatePath, To } from 'react-router-dom'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { useGetFilteringRules } from 'features/globalSetup/pages/Prebid/Protection/pages/ProtectionListPage/ProtectionTable/hooks/useGetFilteringRules'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { FilteringRuleForTable, FilteringRuleSortBy } from './hooks/useGetFilteringRules/types'
import { FilteringRuleTableProps } from './types'
import { useColumns } from './useColumns'

export const ProtectionTable = ({ filters }: FilteringRuleTableProps): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const columns = useColumns()
  const { page, setPage, rowsPerPage, ...usePaginationProps } = usePagination()
  const { sort, props: sortProps } = useSort<FilteringRuleForTable, FilteringRuleSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  useEffect(() => {
    setPage(1)
  }, [filters]) // eslint-disable-line react-hooks/exhaustive-deps

  const { count, filteringRules, loading } = useGetFilteringRules({
    filters,
    ...calculatePagination({ page, rowsPerPage }),
    sort,
  })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  const handleRowClick = ({ id }: FilteringRuleForTable): To =>
    generatePath(ROUTES.GLOBAL_SETUP.PREBID.PROTECTION.EDIT, {
      workspaceId,
      id,
    })

  return (
    <Table<FilteringRuleForTable>
      columns={columns}
      data={filteringRules}
      loading={loading}
      pagination={paginationProps}
      onClickRow={
        ability.can('access', 'GlobalSetupPage-prebid-protection-edit')
          ? id => handleRowClick(id)
          : undefined
      }
      sort={sortProps}
    />
  )
}
