import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { Schema, WorkspaceBidderSid } from '../../../form/schema'
import { Variables, WorkspaceBidderAttribute } from './types'

export const toDTO = (from: Schema): Variables =>
  mapDTO<Schema, Variables>({ from }).transform(({ workspaceBidderSids }: Schema): Variables => {
    type IterProps = [
      workspaceBidderId: keyof Schema['workspaceBidderSids'],
      bidderSid: WorkspaceBidderSid,
    ]

    const workspaceBidderAttributes = Object.entries(workspaceBidderSids)
      .map(
        ([workspaceBidderId, bidderSid]: IterProps): Partial<WorkspaceBidderAttribute> => ({
          workspaceBidderId,
          bidderSid: bidderSid?.trim() || null,
        }),
      )
      .filter(
        ({ bidderSid }: Partial<WorkspaceBidderAttribute>): boolean => !_.isEmpty(bidderSid),
      ) as WorkspaceBidderAttribute[]

    return { workspaceBidderAttributes }
  })
