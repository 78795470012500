import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { fromDTO } from './api/mapper'
import { GET_PREBID_MODULE_PARAMETERS_SCHEMA } from './api/query'
import { Props, UseGetPrebidModuleParameters } from './types'

export const useGetPrebidModuleParametersSchema = ({ id }: Props): UseGetPrebidModuleParameters => {
  const [parametersSchema, setParametersSchema] = useState<PrebidModuleParameter[]>([])

  useEffect(() => {
    if (id) {
      query({ variables: { id } })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_PREBID_MODULE_PARAMETERS_SCHEMA, {
    onCompleted: ({ node: prebidModule }) => {
      if (prebidModule) {
        setParametersSchema(fromDTO(prebidModule.paramsSchema))
      }
    },
  })

  return { parametersSchema, loading }
}
