import { Delete, Edit, MoreVert } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ListItemButton } from 'components/List'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { useDeleteWorkspace } from '../../hooks/useDeleteWorkspace'
import { ActionsCellProps } from './types'

export const ActionsCell = ({ id, name }: ActionsCellProps): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation('features/workspace')
  const { t: tC } = useTranslation('common')
  const navigate = useNavigate()
  const { anchor, isOpen, open, close } = useMenu()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { deleteWorkspace, loading } = useDeleteWorkspace({ name })

  const handleDelete = (): void => {
    close()
    setIsDialogOpen(true)
  }

  const EditItem = (): JSX.Element => (
    <ListItemButton
      icon={<Edit />}
      text={tC('form.action.edit')}
      onClick={() => navigate(generatePath(ROUTES.WORKSPACE.EDIT, { id }))}
      data-cy='actions-edit'
    />
  )

  const DeleteItem = (): JSX.Element => (
    <ListItemButton
      icon={<Delete />}
      text={tC('form.action.delete')}
      onClick={handleDelete}
      data-cy='actions-delete'
    />
  )

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <EditItem />

        {ability.can('delete', 'Workspace') && [
          <Divider
            key='divider'
            sx={{ my: 1 }}
          />,
          <DeleteItem key='delete' />,
        ]}
      </Menu>

      <DeleteDialog
        elementName={name}
        dialog={{ isOpen: isDialogOpen, setOpen: setIsDialogOpen }}
        onDelete={() => void deleteWorkspace(id)}
        loading={loading}
        i18nResource={t}
      />
    </>
  )
}
