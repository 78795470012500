import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import Filter, { FilterValue } from 'components/Filter'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import Table from 'features/inventory/ad-unit/components/AdUnitsTable'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'

export const AdUnitList = (): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/adUnit')
  const [filters, setFilters] = useState<FilterValue[]>([])

  const AdUnitFilter = (
    <Filter
      id='adUnitFilter'
      onChange={setFilters}
      placeholder={t('list.filter')}
      values={filters}
    />
  )

  const AddNew = (): JSX.Element => (
    <Link to={generatePath(ROUTES.INVENTORY.AD_UNIT.ADD, { workspaceId })}>
      <Button
        data-cy='ad-unit-button-add'
        variant='contained'
      >
        {t('list.add')}
      </Button>
    </Link>
  )

  return (
    <Paper>
      <TableHeader
        title={t('list.title')}
        actions={ability.can('create', 'AdUnit') ? <AddNew /> : undefined}
        filter={AdUnitFilter}
        bottomSpacing
      />
      <Table filters={filters} />
    </Paper>
  )
}
