import i18next from 'i18next'
import { z } from 'zod'

import { userWorkspaceRoles } from 'features/user/types/userWorkspaceRole'

export const schema = z.object({
  email: z.string().email(),
  userWorkspaceAttributes: z.array(
    z.object({
      workspaceId: z.string().min(1, i18next.t('common:form.error.nonEmpty')),
      role: z.enum(userWorkspaceRoles),
    }),
  ),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault: Schema = {
  email: '',
  userWorkspaceAttributes: [{ workspaceId: '', role: 'viewer' }],
}
