import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Select from 'components/Form/Select'
import Switch from 'components/Form/Switch'
import { useGetPrioritiesForSelect } from 'features/globalSetup'
import { Bidder } from 'features/globalSetup/types/bidder'
import {
  DomainBidderPriority,
  WorkspaceBidderPriority,
} from 'features/globalSetup/types/bidderPriority'
import { useAbility } from 'providers/casl'
import { Schema } from '../../../form/schema'

type Props = {
  s2sAvailable: Bidder['s2sAvailable']
  workspaceBidderPriority: WorkspaceBidderPriority
}

export const DomainBidderFields = ({
  s2sAvailable,
  workspaceBidderPriority,
}: Props): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.edit.bidParamsAndConnection.domainSection',
  })
  const ability = useAbility()
  const { control } = useFormContext<Schema>()

  const priorities = useGetPrioritiesForSelect({ workspaceBidderPriority, variant: 'domainBidder' })

  return (
    <>
      <Select<Schema, DomainBidderPriority>
        control={control}
        name='priority'
        label={t('priority.label')}
        options={priorities}
        disabled={!ability.can('update', 'GlobalSetupField-prebid-bidders-bid-params-priority')}
        tooltip={{
          content: t('priority.tooltip'),
        }}
      />

      <Switch
        control={control}
        name='csEnabled'
        label={t('csEnabled')}
        disabled={!ability.can('update', 'GlobalSetupField-prebid-bidders-bid-params-cs')}
      />

      {s2sAvailable && (
        <Switch
          control={control}
          name='s2sEnabled'
          label={t('s2sEnabled')}
          disabled={!ability.can('update', 'GlobalSetupField-prebid-bidders-bid-params-s2s')}
        />
      )}
    </>
  )
}
