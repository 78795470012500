import { RouteObject } from 'react-router-dom'

import WorkspaceAddPage from 'features/workspace/pages/WorkspaceAddPage'
import WorkspaceEditPage from 'features/workspace/pages/WorkspaceEditPage'
import WorkspaceListPage from 'features/workspace/pages/WorkspaceListPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from './routePaths'

export const workspaceRoutes: RouteObject[] = [
  {
    path: ROUTES.WORKSPACE.LIST,
    element: (
      <AuthorizedRoute
        access='WorkspacePage-list'
        page={<WorkspaceListPage />}
      />
    ),
  },
  {
    path: ROUTES.WORKSPACE.ADD,
    element: (
      <AuthorizedRoute
        access='WorkspacePage-add'
        page={<WorkspaceAddPage />}
      />
    ),
  },
  {
    path: ROUTES.WORKSPACE.EDIT,
    element: (
      <AuthorizedRoute
        access='WorkspacePage-edit'
        page={<WorkspaceEditPage />}
      />
    ),
  },
]
