import dayjs from 'dayjs'
import pluralize from 'pluralize'
import { useTranslation } from 'react-i18next'

import { UseTrialDuration, UseTrialDurationProps } from './types'

export const useTrialDuration = ({
  startDate,
  endDate,
}: UseTrialDurationProps): UseTrialDuration => {
  const { t } = useTranslation('features/product')

  const calculateDuration = (): number => {
    const from = startDate?.startOf('day')
    const to = endDate?.endOf('day')

    if (from && to) {
      const diff: number = to.diff(from, 'day')

      return diff >= 0 ? diff : 0
    } else {
      return 0
    }
  }

  const withPlurals = (duration: number): string => pluralize(t('duration', { duration }), duration)

  const trialStatus: UseTrialDuration['trialStatus'] = status => {
    switch (status) {
      case 'expired':
        return dayjs(endDate).isBefore(dayjs(), 'day')
      case 'pending':
        return dayjs(startDate).isAfter(dayjs(), 'day')
    }
  }

  const duration: string = withPlurals(calculateDuration())

  return {
    duration,
    trialStatus,
  }
}
