import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Row } from '@tanstack/react-table'
import _ from 'lodash'
import { ReactNode } from 'react'

import { SelectOption } from 'components/Select'
import { useAuthUser } from 'features/user'
import { UserGroupRoles, userGroupRoles } from 'features/user/types/UserGroupRoles'
import { useAbility } from 'providers/casl'
import { useUpdateEmployee } from '../hooks/useUpdateEmployee'
import { EmployeeForTable } from '../types'

export const UserRoleCell = ({ email, id, role }: Row<EmployeeForTable>['original']): ReactNode => {
  const ability = useAbility()
  const {
    user: { id: currentUserId },
  } = useAuthUser()
  const { update } = useUpdateEmployee({ email })
  const show = role && id

  if (!show) return null

  const canManage: boolean =
    ability.can('update', 'Employee') && ability.can('delete', 'Employee') && currentUserId !== id

  type Role = UserGroupRoles['employeesOnly'][number]

  const options: SelectOption<Role>[] = userGroupRoles.employeesOnly.map(
    (role: Role): SelectOption<Role> => ({
      value: role,
      label: _.startCase(role),
    }),
  )

  const handleChange = (event: SelectChangeEvent<Role>): void =>
    void update({ userId: id, role: event.target.value as Role })

  return (
    <Select
      variant='standard'
      size='small'
      value={role}
      onChange={handleChange}
      disabled={!canManage}
    >
      {options.map(
        (option: SelectOption<Role>): JSX.Element => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ),
      )}
    </Select>
  )
}
