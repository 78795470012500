import { useTranslation } from 'react-i18next'

import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { ModuleTable } from './components/ModuleTable'

export const ModulesSection = (): JSX.Element => {
  const { t } = useTranslation('features/domain')

  return (
    <Paper>
      <TableHeader
        title={t('form.prebidStack.modules.list.title')}
        description={t('form.prebidStack.modules.list.description')}
        bottomSpacing
      />

      <ModuleTable
        moduleType='userId'
        bottomSpacing
      />
      <ModuleTable
        moduleType='vendorSpecific'
        bottomSpacing
      />
      <ModuleTable moduleType='analytics' />
    </Paper>
  )
}
