import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EmployeeBox } from 'components/Box'
import { BoxForm, Switch, TextField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { URL } from './constants'
import { Schema } from './schema'
import { FailsafeTimeout } from './sections/FailsafeTimeout'

export const WorkspaceForm = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const { control } = useFormContext<Schema>()

  return (
    <>
      <Paper>
        <SectionHeader
          title={t('general.workspace.header.title')}
          subtitle={t('general.workspace.header.description')}
          bottomSpacing
        />

        <BoxForm>
          <TextField
            control={control}
            name='name'
            label={t('general.workspace.form.name')}
            disabled={!ability.can('update', 'GlobalSetupField-workspace')}
          />

          {ability.can('read', 'GlobalSetupField-priceGenius') && (
            <EmployeeBox>
              <Switch
                disabled={!ability.can('update', 'GlobalSetupField-priceGenius')}
                control={control}
                name='priceGeniusAlwaysOn'
                label={t('general.workspace.form.priceGeniusAlwaysOn.label')}
                labelPlacement='start'
                tooltip={{
                  content: t('general.workspace.form.priceGeniusAlwaysOn.tooltip'),
                  link: {
                    title: t('common:learnMore'),
                    url: URL.PRICE_GENIUS_ALWAYS_ON,
                  },
                }}
              />
            </EmployeeBox>
          )}
        </BoxForm>
      </Paper>

      <FailsafeTimeout />
    </>
  )
}
