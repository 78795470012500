import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import { MatchType, matchTypeValues } from 'features/inventory/domain/types/matchType'
import { UseMatchTypeRadioGroup } from './types'

export const useMatchTypeRadioGroup = (): UseMatchTypeRadioGroup => {
  const { t } = useTranslation('features/domain')

  return matchTypeValues.map(
    (matchType: MatchType): RadioGroupOption => ({
      label: t(`form.refresher.targeting.matchType.values.${matchType}`),
      value: matchType,
      tooltip: { content: t(`form.refresher.targeting.matchType.tooltips.${matchType}`) },
    }),
  )
}
