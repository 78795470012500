import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { GeneralFormEdit } from 'features/inventory/page/forms/GeneralForm'
import PrebidStackForm from 'features/inventory/page/forms/PrebidStackForm'
import { useAbility } from 'providers/casl'
import { ProductTab } from 'types/productTab'

export const useProductTabs = (): Tab<ProductTab>[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/page')
  const { t: tProduct } = useTranslation('features/product')

  const canUpdate = ability.can('update', 'Page')

  return [
    {
      label: t('edit.tabs.general'),
      page: <GeneralFormEdit />,
      element: 'general',
      disabled: !canUpdate,
    },
    {
      label: tProduct('product.prebidStack'),
      page: <PrebidStackForm />,
      element: 'prebid-stack',
      authorized: 'ProductForm-prebidStack',
      disabled: !canUpdate || !ability.can('access', 'ProductForm-prebidStack'),
    },
  ]
}
