import { Theme, ThemeOptions } from '@mui/material'

export const createTypography = (theme: Theme): ThemeOptions['typography'] => ({
  fontFamily: `"Noto Sans", sans-serif`,

  subtitle1: {
    color: theme.palette.grey[600],
    fontSize: '0.8rem',
  },

  button: {
    textTransform: 'none',
  },
})
