import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { WorkspaceProductSlice } from '../../../../forms/types'
import { GET_WORKSPACE_FOR_FORM, workspaceProductSliceFromDTO } from './api'
import { UseGetWorkspaceProduct, UseGetWorkspaceProductProps } from './types'

export const useGetWorkspaceProduct = ({
  id,
  product,
}: UseGetWorkspaceProductProps): UseGetWorkspaceProduct => {
  const [workspaceProduct, setWorkspaceProduct] = useState<WorkspaceProductSlice>()

  useEffect(() => {
    if (id) {
      query({ variables: { id } })
    }
  }, [id, product]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_WORKSPACE_FOR_FORM, {
    onCompleted: ({ node: workspace }) => {
      if (workspace && product) {
        setWorkspaceProduct(workspaceProductSliceFromDTO(workspace, product))
      }
    },
  })

  return { workspaceProduct, loading }
}
