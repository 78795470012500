import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SelectOption } from 'components/Select'
import { SupplyChainSlice } from '../../../../types'
import { SupplyChainSliceDTO } from './types'

export const fromDTO = (from: SupplyChainSliceDTO): SelectOption<SupplyChainSlice> =>
  mapDTO<SupplyChainSliceDTO, SelectOption<SupplyChainSlice>>({ from }).transform(
    (supplyChain: SupplyChainSliceDTO): SelectOption<SupplyChainSlice> => ({
      value: supplyChain.id,
      label: supplyChain.name,
    }),
  )
