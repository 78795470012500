import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { PageForTable } from '../../types'
import { pageForTableFromDTO, sortToDTO } from './api/mapper'
import { GET_PAGES_FOR_TABLE } from './api/query'
import { PageForTableDTO, UseGetPages, UseGetPagesProps } from './types'

export const useGetPages = ({ filters, first, last, sort }: UseGetPagesProps): UseGetPages => {
  const { workspaceId } = useWorkspaceParam()
  const [pages, setPages] = useState<PageForTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_PAGES_FOR_TABLE, {
    variables: {
      first,
      last,
      terms: filters,
      workspaceId,
      sort: sortToDTO(sort),
    },
    fetchPolicy: 'cache-and-network',

    onCompleted: ({ pages: { nodes: pages, totalCount: count } }): void => {
      if (pages) {
        setPages(pages.map((node: PageForTableDTO): PageForTable => pageForTableFromDTO(node)))
        setCount(count)
      }
    },
  })

  return { count, loading, pages }
}
