import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Tabs, { getPage, Tab } from 'components/Tabs'
import {
  GeneralPageContext,
  GeneralPageContextType,
  WorkspaceSlice,
} from 'features/globalSetup/contexts/GeneralPageContext'
import { useAuthUser } from 'features/user'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { useTabs } from './hooks/useTabs'
import { GeneralPageTab, GeneralPageUrlParams } from './types'

export const GeneralPage = (): JSX.Element => {
  const ability = useAbility()
  const { tab: urlTab } = useParams<GeneralPageUrlParams>()
  const {
    user: { currentWorkspace },
  } = useAuthUser()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const tabs: Tab<GeneralPageTab>[] = useTabs()

  if (!currentWorkspace) {
    throw Error('User workspace should exists')
  }
  const [workspace, setWorkspace] = useState<WorkspaceSlice>(currentWorkspace)

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'global-setup-general-form',
  })
  const contextProps: GeneralPageContextType = {
    workspace,
    setWorkspace,
    ...commonContextProps,
  }

  const showSaveButton =
    urlTab === 'workspace' && ability.can('update', 'GlobalSetupField-workspace')

  return (
    <GeneralPageContext.Provider value={contextProps}>
      <StickyBox>
        <GeneralPageContext.Consumer>
          {(ctxProps: GeneralPageContextType) => (
            <PageHeader
              title={t('general.header')}
              actions={showSaveButton ? <SaveButton {...ctxProps} /> : undefined}
            />
          )}
        </GeneralPageContext.Consumer>

        <Tabs
          path={ROUTES.GLOBAL_SETUP.GENERAL.ROOT}
          tabs={tabs}
        />
      </StickyBox>

      {getPage<GeneralPageTab>(tabs, urlTab, 'workspace')}
    </GeneralPageContext.Provider>
  )
}
