import { useContext } from 'react'
import { generatePath, To } from 'react-router-dom'

import { Table, useFilteredData } from 'components/Table'
import { AdUnitBidderSlice } from 'features/inventory/ad-unit/forms/PrebidStackForm'
import { PrebidStackFormContext } from 'features/inventory/ad-unit/forms/PrebidStackForm/context/PrebidStackFormContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { BiddersTableProps } from './types'
import { useColumns } from './useColumns'

export const BiddersTable = ({ filters }: BiddersTableProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { adUnitBidders } = useContext(PrebidStackFormContext)
  const columns = useColumns()
  const data = useFilteredData<AdUnitBidderSlice>({
    filters,
    filterBy: ['code', 'name'],
    data: adUnitBidders,
  })

  if (!adUnitBidders) {
    throw new Error('Ad unit bidders are required')
  }

  const handleRowClick = ({ workspaceBidderId }: AdUnitBidderSlice): To =>
    generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT, {
      workspaceId,
      id: workspaceBidderId,
      tab: 'general',
    })

  return (
    <Table<AdUnitBidderSlice>
      data-cy='ad-unit-bidders-table'
      columns={columns}
      data={data}
      onClickRow={handleRowClick}
    />
  )
}
