import { LanOutlined, PeopleAltOutlined, SchoolOutlined } from '@mui/icons-material'
import { TFunction } from 'i18next'
import { generatePath } from 'react-router-dom'

import { ROUTES } from 'routes'
import { SidebarItem } from '../types'
import { URL } from './constants'

export const configAdmin = (t: TFunction<'features/layout'>): SidebarItem[] => [
  {
    title: t('sidebar.employee.workspaces'),
    to: ROUTES.WORKSPACE.LIST,
    icon: <LanOutlined />,
  },
  {
    title: t('sidebar.employee.userManagement'),
    to: generatePath(ROUTES.USER.LIST, { tab: 'publishers' }),
    icon: <PeopleAltOutlined />,
    gapAfter: true,
  },
  {
    title: t('sidebar.external.knowledgeBase'),
    icon: <SchoolOutlined />,
    to: URL.KNOWLEDGE_BASE,
  },
]
