import { SelectOption } from 'components/Select'
import { Workspace } from 'features/workspace'
import { WorkspaceSlice } from './types'

export const toSelectOptions = (workspaces: Workspace[]): SelectOption<WorkspaceSlice>[] =>
  workspaces.map(
    (workspace: Workspace): SelectOption<WorkspaceSlice> => ({
      label: workspace.name,
      value: workspace.id,
      rawData: {
        ...workspace,
      },
    }),
  )

export const initialOptions = (
  workspacesOptions: SelectOption<WorkspaceSlice>[],
  currentWorkspaceId?: Workspace['id'],
): SelectOption<WorkspaceSlice> => {
  if (currentWorkspaceId) {
    const userWorkspaceOption: SelectOption<WorkspaceSlice> | undefined = workspacesOptions.find(
      (workspace: SelectOption<WorkspaceSlice>): boolean => workspace.value === currentWorkspaceId,
    )

    if (userWorkspaceOption === undefined) {
      throw Error('Mismatched Workspace IDs')
    }
    return userWorkspaceOption
  } else {
    return workspacesOptions[0]
  }
}

export const toOptionProps = (workspaceOption: SelectOption<WorkspaceSlice>): WorkspaceSlice => {
  const { value, label, rawData: workspace } = workspaceOption

  if (workspace === undefined) {
    throw Error('Workspace should be fetched and accessible')
  }

  const { alerts, networkCode } = workspace

  return {
    alerts: alerts,
    id: value,
    name: label,
    networkCode,
  }
}
