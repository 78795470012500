import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { BidParamsAndConnection } from '../../pages/BidParamsAndConnection'
import { GeneralPage } from '../../pages/GeneralPage'
import { BidderEditTabs } from './types'

export const useTabs = (): Tab<BidderEditTabs>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.edit.tabs',
  })

  return [
    {
      label: t('general'),
      page: <GeneralPage />,
      element: 'general',
    },
    {
      label: t('bidParamsAndConnection'),
      page: <BidParamsAndConnection />,
      element: 'bid-params-and-connection',
    },
  ]
}
