import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MutationInputError } from 'providers/graphql'
import { UPDATE_WORKSPACE_USER_FOR_TABLE } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateWorkspaceUser, UseUpdateWorkspaceUserProps, Variables } from './types'

export const useUpdateWorkspaceUser = ({
  email,
}: UseUpdateWorkspaceUserProps): UseUpdateWorkspaceUser => {
  const { t } = useTranslation('features/user')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<MutationInputError[]>([])

  const [mutate] = useMutation(UPDATE_WORKSPACE_USER_FOR_TABLE, {
    refetchQueries: ['getWorkspaceUsersForTable'],
    onCompleted: ({ updatePublisherByWorkspaceOwner: { errors } }: Data): void => {
      if (errors) {
        setErrors(errors)
        enqueueSnackbar(t('list.update.error'), { variant: 'error' })
      } else {
        enqueueSnackbar(t('list.update.success', { email }), {
          variant: 'success',
        })
      }
    },
  })

  const update = (variables: Variables): Promise<FetchResult<Data>> =>
    mutate({ variables: { input: variables } })

  return { errors, update }
}
