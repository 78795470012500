import { userRoles } from './UserRole'

const { admin, adops, backOffice, customerPerformance, customerSuccess, publisher } = userRoles

export type UserGroupRoles = typeof userGroupRoles
export type UserGroup = keyof typeof userGroupRoles

export const userGroupRoles = {
  everyone: [admin, adops, backOffice, customerPerformance, customerSuccess, publisher],
  employeesOnly: [admin, adops, backOffice, customerPerformance, customerSuccess],
} as const
