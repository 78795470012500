import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { WorkspaceDomainDTO } from 'features/workspace/api/types/workspaceDTO'
import { WorkspaceDomainSubRow, WorkspaceForTable, WorkspaceSortBy } from '../types'
import { WorkspaceForTableDTO, WorkspaceSortByDTO } from './types'

export const mapFromDTO = (from: WorkspaceForTableDTO): WorkspaceForTable =>
  mapDTO<WorkspaceForTableDTO, WorkspaceForTable>({ from }).transform(
    (dto: WorkspaceForTableDTO): WorkspaceForTable => {
      const { domains, updatedAt } = dto

      return {
        ...dto,
        domains: domains.length,
        updatedAt: new Date(updatedAt),
        subRows: domains.map(
          ({ id, alerts, name }: WorkspaceDomainDTO): WorkspaceDomainSubRow => ({
            id,
            domains: name,
            alerts,
          }),
        ),
      }
    },
  )

export const sortToDTO = (sort: SortInput<WorkspaceSortBy>): SortInput<WorkspaceSortByDTO> => {
  const { by } = sort

  if (by === 'name') {
    return { ...sort, by: 'workspaceName' }
  } else {
    return { ...sort, by }
  }
}
