import { z } from 'zod'

import { commonSchema } from 'features/globalSetup/forms/Prebid/Bidders/schemas/commonSchema'
import { WorkspaceBidderSlice } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/hooks/useGetWorkspaceBidder'

export type EditSchema = z.infer<typeof commonSchema>

export const editSchemaDefault = (workspaceBidder: WorkspaceBidderSlice): EditSchema => {
  const {
    accountType,
    bidderCode,
    priority,
    consentMode,
    bidder: { id },
    bidderSid,
    bidCpmAdjustment,
    supplyChain,
    status,
  } = workspaceBidder

  return {
    accountType,
    bidderCode,
    priority,
    consentMode,
    bidderId: id,
    bidderSid: bidderSid || '',
    bidCpmAdjustment: bidCpmAdjustment * 100,
    supplyChain: supplyChain?.id ?? '',
    status,
  }
}
