import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Chip,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Theme,
} from '@mui/material'
import _ from 'lodash'
import { Controller, FieldErrors, FieldPath, FieldValues } from 'react-hook-form'

import Tooltip from 'components/Tooltip'
import { TagsFieldProps } from './types'

export const TagsField = <T extends FieldValues>({
  control,
  name,
  options,
  tooltip: tooltipProps,
  ...standardTextFieldProps
}: TagsFieldProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name as FieldPath<T>}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <FormControl error={Boolean(error)}>
        <Stack direction='row'>
          <Autocomplete
            disableClearable
            freeSolo
            id={name}
            multiple
            onChange={(_, value: string[]): void => onChange(value)}
            options={options || []}
            value={value}
            renderInput={props => (
              <TextField
                {...props}
                {...standardTextFieldProps}
                InputProps={{
                  ...props.InputProps,
                }}
                error={Boolean(error)}
              />
            )}
            renderTags={(value: string[], getTagProps: AutocompleteRenderGetTagProps) => {
              return value.map((option: string, index: number): JSX.Element => {
                let isValid: boolean = false
                if (error === undefined) {
                  isValid = true
                } else if (_.isPlainObject(error)) {
                  isValid = _.isEmpty((error as FieldErrors<T>)[option])
                }

                return (
                  <Chip
                    {...getTagProps({ index })}
                    key={option}
                    label={option}
                    sx={({ palette: { grey, error, black, white } }: Theme) =>
                      isValid
                        ? {
                            color: black.main,
                            backgroundColor: grey[200],
                          }
                        : {
                            color: white.main,
                            backgroundColor: error.main,
                          }
                    }
                  />
                )
              })
            }}
          />

          {tooltipProps && <Tooltip {...tooltipProps} />}
        </Stack>

        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    )}
  />
)
