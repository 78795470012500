import { Close as CloseIcon, Delete as DeleteIcon, Save as SaveIcon } from '@mui/icons-material'
import { Box, Grid, IconButton } from '@mui/material'
import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { ChipsMultiValue, NumberField, Switch, TextField } from 'components/Form'
import { LIST_COLUMN_BREAKPOINTS } from 'features/inventory/viewport/constants'
import { ViewportListContext } from 'features/inventory/viewport/contexts/ViewportListContext'
import { useAbility } from 'providers/casl'
import { useRemoveViewport } from './hooks/useRemoveViewport'
import { CommonSchema } from './schemas/commonSchema'

export const CommonForm = () => {
  const ability = useAbility()

  const {
    control,
    formState: { errors, isDirty, isSubmitted },
    reset,
    watch,
  } = useFormContext<CommonSchema>()
  const data = watch()
  const { id } = data

  const { update, viewports } = useContext(ViewportListContext)
  const { remove } = useRemoveViewport()

  useEffect(() => {
    if (isSubmitted && _.isEmpty(errors)) {
      reset(data)
    }
  }, [isSubmitted]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRemove = (): void => {
    if (viewports.find(viewport => viewport.id === id)) {
      remove(id)
    } else {
      update(id)
    }
  }

  const canUpdate = ability.can('update', 'Viewport')

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.name}
      >
        <TextField
          control={control}
          name='name'
          sx={{ minWidth: '100%' }}
          disabled={!canUpdate}
        />
      </Grid>

      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.minimumWidth}
      >
        <NumberField
          control={control}
          name='minimumWidth'
          sx={{ minWidth: '100%' }}
          disabled={!canUpdate}
        />
      </Grid>

      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.bannerSizes}
      >
        <ChipsMultiValue
          control={control}
          name='bannerSizes'
          sx={{ minWidth: '100%' }}
          disabled={!canUpdate}
        />
      </Grid>

      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.nativeEnabled}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Switch
          control={control}
          name='nativeEnabled'
          disabled={!canUpdate}
        />
      </Grid>

      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.playerSize}
      >
        <ChipsMultiValue
          control={control}
          name='playerSize'
          sx={{ minWidth: '100%' }}
          disabled={!canUpdate}
        />
      </Grid>

      <Grid
        item
        lg={LIST_COLUMN_BREAKPOINTS.actionIcons}
      >
        <Box sx={{ pt: 1 }}>
          {isDirty
            ? canUpdate && (
                <>
                  <IconButton type='submit'>
                    <SaveIcon />
                  </IconButton>

                  <IconButton onClick={(): void => reset()}>
                    <CloseIcon />
                  </IconButton>
                </>
              )
            : ability.can('delete', 'Viewport') && (
                <IconButton onClick={handleRemove}>
                  <DeleteIcon />
                </IconButton>
              )}
        </Box>
      </Grid>
    </Grid>
  )
}
