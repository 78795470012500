import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { ViewportListItem } from 'features/inventory/viewport/types/viewportListItem'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { viewportListItemFromDTO } from './api/mapper'
import { GET_VIEWPORTS } from './api/query'
import { UseGetViewports } from './types'

export const useGetViewports = (): UseGetViewports => {
  const [viewports, setViewports] = useState<ViewportListItem[]>([])
  const { workspaceId } = useWorkspaceParam()

  const { loading } = useQuery(GET_VIEWPORTS, {
    variables: {
      workspaceId,
    },
    onCompleted: ({ viewports: { nodes: viewports } }): void => {
      if (viewports) {
        setViewports(viewports.map(viewport => viewportListItemFromDTO(viewport)))
      }
    },
  })

  return {
    loading,
    viewports,
  }
}
