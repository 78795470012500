import { z } from 'zod'

import { adUnitsAttributesSchema } from 'features/inventory/domain/components/ProductAdUnitsTable'
import { Domain } from 'features/inventory/types/domain'

export const schema = z
  .object({
    domainId: z.string(),
    priceGeniusEnabled: z.boolean(),
    trafficPercent: z.number().min(0).max(100),
  })
  .merge(adUnitsAttributesSchema)

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({
  id,
  domainPriceGenius: { priceGeniusEnabled, trafficPercent },
}: Domain): Schema => ({
  adUnitsAttributes: {},
  domainId: id,
  priceGeniusEnabled,
  trafficPercent,
})
