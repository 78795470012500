import { RouteObject } from 'react-router-dom'

import { UserAddEmployeePage } from 'features/user/pages/UserAddEmployeePage'
import { UserAddPublisherPage } from 'features/user/pages/UserAddPublisherPage'
import { UserEditEmployeePage } from 'features/user/pages/UserEditEmployeePage'
import { UserEditPublisherPage } from 'features/user/pages/UserEditPublisherPage'
import UserListPage from 'features/user/pages/UserListPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from './routePaths'

export const userRoutes: RouteObject[] = [
  {
    path: ROUTES.USER.LIST,
    element: (
      <AuthorizedRoute
        access='UserPage-list'
        page={<UserListPage />}
      />
    ),
  },
  {
    path: ROUTES.USER.EMPLOYEE.ADD,
    element: (
      <AuthorizedRoute
        access='UserPage-addEmployee'
        page={<UserAddEmployeePage />}
      />
    ),
  },
  {
    path: ROUTES.USER.PUBLISHER.ADD,
    element: (
      <AuthorizedRoute
        access='UserPage-addPublisher'
        page={<UserAddPublisherPage />}
      />
    ),
  },
  {
    path: ROUTES.USER.EMPLOYEE.EDIT,
    element: (
      <AuthorizedRoute
        access='UserPage-editEmployee'
        page={<UserEditEmployeePage />}
      />
    ),
  },
  {
    path: ROUTES.USER.PUBLISHER.EDIT,
    element: (
      <AuthorizedRoute
        access='UserPage-editPublisher'
        page={<UserEditPublisherPage />}
      />
    ),
  },
]
