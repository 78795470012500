import { TableCell } from '@mui/material'

import { HeaderProps } from './types'
import { useHeader } from './useHeader'

export const Header = <T,>({ header, ...props }: HeaderProps<T>): JSX.Element => {
  const { colSpan, Content, id, onSort, styles } = useHeader({ header, ...props })

  return (
    <TableCell
      key={id}
      colSpan={colSpan}
      sx={styles}
      onClick={onSort}
      data-cy={`table-header-${id}`}
    >
      {Content}
    </TableCell>
  )
}
