import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { composeAdUnitSize } from 'features/inventory/viewport/utils'
import { mapGraphQLErrors } from 'providers/graphql'
import { EditSchema } from '../../schemas/editSchema'
import { UPDATE_VIEWPORT } from './api/mutation'
import { Data, Variables } from './api/types'
import { UseUpdateViewport } from './types'

export const useUpdateViewport = (): UseUpdateViewport => {
  const { t } = useTranslation('features/viewport')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutateUpdateViewport] = useMutation(UPDATE_VIEWPORT, {
    refetchQueries: ['getViewports'],
    onCompleted: ({ updateViewport: { errors, viewport } }: Data): void => {
      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else if (viewport) {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
  })

  const mapper = (data: EditSchema): Variables => {
    const { bannerSizes, playerSize, ...viewport } = data

    return {
      ...viewport,
      bannerSizes: bannerSizes.map(size => composeAdUnitSize(size)),
      playerSize: playerSize.map(size => composeAdUnitSize(size)),
    }
  }

  const update = (data: EditSchema) =>
    mutateUpdateViewport({
      variables: {
        input: mapper(data),
      },
    })

  return { update, errors }
}
