import { useContext } from 'react'
import { FieldErrors } from 'react-hook-form'

import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { AddSchema } from '../../schemas/addSchema'
import { useCreateOwnWorkspaceBidder } from '../useCreateOwnWorkspaceBidder'
import { useCreateThirdPartyProviderWorkspaceBidder } from '../useCreateThirdPartyProviderWorkspaceBidder'
import { useCreateYieldbirdWorkspaceBidder } from '../useCreateYieldbirdWorkspaceBidder'
import { UseCreateWorkspaceBidder } from './types'

export const useCreateWorkspaceBidder = (): UseCreateWorkspaceBidder => {
  const { accountType } = useContext(WorkspaceBidderContext)

  const { ownErrors, createOwn } = useCreateOwnWorkspaceBidder()
  const { yieldbirdErrors, createYieldbird } = useCreateYieldbirdWorkspaceBidder()
  const { thirdPartyProviderErrors, createThirdPartyProvider } =
    useCreateThirdPartyProviderWorkspaceBidder()

  const getApiErrors = (): FieldErrors<AddSchema> => {
    switch (accountType) {
      case 'own':
        return ownErrors
      case 'yieldbird':
        return yieldbirdErrors
      case 'thirdPartyProvider':
        return thirdPartyProviderErrors
    }
  }

  const handleSubmit = (data: AddSchema): void => {
    switch (data.accountType) {
      case 'own':
        createOwn(data)
        break
      case 'yieldbird':
        createYieldbird(data)
        break
      case 'thirdPartyProvider':
        createThirdPartyProvider(data)
        break
    }
  }

  return { handleSubmit, getApiErrors }
}
