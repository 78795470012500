import { SvgIcon } from '@mui/material'
import { kebabCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { ListItemButton } from 'components/List'
import { Product } from 'features/product'
import { productsLogos } from 'features/product/utils/productsLogos'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ProductsItemsProps } from './types'

export const ProductsItems = ({ editPath, id, products }: ProductsItemsProps): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/product')
  const navigate = useNavigate()

  const LogoIcon = ({ product }: { product: Product }): JSX.Element => (
    <SvgIcon
      component={productsLogos[product]}
      sx={{ filter: 'grayscale(1)' }}
      inheritViewBox
    />
  )

  const navigateToEdit = (product: Product): void =>
    navigate(
      generatePath(editPath, {
        workspaceId,
        id,
        tab: kebabCase(product),
      }),
    )

  return (
    <>
      {products.map((product: Product): JSX.Element | undefined =>
        ability.can('access', `ProductForm-${product}`) ? (
          <ListItemButton
            key={product}
            icon={<LogoIcon product={product} />}
            text={t(`product.${product}`)}
            onClick={() => navigateToEdit(product)}
            data-cy={`actions-${product}`}
          />
        ) : undefined,
      )}
    </>
  )
}
