import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Row } from '@tanstack/react-table'
import { ReactNode } from 'react'

import { SelectOption } from 'components/Select'
import { useAuthUser, userWorkspaceRoleOptions } from 'features/user'
import { UserWorkspaceRole } from 'features/user/types/userWorkspaceRole'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { useUpdateWorkspaceUser } from '../hooks/useUpdateWorkspaceUser'
import { WorkspaceUserForTable } from '../types'

export const UserRoleCell = ({
  email,
  id,
  role,
}: Row<WorkspaceUserForTable>['original']): ReactNode => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const {
    user: { id: currentUserId },
  } = useAuthUser()
  const { update } = useUpdateWorkspaceUser({ email })

  if (!workspaceId) {
    throw Error('Workspace id should exists')
  }

  const disabled = id === currentUserId || !ability.can('update', 'WorkspaceUser')

  const handleChange = (event: SelectChangeEvent<UserWorkspaceRole>): void =>
    void update({
      workspaceId,
      userId: id,
      role: event.target.value as UserWorkspaceRole,
    })

  return (
    <Select
      disabled={disabled}
      variant='standard'
      size='small'
      value={role}
      onChange={handleChange}
    >
      {userWorkspaceRoleOptions().map(
        (option: SelectOption<UserWorkspaceRole>): JSX.Element => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ),
      )}
    </Select>
  )
}
