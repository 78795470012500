import { useContext } from 'react'

import { Form } from 'components/Form'
import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { CommonForm } from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/CommonForm'
import { useUpdateSupplyChain } from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/hooks/useUpdateSupplyChain'
import { commonSchema } from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/schemas/commonSchema'
import {
  EditSchema,
  editSchemaDefault,
} from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/schemas/editSchema'

export const GeneralPage = () => {
  const { formId, setTouched, setValid, supplyChain } = useContext(SupplyChainContext)

  if (!supplyChain) {
    throw Error('Supply chain should be accessible')
  }

  const { id } = supplyChain
  const { update, errors: apiErrors } = useUpdateSupplyChain({ id })

  return (
    <Form<EditSchema>
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={commonSchema}
      schemaDefaults={editSchemaDefault(supplyChain)}
      externalErrors={apiErrors}
    >
      {() => <CommonForm />}
    </Form>
  )
}
