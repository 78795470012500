import { Button, SvgIcon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { Link } from 'components/Link'
import YieldbirdIcon from 'images/logos/yieldbird.svg?react'
import { ROUTES } from 'routes'
import { ErrorPageProps } from './types'

export const ErrorPage = ({ cause }: ErrorPageProps): JSX.Element => {
  const { t } = useTranslation('providers/router')

  return (
    <FlexBox
      axis='xy'
      sx={{ height: '100vh' }}
    >
      <Typography
        sx={{ mb: 2 }}
        variant='h4'
      >
        {t('errors.route.oops')}
      </Typography>

      <SvgIcon
        component={YieldbirdIcon}
        inheritViewBox
        sx={{
          fontSize: '64px',
          mb: 2,
        }}
      />

      <Typography sx={{ mb: 2 }}>{cause}</Typography>

      <Link to={ROUTES.BASE.ROOT}>
        <Button variant='contained'>{t('errors.route.back')}</Button>
      </Link>
    </FlexBox>
  )
}
