import { useTranslation } from 'react-i18next'
import { generatePath, Navigate, useParams } from 'react-router-dom'

import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import {
  WorkspaceUserContext,
  WorkspaceUserContextType,
} from 'features/globalSetup/contexts/WorkspaceUserContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { FormContainer } from './FormContainer'
import { useGetWorkspaceUser } from './hooks/useGetWorkspaceUser'
import { WorkspaceUserEditPageParams } from './types'

export const WorkspaceUserEditPage = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { id } = useParams<WorkspaceUserEditPageParams>()
  const { t } = useTranslation('features/globalSetup')

  const { user } = useGetWorkspaceUser({ id })

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'workspace-user-form-edit',
  })

  if (!id) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!user) {
    return <Loading />
  }

  const contextProps: WorkspaceUserContextType = {
    user,
    ...commonContextProps,
  }

  return (
    <WorkspaceUserContext.Provider value={contextProps}>
      <WorkspaceUserContext.Consumer>
        {(ctxProps: WorkspaceUserContextType): JSX.Element => (
          <PageHeader
            actions={<SaveButton {...ctxProps} />}
            backTo={generatePath(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, {
              workspaceId,
              tab: 'user-management',
            })}
            title={t('general.userManagement.edit.header', { email: user.email })}
          />
        )}
      </WorkspaceUserContext.Consumer>

      <FormContainer />
    </WorkspaceUserContext.Provider>
  )
}
