import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { BiddersForTable } from '../../../types'
import { BiddersForTableDTO } from './types'

export const fromDTO = (from: BiddersForTableDTO) =>
  mapDTO<BiddersForTableDTO, BiddersForTable>({ from }).transform(dto => {
    const {
      accountType,
      bidCpmAdjustment,
      bidder: { name },
      bidderCode,
      supplyChain,
      status,
    } = dto

    return {
      ...dto,
      accountType: accountType === 'thirdPartyProvider' ? supplyChain.name : accountType,
      bidCpmAdjustment: _.round(bidCpmAdjustment * 100, 2),
      code: bidderCode,
      name,
      status,
    }
  })
