import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { WorkspaceForTable } from '../types'
import { mapFromDTO, sortToDTO } from './mapper'
import { GET_WORKSPACES_FOR_TABLE } from './query'
import { UseGetWorkspaces, UseGetWorkspacesProps, WorkspaceForTableDTO } from './types'

export const useGetWorkspaces = ({
  filters,
  first,
  last,
  sort,
}: UseGetWorkspacesProps): UseGetWorkspaces => {
  const [workspaces, setWorkspaces] = useState<WorkspaceForTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_WORKSPACES_FOR_TABLE, {
    variables: {
      first,
      last,
      terms: filters,
      sort: sortToDTO(sort),
    },
    fetchPolicy: 'cache-and-network',

    onCompleted: ({ workspaces: { nodes: workspaces, totalCount: count } }): void => {
      if (workspaces) {
        setWorkspaces(
          workspaces.map(
            (workspace: WorkspaceForTableDTO): WorkspaceForTable => mapFromDTO(workspace),
          ),
        )
        setCount(count)
      }
    },
  })

  return {
    count,
    loading,
    workspaces,
  }
}
