import { generatePath, To } from 'react-router-dom'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { useGetDomains } from './hooks/useGetDomains'
import { DomainsForTable, DomainSortBy, DomainsTableProps } from './types'
import { useColumns } from './useColumns'

export const DomainsTable = ({ filters }: DomainsTableProps): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { page, setPage, rowsPerPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })
  const { sort, props: sortProps } = useSort<DomainsForTable, DomainSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const { count, domains, loading } = useGetDomains({
    filters,
    ...calculatePagination({ page, rowsPerPage }),
    sort,
  })

  const columns = useColumns(filters, count)

  const handleRowClick = (row: DomainsForTable): To =>
    generatePath(ROUTES.INVENTORY.DOMAIN.EDIT, {
      workspaceId,
      id: row.id,
      tab: 'general',
    })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<DomainsForTable>
      columns={columns}
      data={domains}
      loading={loading}
      onClickRow={ability.can('update', 'Domain') ? id => handleRowClick(id) : undefined}
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}
