import { Editor as CodeEditor } from '@monaco-editor/react'
import { Box, Divider, Theme, Typography } from '@mui/material'
import { CSSProperties } from 'react'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import Loading from 'components/Loading'
import {
  CONTAINER_BOTTOM_SPACING,
  GLOBAL_BORDER_RADIUS,
} from 'providers/material-ui/theme/constants'
import { CodeFieldProps } from './types'

export const CodeField = <T extends FieldValues>({
  control,
  name,
  title,
  bottomSpacing,
}: CodeFieldProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name as FieldPath<T>}
    render={({ field: { onChange, value } }): JSX.Element => (
      <Box
        id={name}
        sx={{
          border: (theme: Theme): CSSProperties['border'] => `1px solid ${theme.palette.grey[400]}`,
          borderRadius: `${GLOBAL_BORDER_RADIUS}px`,
          mb: bottomSpacing ? CONTAINER_BOTTOM_SPACING : 0,
          pb: 1,
        }}
      >
        <Typography sx={{ m: 1 }}>{title}</Typography>

        <Divider />

        <CodeEditor
          height={300}
          language='javascript'
          loading={<Loading />}
          onChange={onChange}
          options={{
            minimap: { enabled: false },
            renderLineHighlightOnlyWhenFocus: true,
            scrollbar: { alwaysConsumeMouseWheel: false },
            scrollBeyondLastLine: false,
          }}
          value={value}
        />
      </Box>
    )}
  />
)
