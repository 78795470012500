import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainPriceGenius } from '../../types/DomainPriceGenius'
import { DomainPriceGeniusDTO } from '../types/domainPriceGeniusDTO'

export const domainPriceGeniusFromDTO = (from: DomainPriceGeniusDTO) =>
  mapDTO<DomainPriceGeniusDTO, DomainPriceGenius>({ from }).transform(domainPriceGeniusDTO => {
    const { trafficPercent, createdAt, updatedAt } = domainPriceGeniusDTO

    return {
      ...domainPriceGeniusDTO,
      createdAt: new Date(createdAt),
      trafficPercent: trafficPercent * 100,
      updatedAt: new Date(updatedAt),
    }
  })
