import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { commonSchema } from 'features/globalSetup/forms/Prebid/Bidders/schemas/commonSchema'
import { EditBidderFormBody } from './EditBidderFormBody'
import { useUpdateWorkspaceBidder } from './hooks/useUpdateWorkspaceBidder'
import { EditSchema, editSchemaDefault } from './schemas/editSchema'

export const EditBidderForm = (): JSX.Element => {
  const { formId, loading, setTouched, setValid, workspaceBidder } =
    useContext(WorkspaceBidderContext)

  if (!workspaceBidder) {
    throw Error('Workspace Bidder should be accessible')
  }
  const { id, bidderCode } = workspaceBidder

  const { update, errors: apiErrors } = useUpdateWorkspaceBidder({
    id,
    bidderCode,
  })

  return (
    <Form<EditSchema>
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={commonSchema}
      schemaDefaults={editSchemaDefault(workspaceBidder)}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <EditBidderFormBody />)}
    </Form>
  )
}
