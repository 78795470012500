import { AdUnitSize } from './types/viewport'

export const composeAdUnitSize = (size: string): AdUnitSize => {
  const [x, y] = size.split('x')

  return {
    width: parseInt(x),
    height: parseInt(y),
  }
}

export const stringifyAdUnitSize = (size: AdUnitSize): string => `${size.width}x${size.height}`
