import { mapDTO } from '@twistezo/ts-dto-mapper'

import { csManagerFromDTO } from 'features/workspace/mappers/csManager'
import { WorkspaceSlice } from '../types'
import { WorkspaceSliceDTO } from './types'

export const workspaceFromDTO = (from: WorkspaceSliceDTO): WorkspaceSlice =>
  mapDTO<WorkspaceSliceDTO, WorkspaceSlice>({ from }).transform(workspace => {
    const { csManager } = workspace

    return {
      ...workspace,
      csManager: csManager ? csManagerFromDTO(csManager) : undefined,
    }
  })
