import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { WorkspaceUserContext } from 'features/globalSetup/contexts/WorkspaceUserContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { Form as WorkspaceUserAddForm } from './Form'
import { useCreateWorkspaceUser } from './hooks/useCreateWorkspaceUser'
import { Schema, schema, schemaDefault } from './schema'

export const FormContainer = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { formId, loading, setTouched, setValid } = useContext(WorkspaceUserContext)
  const { create, errors: apiErrors } = useCreateWorkspaceUser()

  return (
    <Form<Schema>
      id={formId}
      onSubmit={create}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault({ workspaceId })}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <WorkspaceUserAddForm />)}
    </Form>
  )
}
