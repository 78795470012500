import { MoreVert } from '@mui/icons-material'
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined'
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined'
import { IconButton, Menu } from '@mui/material'

import { ListItemButton } from 'components/List'
import { useMenu } from 'hooks/useMenu'
import { HeaderMenuProps } from './types'

export const ProductsHeaderMenu = ({
  productState: { onToggle, disabledToggle },
  i18nResource: t,
}: HeaderMenuProps): JSX.Element => {
  const { anchor, isOpen, open, close } = useMenu()

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation()
    open(event)
  }

  const handleActivate = (): void => {
    onToggle(true)
    close()
  }

  const handleDeactivate = (): void => {
    onToggle(false)
    close()
  }

  const Activate = (): JSX.Element => (
    <ListItemButton
      icon={<ToggleOnOutlinedIcon />}
      text={t('list.headerMenu.productToggle.activate')}
      onClick={handleActivate}
      disabled={disabledToggle}
    />
  )

  const Deactivate = (): JSX.Element => (
    <ListItemButton
      icon={<ToggleOffOutlinedIcon />}
      text={t('list.headerMenu.productToggle.deactivate')}
      onClick={handleDeactivate}
      disabled={disabledToggle}
    />
  )

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void =>
          event.stopPropagation()
        }
      >
        <Activate />
        <Deactivate />
      </Menu>
    </>
  )
}
