import _ from 'lodash'
import { DeepPartial, FieldErrors, UseFormSetError } from 'react-hook-form'
import validator from 'validator'

import {
  Schema,
  SchemaAdUnitBidderParams,
  SchemaAdUnitBidderProps,
} from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { AdUnitBidderSlice } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/hooks/UseGetAdUnitBidders'
import { ParamsKind } from 'features/globalSetup/pages/Prebid/Bidders/types'
import { AdUnitParameter } from 'features/globalSetup/types/adUnitBidder'
import { getParamTemplate } from './getParamTemplate'

export const setFormErrorsFor = ({
  adUnitBidderId,
  params,
  kind,
  adUnitBidders,
  errors,
  setError,
}: {
  adUnitBidderId: AdUnitBidderSlice['id']
  params: DeepPartial<SchemaAdUnitBidderProps['csParams' | 's2sParams']>
  kind: ParamsKind
  adUnitBidders: AdUnitBidderSlice[]
  errors: FieldErrors<Schema>
  setError: UseFormSetError<Schema>
}): void => {
  Object.entries(params).forEach(
    ([key, value]: [key: keyof SchemaAdUnitBidderParams, value: string | number | undefined]) => {
      if (value === undefined) return

      const paramTemplate: AdUnitParameter | undefined = getParamTemplate({
        id: adUnitBidderId,
        paramName: key,
        kind,
        adUnitBidders,
      })
      if (paramTemplate === undefined) return

      const { name, required } = paramTemplate

      if (!_.isEmpty(errors)) {
        const alreadyHasError = Boolean(
          errors['adUnitBidders']?.[`${adUnitBidderId}`]?.[`${kind}Params`]?.[`${name}`],
        )
        if (alreadyHasError) return
      }

      const isEmpty: boolean = _.isString(value) && validator.isEmpty(value)

      if (required && isEmpty) {
        setError(`adUnitBidders.${adUnitBidderId}.${kind}Params.${name}`, {
          message: 'is required',
        })
      }
    },
  )
}
