import { TableRow } from '@mui/material'
import { HeaderGroup, Header as THeader } from '@tanstack/react-table'

import { Header } from './Header'
import { HeaderGroupsProps } from './types'

export const HeaderGroups = <T,>({ headerGroups, ...props }: HeaderGroupsProps<T>): JSX.Element => (
  <>
    {headerGroups.map(
      (headerGroup: HeaderGroup<T>): JSX.Element => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers
            .filter((header: THeader<T, unknown>): boolean =>
              Boolean(!header.column.columnDef.meta?.hidden),
            )
            .map(
              (header: THeader<T, unknown>): JSX.Element => (
                <Header
                  key={header.id}
                  header={header}
                  {...props}
                />
              ),
            )}
        </TableRow>
      ),
    )}
  </>
)
