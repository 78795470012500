import { Typography } from '@mui/material'
import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, Slider, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import ProductAdUnitsTable from '../../components/ProductAdUnitsTable'
import { useTrafficPercentMarks } from './hooks/useTrafficPercentMarks'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { t } = useTranslation('features/domain')
  const { domain } = useContext(AddEditDomainContext)
  const { control } = useFormContext<Schema>()

  const trafficPercentMarks = useTrafficPercentMarks()

  return (
    <>
      <Paper>
        <SectionHeader
          title={t('form.priceGenius.enabled.title')}
          subtitle={t('form.priceGenius.enabled.description')}
          titleEndAdornment={
            <Switch
              control={control}
              name='priceGeniusEnabled'
            />
          }
          bottomSpacing
        />

        <BoxForm>
          <Slider
            control={control}
            label={t('form.priceGenius.trafficPercent.title')}
            marks={trafficPercentMarks}
            name='trafficPercent'
            withInput
          />

          <Typography variant='subtitle1'>
            {t('form.priceGenius.trafficPercent.description')}
          </Typography>
        </BoxForm>
      </Paper>

      <Controller
        control={control}
        name='adUnitsAttributes'
        render={() => (
          <ProductAdUnitsTable
            domainId={domain!.id} // eslint-disable-line @typescript-eslint/no-non-null-assertion
            product='priceGenius'
          />
        )}
      />
    </>
  )
}
