import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useBlocker } from 'react-router-dom'

import Dialog from 'components/Dialog'
import { LeaveDialogPrams } from './types'

export const LeaveDialog = ({ isDirty, isSubmitted, isSubmitting }: LeaveDialogPrams) => {
  const { t } = useTranslation('components')
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !(isSubmitting || isSubmitted) &&
      isDirty &&
      currentLocation.pathname !== nextLocation.pathname,
  )

  const handleSetOpen = () => blocker.reset?.()

  const Content = (): JSX.Element => <Typography>{t('form.leaveDialog.description')}</Typography>

  const Actions = (): JSX.Element => (
    <Button
      onClick={blocker.proceed}
      variant='contained'
    >
      {t('form.leaveDialog.leave')}
    </Button>
  )

  return (
    <Dialog
      title={t('form.leaveDialog.title')}
      content={<Content />}
      actions={<Actions />}
      isOpen={blocker.state === 'blocked'}
      setOpen={handleSetOpen}
    />
  )
}
