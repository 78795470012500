import { enqueueSnackbar, VariantType } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Product } from 'features/product'

export const useSnackbar = () => {
  const { t } = useTranslation('features/domain', {
    keyPrefix: 'list.headerMenu.productToggle',
  })
  const { t: tProduct } = useTranslation('features/product')

  const snackbar = (product: Product, enabled: boolean, variant?: VariantType): void => {
    const tParams = {
      product: tProduct(`product.${product}`),
      action: enabled ? 'enabled' : 'disabled',
    }

    if (variant === 'error') {
      enqueueSnackbar(t('error', tParams), { variant })
    } else {
      enqueueSnackbar(t('success', tParams), {
        variant: enabled ? 'success' : 'warning',
      })
    }
  }

  return { snackbar }
}
