import { t } from 'i18next'
import { z } from 'zod'

export const schema = z
  .object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    password: z.string().min(1),
    passwordConfirmation: z.string().min(1),
    invitationToken: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.passwordConfirmation) {
      ctx.addIssue({
        message: t('features/user:completeSetup.form.passwordConfirmation.error'),
        path: ['passwordConfirmation'],
        code: z.ZodIssueCode.custom,
      })
    }
  })

export const schemaDefaults = (invitationToken: string): Schema => ({
  firstName: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
  invitationToken,
})

export type Schema = z.infer<typeof schema>
