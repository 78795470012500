import { z } from 'zod'

import { AdUnitForForm } from '../../GeneralForm/hooks/useGetAdUnit'

export const commonSchema = z.object({
  id: z.string(),
  priceGeniusEnabled: z.boolean(),
})

export type CommonSchema = z.infer<typeof commonSchema>

export const schemaDefault = ({ id, priceGeniusEnabled }: AdUnitForForm): CommonSchema => ({
  id,
  priceGeniusEnabled,
})
