import { ColumnDef } from '@tanstack/react-table'
import { upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'

import { BoxWithLogo } from 'components/Box'
import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { prebidModuleLogos } from 'features/globalSetup/forms/Prebid/Modules/logos/prebidModuleLogos'
import { WorkspacePrebidModuleSlice } from 'features/globalSetup/pages/Prebid/Modules/hooks/useGetWorkspacePrebidModules'
import { ActionsCell } from './components/ActionsCell'
import { EnabledCell } from './components/EnabledCell'

export const useColumns = (): ColumnDef<WorkspacePrebidModuleSlice>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.analytics.list.header',
  })

  return [
    {
      accessorKey: 'prebidModule.name',
      cell: ({
        row: {
          original: {
            prebidModule: { name },
          },
        },
      }) => (
        <BoxWithLogo logo={{ svg: prebidModuleLogos[name], size: 'small' }}>{name}</BoxWithLogo>
      ),
      header: t('name'),
    },
    {
      accessorKey: 'prebidModule.account',
      cell: ({ row }) => upperFirst(row.original.prebidModule.account),
      header: t('account'),
    },
    {
      accessorKey: 'enabled',
      cell: props => <EnabledCell {...props} />,
      header: t('enabled'),
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('lastModified'),
    },
    {
      cell: ({ row }) => <ActionsCell {...row.original} />,
      header: t('actions'),
      id: 'actions',
      meta: {
        centered: true,
        nonClickable: true,
      },
      size: COLUMN_SIZE.ACTIONS,
    },
  ]
}
