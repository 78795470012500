import { Form as FormContainer } from 'components/Form'
import Loading from 'components/Loading'
import { Form } from './Form'
import { useConfirmUser } from './hooks/useConfirmUser'
import { Schema, schema, schemaDefaults } from './schema'
import { UserCompleteSetupFormProps } from './types'

export const UserCompleteSetupForm = ({
  invitationToken,
}: UserCompleteSetupFormProps): JSX.Element => {
  const { errors: apiErrors, loading, confirm } = useConfirmUser()

  return (
    <FormContainer<Schema>
      onSubmit={confirm}
      schema={schema}
      schemaDefaults={schemaDefaults(invitationToken)}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <Form />)}
    </FormContainer>
  )
}
