import { RouteObject } from 'react-router-dom'

import LayoutPage from 'features/layout/pages/LayoutPage'
import { multiPathRoute } from 'providers/router'
import AuthenticatedRoute from 'providers/router/components/AuthenticatedRoute'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import RootRedirection from 'providers/router/components/RootRedirection'
import RouterErrorPage from 'providers/router/components/RouterErrorPage'
import { financeRoutes } from './financeRoutes'
import { globalSetupRoutes } from './globalSetupRoutes'
import { inventoryRoutes } from './inventoryRoutes'
import { performanceRoutes } from './performanceRoutes'
import { productRoutes } from './productRoutes'
import { ROUTES } from './routePaths'
import { tagRoutes } from './tagRoutes'
import { userRoutes } from './userRoutes'
import { workspaceRoutes } from './workspaceRoutes'

export const protectedRoutes: RouteObject[] = [
  {
    path: ROUTES.BASE.ROOT,
    element: <AuthenticatedRoute />,
    errorElement: <RouterErrorPage />,

    children: [
      {
        path: ROUTES.BASE.ROOT,
        element: (
          <AuthorizedRoute
            access='Layout'
            page={<LayoutPage />}
          />
        ),

        children: [
          ...multiPathRoute({
            paths: [ROUTES.BASE.ROOT, ROUTES.BASE.HOME],
            element: <RootRedirection />,
          }),

          ...financeRoutes,
          ...globalSetupRoutes,
          ...inventoryRoutes,
          ...performanceRoutes,
          ...productRoutes,
          ...tagRoutes,
          ...userRoutes,
          ...workspaceRoutes,
        ],
      },
    ],
  },
]
