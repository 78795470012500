import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { GeneralPageContext } from 'features/globalSetup/contexts/GeneralPageContext'
import { useAbility } from 'providers/casl'
import { useUpdateWorkspace } from './hooks/useUpdateWorkspace'
import { Schema, schema, schemaDefault } from './schema'
import { WorkspaceForm } from './WorkspaceForm'

export const WorkspaceFormContainer = (): JSX.Element => {
  const ability = useAbility()
  const { formId, loading, setTouched, setValid, workspace } = useContext(GeneralPageContext)
  const { errors: apiErrors, update } = useUpdateWorkspace()

  return (
    <Form<Schema>
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(workspace, ability)}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <WorkspaceForm />)}
    </Form>
  )
}
