import { Ability } from 'providers/casl'
import { PrebidModule, PrebidModuleType } from './types/prebidModule'

type CanUpdateProps = {
  account: PrebidModule['account']
  moduleType: PrebidModuleType
  ability: Ability
}

export const canUpdate = ({ account, moduleType, ability }: CanUpdateProps): boolean =>
  account === 'own' && ability.can('update', `GlobalSetupFeature-${moduleType}`)
