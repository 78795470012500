import { ChangeEvent, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RadioGroup } from 'components/Form'
import { TargetingActionType } from 'features/inventory/domain/types/targetingActionType'
import { useAbility } from 'providers/casl'
import { KNOWLEDGE_BASE_URL } from '../../constants'
import { EditSchema } from '../../schemas/editSchema'
import { TargetingActionTypeDialog } from './components/TargetingActionTypeDialog'
import { useTargetingActionTypeOptions } from './hooks/useTargetingActionTypeOptions'

export const TargetingActionTypeInput = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/domain', 'common'])
  const { control, setValue } = useFormContext<EditSchema>()

  const [isOpen, setIsOpen] = useState(false)

  const targetingActionTypeOptions = useTargetingActionTypeOptions()

  return (
    <>
      <RadioGroup
        control={control}
        label={t('form.general.advancedSettings.targetingActionType.label')}
        name='targetingActionType'
        options={targetingActionTypeOptions}
        tooltip={{
          content: t('form.general.advancedSettings.targetingActionType.tooltip'),
          link: {
            url: KNOWLEDGE_BASE_URL.TARGETING_AUCTION_TYPE,
            title: t('common:learnMore'),
          },
        }}
        onChange={(value: ChangeEvent<HTMLInputElement>): void => {
          const tempValue = value.target.value as TargetingActionType

          tempValue === 'targetAll'
            ? setIsOpen(true)
            : setValue('targetingActionType', tempValue, { shouldDirty: true })
        }}
        disabled={!ability.can('update', 'DomianFeature-advancedSettings')}
      />

      <TargetingActionTypeDialog dialog={{ isOpen, setOpen: setIsOpen }} />
    </>
  )
}
