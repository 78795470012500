import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Filter, { FilterValue } from 'components/Filter'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import Table from 'features/tag/components/DomainsTable'
import { KNOWLEDGE_BASE_URL } from '../../constants'

export const DownloadTagsPage = (): JSX.Element => {
  const { t } = useTranslation(['features/tag', 'common'])
  const [filters, setFilters] = useState<FilterValue[]>([])

  const DomainsFilter = (
    <Filter
      id='domainsFilter'
      onChange={setFilters}
      placeholder={t('filter')}
      values={filters}
    />
  )

  return (
    <Paper>
      <TableHeader
        title={t('title')}
        description={t('description.text')}
        descriptionEndAdornment={
          <Link
            newTab
            to={KNOWLEDGE_BASE_URL.TAGS_IMPLEMENTATION}
          >
            {t('common:learnMore')}
          </Link>
        }
        filter={DomainsFilter}
        bottomSpacing
      />
      <Table filters={filters} />
    </Paper>
  )
}
