import { useTranslation } from 'react-i18next'

import Loading from 'components/Loading'
import { DialogContentFailure } from '../../components/DialogContentFailure'
import { DialogContentSuccess } from '../../components/DialogContentSuccess'
import { DropZone } from '../../components/DropZone'
import { DialogConfig, DialogConfigProps } from './types'

export const useDialogConfig = ({
  file,
  setFile,
  reset,
  response: { errors, status },
  upload,
  setOpen,
}: DialogConfigProps): DialogConfig => {
  const { t } = useTranslation('features/domain')

  switch (status) {
    case 'initial': {
      return {
        content: (
          <DropZone
            file={file}
            onChange={setFile}
          />
        ),
        button: {
          title: t('form.action.upload', { ns: 'common' }),
          action: () => file && upload(file),
        },
      }
    }

    case 'loading': {
      return {
        content: <Loading />,
        button: {
          title: t('form.action.upload', { ns: 'common' }),
          disabled: true,
        },
      }
    }

    case 'success': {
      if (!file) throw Error('Uploaded file should be accessible')
      return {
        content: <DialogContentSuccess filename={file.name} />,
        button: {
          title: t('form.action.done', { ns: 'common' }),
          action: () => setOpen(false),
        },
      }
    }

    case 'failure': {
      if (!file || !errors) throw Error('Uploaded file and errrors should be accessible')
      return {
        content: (
          <DialogContentFailure
            filename={file.name}
            errors={errors}
          />
        ),
        button: {
          title: t('form.general.bulkManagement.dialog.changeFile'),
          action: reset,
        },
      }
    }
  }
}
