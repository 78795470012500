import { Box, Typography } from '@mui/material'

import Space from 'components/Space'
import { CONTAINER_BOTTOM_SPACING } from 'providers/material-ui/theme/constants'
import { SectionHeaderProps } from './types'

export const SectionHeader = ({
  bottomSpacing,
  subtitle,
  subtitleEndAdornment,
  title,
  titleEndAdornment,
}: SectionHeaderProps): JSX.Element => (
  <Box
    sx={{ mb: bottomSpacing ? CONTAINER_BOTTOM_SPACING : 0 }}
    data-cy='section-header'
  >
    <Typography
      variant='h5'
      display='flex'
    >
      {title}

      {titleEndAdornment && <Space />}
      {titleEndAdornment}
    </Typography>

    <Typography variant='subtitle1'>
      {subtitle}

      {subtitleEndAdornment && <Space />}
      {subtitleEndAdornment}
    </Typography>
  </Box>
)
