import { useMutation } from '@apollo/client'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ViewportListContext } from 'features/inventory/viewport/contexts/ViewportListContext'
import { Viewport } from 'features/inventory/viewport/types/viewport'
import { composeAdUnitSize } from 'features/inventory/viewport/utils'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { mapGraphQLErrors } from 'providers/graphql'
import { AddSchema } from '../../schemas/addSchema'
import { CREATE_VIEWPORT } from './api/mutation'
import { Data, Variables } from './api/types'
import { UseCreateViewport } from './types'

export const useCreateViewport = (id: Viewport['id']): UseCreateViewport => {
  const { t } = useTranslation('features/viewport')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<AddSchema>>({})

  const { workspaceId } = useWorkspaceParam()

  const { update } = useContext(ViewportListContext)

  const [mutateCreateViewport] = useMutation(CREATE_VIEWPORT, {
    refetchQueries: ['getViewports'],
    onCompleted: ({ createViewport: { errors, viewport } }: Data): void => {
      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.create'), { variant: 'error' })
      } else if (viewport) {
        update(id)

        setErrors({})
        enqueueSnackbar(t('form.success.create'), { variant: 'success' })
      }
    },
  })

  const mapper = (data: AddSchema): Variables => {
    const { bannerSizes, playerSize, ...viewport } = data

    return {
      ..._.omit(viewport, ['id']),
      bannerSizes: bannerSizes.map(size => composeAdUnitSize(size)),
      playerSize: playerSize.map(size => composeAdUnitSize(size)),
      workspaceId,
    }
  }

  const create = (data: AddSchema) =>
    mutateCreateViewport({
      variables: {
        input: mapper(data),
      },
    })

  return { create, errors }
}
