import { RouteObject } from 'react-router-dom'

import AddDomain from 'features/inventory/domain/pages/AddDomain'
import DomainList from 'features/inventory/domain/pages/DomainList'
import EditDomain from 'features/inventory/domain/pages/EditDomain'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const domainRoutes: RouteObject[] = [
  {
    path: ROUTES.INVENTORY.DOMAIN.LIST,
    element: (
      <AuthorizedRoute
        access='DomainPage-list'
        page={<DomainList />}
      />
    ),
  },
  {
    path: ROUTES.INVENTORY.DOMAIN.ADD,
    element: (
      <AuthorizedRoute
        access='DomainPage-add'
        page={<AddDomain />}
      />
    ),
  },
  {
    path: ROUTES.INVENTORY.DOMAIN.EDIT,
    element: (
      <AuthorizedRoute
        access='DomainPage-edit'
        page={<EditDomain />}
      />
    ),
  },
]
