import { generatePath, To } from 'react-router-dom'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { UserTableProps } from 'features/user/components/Table/types'
import { useAbility } from 'providers/casl'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { useGetPublishers } from './hooks/useGetPublishers'
import { PublisherForTable } from './types'
import { useColumns } from './useColumns'

export const PublisherTable = ({ filters }: UserTableProps): JSX.Element => {
  const ability = useAbility()
  const columns = useColumns()
  const { page, setPage, rowsPerPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })

  const { count, users, loading } = useGetPublishers({
    filters,
    ...calculatePagination({ page, rowsPerPage }),
  })

  const canManage: boolean =
    ability.can('update', 'Publisher') && ability.can('delete', 'Publisher')

  const handleRowClick = (row: PublisherForTable): To =>
    generatePath(ROUTES.USER.PUBLISHER.EDIT, { id: row.id })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<PublisherForTable>
      columns={columns}
      columnVisibility={{ actions: canManage }}
      data={users}
      loading={loading}
      meta={{ expanded: true }}
      onClickRow={canManage ? handleRowClick : undefined}
      pagination={paginationProps}
    />
  )
}
