import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { BoxWithLogo } from 'components/Box'
import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { bidderLogos } from 'constants/bidderLogos'
import { AdUnitBidderSlice } from 'features/inventory/ad-unit/forms/PrebidStackForm'
import { ActionsCell } from './components/ActionsCell'
import { CsEnabledCell } from './components/CsEnabledCell'
import { S2sEnabledCell } from './components/S2sEnabledCell'

export const useColumns = (): ColumnDef<AdUnitBidderSlice>[] => {
  const { t } = useTranslation('features/adUnit')

  return [
    {
      accessorKey: 'name',
      header: t('form.prebidStack.bidders.list.header.name'),
      cell: ({
        row: {
          original: { name },
        },
      }) => <BoxWithLogo logo={{ svg: bidderLogos[name], size: 'small' }}>{name}</BoxWithLogo>,
    },
    {
      accessorKey: 'code',
      header: t('form.prebidStack.bidders.list.header.code'),
    },
    {
      accessorKey: 'csEnabled',
      header: t('form.prebidStack.bidders.list.header.csEnabled'),
      cell: props => <CsEnabledCell {...props} />,
      meta: { nonClickable: true, centered: true },
    },
    {
      accessorKey: 's2sEnabled',
      header: t('form.prebidStack.bidders.list.header.s2sEnabled'),
      cell: props => {
        const {
          row: {
            original: { s2sAvailable },
          },
        } = props

        return s2sAvailable && <S2sEnabledCell {...props} />
      },
      meta: { nonClickable: true, centered: true },
    },
    {
      accessorKey: 'updatedAt',
      header: t('form.prebidStack.bidders.list.header.updatedAt'),
      cell: props => <LastModifiedCell {...props} />,
    },
    {
      accessorKey: 'actions',
      cell: props => <ActionsCell {...props} />,
      header: t('form.prebidStack.bidders.list.header.actions'),
      meta: { nonClickable: true },
      size: COLUMN_SIZE.ACTIONS,
    },
  ]
}
