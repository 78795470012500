import { CSSObject, Paper as PaperRoot } from '@mui/material'

import { DEFAULTS } from './constants'
import { PaperProps } from './types'

export const Paper = ({ spacing = 'mp', ...muiPaperProps }: PaperProps): JSX.Element => {
  const spacingProps = (): CSSObject => {
    switch (spacing) {
      case 'mp': {
        return {
          m: DEFAULTS.MARGIN,
          p: DEFAULTS.PADDING,
        }
      }
      case 'm': {
        return {
          m: DEFAULTS.MARGIN,
        }
      }
      case 'p': {
        return {
          p: DEFAULTS.PADDING,
        }
      }
      case 'none': {
        return {}
      }
    }
  }

  return (
    <PaperRoot
      {...muiPaperProps}
      sx={{
        border: 0,
        ...spacingProps(),
        ...muiPaperProps.sx,
      }}
      variant='outlined'
    />
  )
}
