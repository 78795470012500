import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { WorkspaceFormContext } from 'features/workspace/contexts/WorkspaceFormContext'
import { CommonForm } from './CommonForm'
import { useUpdateWorkspace } from './hooks/useUpdateWorkspace'
import { EditSchema, editSchema, editSchemaDefault } from './schemas/edit'

export const WorkspaceEditForm = (): JSX.Element => {
  const { formId, loading, setTouched, setValid, workspace } = useContext(WorkspaceFormContext)
  const { errors: apiErrors, update } = useUpdateWorkspace()

  const LoadingFullContainer = (): JSX.Element => <Loading />

  if (!workspace) return <LoadingFullContainer />

  return (
    <Form<EditSchema>
      id={formId}
      onSubmit={update}
      schema={editSchema}
      schemaDefaults={editSchemaDefault(workspace)}
      onTouched={setTouched}
      onValid={setValid}
      externalErrors={apiErrors}
    >
      {() => (loading ? <LoadingFullContainer /> : <CommonForm />)}
    </Form>
  )
}
