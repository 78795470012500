import { Controller, FieldValues } from 'react-hook-form'

import { FilterableListBox } from 'components/FilterableListBox'
import { ChipsInput } from './components/ChipsInput'
import { ChipsListFilterableProps } from './types'

export const ChipsListFilterable = <T extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  disabled,
}: ChipsListFilterableProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name}
    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
      <FilterableListBox
        label={label}
        error={error}
        placeholder={placeholder}
      >
        <ChipsInput<T> {...{ name, value, onChange, disabled }} />
      </FilterableListBox>
    )}
  />
)
