import { Switch } from 'components/Switch'
import { useDataForEnabledSwitch } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/GeneralPage/hooks/useDataForEnabledSwitch'
import { useUpdateWorkspaceBidder } from 'features/globalSetup/pages/Prebid/Bidders/hooks/useUpdateWorkspaceBidder'
import { EnabledSwitchProps } from './types'

export const EnabledSwitch = ({
  bidderCode,
  id,
  enabled,
  accountType,
  status,
}: EnabledSwitchProps): JSX.Element => {
  const { disabled, tooltip } = useDataForEnabledSwitch({ accountType, status })
  const { checked, update } = useUpdateWorkspaceBidder({
    bidderCode,
    enabled,
    id,
  })

  return (
    <Switch
      checked={checked}
      disabled={disabled}
      onChange={update}
      tooltip={tooltip}
      tooltipMode={'wrap'}
    />
  )
}
