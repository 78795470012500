import { SvgIcon } from '@mui/material'

import Icon from 'images/logos/yieldbird.svg?react'

export const IconYieldbird = (): JSX.Element => (
  <SvgIcon
    component={Icon}
    inheritViewBox
    sx={{
      width: 'inherit',
      height: 'inherit',
    }}
  />
)
