import _ from 'lodash'

import { DomainBidderParameter } from 'features/globalSetup/types/domainBidder'
import { Schema } from '../../form/schema'
import { AdUnitBidderSlice } from '../../hooks/UseGetAdUnitBidders'

export const adUnitBiddersToSchema = (
  adUnitBidders: AdUnitBidderSlice[],
): Schema['adUnitBidders'] =>
  _.chain(adUnitBidders)
    .keyBy('id')
    .mapValues((adUnitBidder: AdUnitBidderSlice) => {
      const { csEnabled, s2sEnabled, csParams, s2sParams } = adUnitBidder

      return {
        csEnabled,
        csParams: _.mapValues(csParams, 'value'),
        s2sEnabled,
        s2sParams: _.mapValues(s2sParams, 'value'),
        csDisabledByRequiredParams: false,
        s2sDisabledByRequiredParams: false,
      }
    })
    .value()

export const getRequiredParams = (
  paramsSchema: DomainBidderParameter[],
): DomainBidderParameter['name'][] =>
  paramsSchema
    .filter((param: DomainBidderParameter): DomainBidderParameter['required'] => param.required)
    .map((param: DomainBidderParameter): DomainBidderParameter['name'] => param.name)
