import { z } from 'zod'

import { ViewportListItem } from 'features/inventory/viewport/types/viewportListItem'
import { CommonSchema, commonSchema } from './commonSchema'
import { anySizeFilled, anySizeFilledError } from './validateSizes'

export const addSchema = commonSchema.refine(
  (data: CommonSchema): boolean => anySizeFilled(data),
  anySizeFilledError,
)

export type AddSchema = z.infer<typeof addSchema>

export const addSchemaDefault = ({ id }: ViewportListItem): AddSchema => ({
  id,
  name: '',
  minimumWidth: 0,
  bannerSizes: [],
  nativeEnabled: false,
  playerSize: [],
})
