import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { WorkspaceFormContainer } from '../forms/WorkspaceForm'
import { GoogleAdManagerConnection } from '../GoogleAdManagerConnection'
import { GeneralPageTab } from '../types'
import { WorkspaceUserList } from '../WorkspaceUserList'

export const useTabs = (): Tab<GeneralPageTab>[] => {
  const { t } = useTranslation('features/globalSetup')

  return [
    {
      label: t('general.tab.workspace'),
      page: <WorkspaceFormContainer />,
      element: 'workspace',
    },
    {
      label: t('general.tab.userManagement'),
      page: <WorkspaceUserList />,
      element: 'user-management',
    },
    {
      label: t('general.tab.googleAdManagerConnection'),
      page: <GoogleAdManagerConnection />,
      element: 'google-ad-manager-connection',
      authorized: 'GlobalSetupPage-googleAdManagerConnection',
    },
  ]
}
