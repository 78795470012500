import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import { Select as SelectRoot } from 'components/Select'
import { SelectProps } from './types'

export const Select = <Schema extends FieldValues, Data>({
  control,
  name,
  onChange,
  ...selectProps
}: SelectProps<Schema, Data>): JSX.Element => (
  <Controller
    control={control}
    name={name as FieldPath<Schema>}
    render={({ field: { value, onChange: formOnChange }, fieldState: { error } }) => (
      <SelectRoot<Data>
        {...selectProps}
        id={name}
        name={name}
        value={value || ''}
        onChange={onChange || formOnChange}
        error={Boolean(error)}
        helperText={error?.message}
      />
    )}
  />
)
