import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditPageContext } from 'features/inventory/page/contexts/AddEditPageContext'
import { CommonForm } from './CommonForm'
import { useUpdatePage } from './hooks/useUpdatePage'
import { EditSchema, editSchema, editSchemaDefault } from './schemas/editSchema'

export const GeneralFormEdit = (): JSX.Element => {
  const { formId, loading, page, setTouched, setValid } = useContext(AddEditPageContext)
  const { errors: apiErrors, updatePage } = useUpdatePage()

  if (!page) {
    return <Loading />
  }

  return (
    <Form<EditSchema>
      id={formId}
      onSubmit={data => updatePage(data)}
      onTouched={setTouched}
      onValid={setValid}
      schema={editSchema}
      schemaDefaults={editSchemaDefault(page)}
      externalErrors={apiErrors}
    >
      {() => (loading ? <Loading /> : <CommonForm variant='edit' />)}
    </Form>
  )
}
