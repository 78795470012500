import { z } from 'zod'

import { workspaceBidderPriorities } from 'features/globalSetup/types/bidderPriority'
import { accounts, consentModes, statuses } from 'features/globalSetup/types/workspaceBidder'

const accountType = z.enum(accounts)
const yieldbirdStatuses = z.enum(statuses)

const common = z.object({
  bidderId: z.string().min(1),
  bidCpmAdjustment: z.number().min(0).max(100),
  priority: z.enum(workspaceBidderPriorities),
  consentMode: z.enum(consentModes),
})

const own = z
  .object({
    accountType: accountType.extract(['own']),
    bidderCode: z.string().min(1),
    bidderSid: z.string().optional(),
  })
  .merge(common)

const yieldbird = z
  .object({
    accountType: accountType.extract(['yieldbird']),
    bidderCode: z.string(),
    bidderSid: z.string(),
    status: yieldbirdStatuses,
  })
  .merge(common)

const thirdPartyProvider = z
  .object({
    accountType: accountType.extract(['thirdPartyProvider']),
    bidderCode: z.string().min(1),
    bidderSid: z.string().optional(),
    supplyChain: z.string().min(1),
  })
  .merge(common)

export const commonSchema = z.discriminatedUnion('accountType', [
  own,
  yieldbird,
  thirdPartyProvider,
])

export type CommonSchema = z.infer<typeof commonSchema>
export type CommonOwnSchema = z.infer<typeof own>
export type CommonYieldbirdSchema = z.infer<typeof yieldbird>
export type CommonThirdPartyProviderSchema = z.infer<typeof thirdPartyProvider>
