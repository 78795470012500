import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { User } from 'features/user'
import { DELETE_USER } from './api/mutation'
import { UseDeleteUser, UseDeleteUserProps } from './types'

export const useDeleteUser = ({ email, refetchQueries }: UseDeleteUserProps): UseDeleteUser => {
  const { t } = useTranslation('features/user')
  const { enqueueSnackbar } = useSnackbar()

  const [mutate, { loading }] = useMutation(DELETE_USER, {
    refetchQueries,
    onCompleted: ({ errors }): void => {
      if (errors && errors.length > 0) {
        enqueueSnackbar(t('delete.action.error', { email }), { variant: 'error' })
      } else {
        enqueueSnackbar(t('delete.action.success', { email }), { variant: 'success' })
      }
    },
  })

  const deleteUser = (id: User['id']) => mutate({ variables: { input: { userId: id } } })

  return { deleteUser, loading }
}
