import { z } from 'zod'

import { WorkspacePrebidModuleSlice } from 'features/globalSetup/contexts/PrebidModulesContext'
import { commonSchema } from './commonSchema'

export const editSchema = commonSchema

export type EditSchema = z.infer<typeof editSchema>

export const editSchemaDefault = (
  workspacePrebidModule: WorkspacePrebidModuleSlice,
): EditSchema => {
  const {
    prebidModule: { account, id },
  } = workspacePrebidModule

  return {
    accountType: account,
    prebidModuleId: id,
  }
}
