import { useTheme } from '@mui/material'

import { FooterInfoProps } from 'components/Table/Table/components/FooterInfo'
import { Props } from './types'

export const useFooterInfoProps = ({ pagesWithError }: Props): FooterInfoProps | undefined => {
  const theme = useTheme()

  if (pagesWithError.length === 0) return undefined

  return {
    text: `Error detected on pages: ${pagesWithError.join(', ')}`,
    color: theme.palette.error.main,
  }
}
