import { generatePath } from 'react-router-dom'

import { RelatedTimeoutItem } from 'components/RelatedTimeoutSettings'
import { ROUTES } from 'routes'
import { RelatedFailsafeTimeoutProps } from './types'

export const RelatedFailsafeTimeout = ({ domain }: RelatedFailsafeTimeoutProps): JSX.Element =>
  domain.customFailsafeTimeoutEnabled ? (
    <RelatedTimeoutItem
      message='customFailsafeTimeout'
      value={domain.failsafeTimeout}
      url={generatePath(ROUTES.INVENTORY.DOMAIN.EDIT, {
        workspaceId: domain.workspace.id,
        id: domain.id,
        tab: 'general',
      })}
    />
  ) : (
    <RelatedTimeoutItem
      message='failsafeTimeout'
      value={domain.workspace.failsafeTimeout}
      url={generatePath(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, {
        workspaceId: domain.workspace.id,
        tab: 'workspace',
      })}
    />
  )
