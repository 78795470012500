import { User } from 'features/user'
import { Can } from '../Can'
import {
  onAdUnit,
  onDomain,
  onFinance,
  onGlobalSetupGoogleAdManagerConnection,
  onGlobalSetupWorkspace,
  onPage,
  onProduct,
  onViewport,
} from './userByGroup'
import {
  onDomainAdvancedSettings,
  onDomainBulkManagement,
  onDomainCustomCode,
  onGlobalSetupAmazonCreate,
  onGlobalSetupAmazonUpdate,
  onGlobalSetupBidders,
  onGlobalSetupBiddersBidParamsCommon,
  onGlobalSetupBiddersBidParamsOwn,
  onGlobalSetupBiddersOwn,
  onGlobalSetupBiddersThirdParty,
  onGlobalSetupBiddersYieldbird,
  onGlobalSetupConnectionStatus,
  onGlobalSetupPrebidBiddersAdd,
  onGlobalSetupPrebidDefaults,
  onGlobalSetupPrebidModule,
  onGlobalSetupProtectionCUD,
  onGlobalSetupSupplyChain,
  onGlobalSetupWorkspaceUser,
} from './userByRole'

const onViewer = (can: Can) => {
  can('access', 'DomainPage-list')
  can('access', 'AdUnitPage-list')
  can('access', 'PagePage-list')
}

export const userByWorkspaceRole = (user: User, can: Can) => {
  switch (user.currentWorkspaceRole) {
    case 'owner': {
      onAdUnit(can)
      onDomain(can)
      onDomainAdvancedSettings(can)
      onDomainBulkManagement(can)
      onDomainCustomCode(can)
      onFinance(can)
      onGlobalSetupAmazonCreate(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupBidders(can)
      onGlobalSetupBiddersBidParamsCommon(can)
      onGlobalSetupBiddersBidParamsOwn(can)
      onGlobalSetupBiddersOwn(can)
      onGlobalSetupBiddersThirdParty(can)
      onGlobalSetupBiddersYieldbird(can)
      onGlobalSetupConnectionStatus(can)
      onGlobalSetupGoogleAdManagerConnection(can)
      onGlobalSetupPrebidBiddersAdd(can)
      onGlobalSetupPrebidDefaults(can)
      onGlobalSetupPrebidModule(can)
      onGlobalSetupProtectionCUD(can)
      onGlobalSetupSupplyChain(can)
      onGlobalSetupWorkspace(can)
      onGlobalSetupWorkspaceUser(can)
      onPage(can)
      onProduct(can)
      onViewport(can)
      break
    }

    case 'editor': {
      onAdUnit(can)
      onDomain(can)
      onDomainAdvancedSettings(can)
      onDomainBulkManagement(can)
      onDomainCustomCode(can)
      onGlobalSetupAmazonCreate(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupBidders(can)
      onGlobalSetupBiddersBidParamsCommon(can)
      onGlobalSetupBiddersBidParamsOwn(can)
      onGlobalSetupBiddersOwn(can)
      onGlobalSetupBiddersThirdParty(can)
      onGlobalSetupBiddersYieldbird(can)
      onGlobalSetupConnectionStatus(can)
      onGlobalSetupGoogleAdManagerConnection(can)
      onGlobalSetupPrebidBiddersAdd(can)
      onGlobalSetupPrebidDefaults(can)
      onGlobalSetupPrebidModule(can)
      onGlobalSetupProtectionCUD(can)
      onGlobalSetupSupplyChain(can)
      onGlobalSetupWorkspace(can)
      onPage(can)
      onProduct(can)
      onViewport(can)
      break
    }

    case 'viewer': {
      onGlobalSetupGoogleAdManagerConnection(can)
      onViewer(can)
      break
    }
  }
}
