import _ from 'lodash'
import { useEffect } from 'react'
import { DeepPartial, useFormContext } from 'react-hook-form'

import {
  Schema,
  SchemaAdUnitBidderParams,
  SchemaAdUnitBidderProps,
} from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { AdUnitBidder } from 'features/globalSetup/types/adUnitBidder'
import { EnableBidderToggleCellProps } from './types'

type Props = {
  adUnitBidderId: AdUnitBidder['id']
} & Pick<EnableBidderToggleCellProps, 'requiredParams' | 'kind'>

export const useDisableByEmptyRequiredParams = ({
  adUnitBidderId,
  requiredParams,
  kind,
}: Props): void => {
  const { watch, setValue } = useFormContext<Schema>()

  useEffect(() => {
    const subscription = watch((schema: DeepPartial<Schema>) => {
      const adUnitBidderProps: DeepPartial<SchemaAdUnitBidderProps> | undefined =
        schema.adUnitBidders?.[adUnitBidderId]

      if (!adUnitBidderProps) return

      const adUnitBidderParams: Partial<SchemaAdUnitBidderParams> | undefined =
        adUnitBidderProps[`${kind}Params`]

      if (!adUnitBidderParams) return

      const onlyRequired: Partial<SchemaAdUnitBidderParams> = _.pickBy(
        adUnitBidderParams,
        (_, key: string) => requiredParams.includes(key),
      )
      const anyEmptyRequired: boolean = _.some(
        onlyRequired,
        (value?: string | number) => _.isString(value) && value.length === 0,
      )

      const currentValue: boolean | undefined = adUnitBidderProps[`${kind}DisabledByRequiredParams`]
      if (currentValue !== anyEmptyRequired) {
        setValue(
          `adUnitBidders.${adUnitBidderId}.${kind}DisabledByRequiredParams`,
          anyEmptyRequired,
        )
      }
    })

    return () => subscription.unsubscribe()
  }, [watch]) // eslint-disable-line react-hooks/exhaustive-deps
}
