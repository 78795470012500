import { gql, TypedDocumentNode } from '@apollo/client'

import { ViewportListItemDTO } from 'features/inventory/viewport/api/types/viewportListItemDTO'

export const VIEWPORT_FRAGMENT: TypedDocumentNode<ViewportListItemDTO> = gql`
  fragment ViewportFragment on Viewport {
    bannerSizes {
      height
      width
    }
    createdAt
    id
    minimumWidth
    name
    nativeEnabled
    playerSize {
      height
      width
    }
    updatedAt
  }
`
