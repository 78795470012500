import { useQuery } from '@apollo/client'
import { Dispatch, SetStateAction } from 'react'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { gamConnectionOrderFromDTO } from 'features/workspace/mappers/gamConnectionOrderFromDTO'
import { GamConnectionOrder } from 'features/workspace/types/gamConnectionOrder'
import { GET_GOOGLE_AD_MANAGER_CONNECTION } from './api/query'
import { UseGetConnection } from './types'

export const useGetConnection = (
  setConnection: Dispatch<SetStateAction<GamConnectionOrder | undefined>>,
): UseGetConnection => {
  const { workspaceId: id } = useWorkspaceParam()

  const { loading } = useQuery(GET_GOOGLE_AD_MANAGER_CONNECTION, {
    variables: { id },
    onCompleted: ({ node: { latestGamConnectionOrder } }) => {
      if (latestGamConnectionOrder) {
        setConnection(gamConnectionOrderFromDTO(latestGamConnectionOrder))
      }
    },
  })

  return { loading }
}
