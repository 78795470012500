import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { DELETE_BIDDER } from './api/mutation'
import { UseDeleteWorkspaceBidder, UseDeleteWorkspaceBidderProps } from './types'

export const useDeleteWorkspaceBidder = ({
  code,
}: UseDeleteWorkspaceBidderProps): UseDeleteWorkspaceBidder => {
  const { t } = useTranslation('features/globalSetup', { keyPrefix: 'prebid.bidders.list.delete' })
  const { enqueueSnackbar } = useSnackbar()

  const [mutate, { loading }] = useMutation(DELETE_BIDDER, {
    refetchQueries: ['getWorkspaceBidders'],
    onCompleted: ({ deleteWorkspaceBidder: deleteBidder }): void => {
      if (deleteBidder) {
        const { errors } = deleteBidder

        if (errors && errors.length > 0) {
          enqueueSnackbar(t('action.error', { bidderCode: code }), { variant: 'error' })
        }
      } else {
        enqueueSnackbar(t('action.success', { bidderCode: code }), { variant: 'success' })
      }
    },
  })

  const deleteWorkspaceBidder: UseDeleteWorkspaceBidder['deleteWorkspaceBidder'] = id =>
    mutate({ variables: { input: { id } } })

  return { deleteWorkspaceBidder, loading }
}
