import { useMutation } from '@apollo/client'
import { ChangeEvent, useState } from 'react'

import { TOGGLE_FILTERING_RULE } from './api/mutation'
import { UseToggleFilteringRule, UseToggleFilteringRuleProps } from './types'
import { useSnackbar } from './useSnackbar'

export const useToggleFilteringRule = ({
  name,
  enabled,
  id,
}: UseToggleFilteringRuleProps): UseToggleFilteringRule => {
  const { snackbar } = useSnackbar(name)
  const [checked, setChecked] = useState<boolean>(enabled)

  const [mutate] = useMutation(TOGGLE_FILTERING_RULE)

  const update = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value: boolean = event.target.checked

    const variables = { input: { id, name, enabled: value } }
    const { data } = await mutate({ variables })
    const { errors } = { ...data?.updateFilteringRule }

    if (errors && errors.length > 0) {
      snackbar(value, 'error')
    } else {
      setChecked(value)
      snackbar(value)
    }
  }

  return { checked, update }
}
