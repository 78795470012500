import { OpenInNew } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { Link } from 'components/Link'
import { RelatedTimeoutItemProps } from './types'

export const RelatedTimeoutItem = ({
  message,
  value,
  url,
}: RelatedTimeoutItemProps): JSX.Element => {
  const { t } = useTranslation('components')

  return (
    <FlexBox axis='x'>
      <Trans
        t={t}
        i18nKey={`timeoutRelatedSettings.message.${message}`}
        values={{ value }}
        components={{
          bold: (
            <Typography
              fontWeight='bold'
              px={1}
            />
          ),
        }}
      />

      <Link
        to={url}
        newTab
      >
        <OpenInNew
          sx={theme => ({ color: theme.palette.info.main })}
          fontSize='small'
        />
      </Link>
    </FlexBox>
  )
}
