import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { GeneralPageForm } from '../../forms/GeneralPageForm'
import { InventoryConnectionPage } from '../InventoryConnectionPage'
import { AmazonTabs } from './types'

type Props = {
  showInventoryConnectionTab: boolean
}

export const useTabs = ({ showInventoryConnectionTab }: Props): Tab<AmazonTabs>[] => {
  const { t } = useTranslation('features/globalSetup/amazon')

  const generalTab: Tab<AmazonTabs> = {
    label: t('tabs.general'),
    page: <GeneralPageForm />,
    element: 'general',
    authorized: 'GlobalSetupPage-amazon-general',
  }

  const inventoryConnectionTab: Tab<AmazonTabs> = {
    label: t('tabs.inventoryConnection'),
    page: <InventoryConnectionPage />,
    element: 'inventory-connection',
    authorized: 'GlobalSetupPage-amazon-inventory-connection',
  }

  if (showInventoryConnectionTab) {
    return [generalTab, inventoryConnectionTab]
  } else {
    return [generalTab]
  }
}
