import {
  ArrowBackIosNew,
  CodeOutlined,
  HistoryOutlined,
  HomeOutlined,
  OpenInNew,
  PaymentsRounded,
  SettingsSuggestOutlined,
  SpeedOutlined,
  ViewCozyOutlined,
} from '@mui/icons-material'
import { SvgIcon } from '@mui/material'
import { generatePath, Params, To } from 'react-router-dom'

import GoogleAdManagerIcon from 'images/icons/google-ad-manager.svg?react'
import KnowledgeBaseIcon from 'images/icons/knowledge-base.svg?react'
import PortalIcon from 'images/icons/portal.svg?react'
import { theme } from 'providers/material-ui'
import { ROUTES } from 'routes'
import { SidebarItem } from '../types'
import { URL } from './constants'
import { performanceSubItems } from './performanceSubItems'
import { ConfigGeneralProps } from './types'

export const configGeneral = ({
  workspaceId,
  networkCode,
  ability,
  updateCurrentWorkspace,
  navigate,
  t,
  tProduct,
}: ConfigGeneralProps): SidebarItem[] => {
  const withWorkspace = (path: string, params?: Params): To =>
    generatePath(path, { workspaceId, ...params })

  const BACK_TO_WORKSPACES: SidebarItem = {
    title: t('sidebar.employee.backToWorkspaces'),
    icon: <ArrowBackIosNew />,
    onClick: async () => {
      const status = await updateCurrentWorkspace(undefined)
      if (status === 'failure') return

      navigate(ROUTES.WORKSPACE.LIST)
    },
    to: ROUTES.WORKSPACE.LIST,
    color: theme.palette.cream,
    marginBottom: true,
  }

  const INVENTORY_GROUP: SidebarItem = {
    title: t('sidebar.general.inventory.title'),
    group: 'inventory',
    icon: <ViewCozyOutlined />,
    subItems: [
      {
        title: t('sidebar.general.inventory.domains'),
        to: withWorkspace(ROUTES.INVENTORY.DOMAIN.LIST),
      },
      {
        title: t('sidebar.general.inventory.pages'),
        to: withWorkspace(ROUTES.INVENTORY.PAGE.LIST),
      },
      {
        title: t('sidebar.general.inventory.adUnits'),
        to: withWorkspace(ROUTES.INVENTORY.AD_UNIT.LIST),
      },
      {
        title: t('sidebar.general.inventory.viewports'),
        to: withWorkspace(ROUTES.INVENTORY.VIEWPORT),
      },
    ],
  }

  const PERFORMANCE_GROUP: SidebarItem = {
    title: t('sidebar.general.performance.title'),
    group: 'performance',
    icon: <SpeedOutlined />,
    subItems: performanceSubItems({ ability, t: tProduct, workspaceId }),
  }

  const GLOBAL_SETUP_GROUP: SidebarItem = {
    title: t('sidebar.general.globalSetup.title'),
    group: 'globalSetup',
    icon: <SettingsSuggestOutlined />,
    subItems: [
      {
        title: t('sidebar.general.globalSetup.prebid.title'),
        hidden: !ability.can('have', 'prebidStack'),
        group: 'prebid',
        subItems: [
          {
            title: t('sidebar.general.globalSetup.prebid.defaults'),
            to: withWorkspace(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT),
            shifted: true,
          },
          {
            title: t('sidebar.general.globalSetup.prebid.bidders'),
            to: withWorkspace(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.ROOT),
            shifted: true,
          },
          {
            title: t('sidebar.general.globalSetup.prebid.modules'),
            to: withWorkspace(ROUTES.GLOBAL_SETUP.PREBID.MODULES.ROOT, { tab: 'userId' }),
            hidden: !ability.can('access', 'GlobalSetupPage-prebid-modules'),
            shifted: true,
          },
          {
            title: t('sidebar.general.globalSetup.prebid.protection'),
            to: withWorkspace(ROUTES.GLOBAL_SETUP.PREBID.PROTECTION.LIST),
            shifted: true,
          },
        ],
      },
      {
        title: t('sidebar.general.globalSetup.schain'),
        to: withWorkspace(ROUTES.GLOBAL_SETUP.SCHAIN.LIST),
        hidden: !ability.can('access', 'GlobalSetupPage-supplyChain-list'),
      },
      {
        title: t('sidebar.general.globalSetup.amazon'),
        to: withWorkspace(ROUTES.GLOBAL_SETUP.AMAZON.ROOT, { tab: 'general' }),
        hidden: !ability.can('access', 'GlobalSetupPage-amazon'),
      },
      {
        title: t('sidebar.general.globalSetup.general'),
        to: withWorkspace(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, { tab: 'workspace' }),
      },
    ],
  }

  const showFinanceItem: boolean = ability.can('access', 'FinancePage')

  return [
    ...(ability.can('access', 'WorkspacePage-list') ? [BACK_TO_WORKSPACES] : []),

    {
      title: t('sidebar.general.home'),
      icon: <HomeOutlined />,
      to: ROUTES.BASE.HOME,
    },
    INVENTORY_GROUP,
    PERFORMANCE_GROUP,
    GLOBAL_SETUP_GROUP,
    {
      title: t('sidebar.general.history'),
      icon: <HistoryOutlined />,
    },
    {
      title: t('sidebar.general.downloadTags'),
      icon: <CodeOutlined />,
      to: withWorkspace(ROUTES.TAG),
      gapAfter: !showFinanceItem,
    },
    {
      title: t('sidebar.general.finance'),
      icon: <PaymentsRounded />,
      to: withWorkspace(ROUTES.FINANCE),
      hidden: !showFinanceItem,
      gapAfter: true,
    },
    {
      title: t('sidebar.external.googleadManagerAccount'),
      icon: (
        <SvgIcon
          component={GoogleAdManagerIcon}
          inheritViewBox
        />
      ),
      to: `https://admanager.google.com/${networkCode}#home`,
      endAdornment: <OpenInNew fontSize='inherit' />,
      newTab: true,
    },
    {
      title: t('sidebar.external.portal'),
      icon: (
        <SvgIcon
          component={PortalIcon}
          inheritViewBox
        />
      ),
      to: URL.PORTAL,
      endAdornment: <OpenInNew fontSize='inherit' />,
      newTab: true,
    },
    {
      title: t('sidebar.external.knowledgeBase'),
      icon: (
        <SvgIcon
          component={KnowledgeBaseIcon}
          inheritViewBox
        />
      ),
      to: URL.KNOWLEDGE_BASE,
      endAdornment: <OpenInNew fontSize='inherit' />,
      newTab: true,
    },
  ]
}
