import { FieldValues, useFormContext } from 'react-hook-form'

import { Switch } from 'components/Form'
import { SwitchProps } from 'components/Form/Switch'

type Props<T extends FieldValues> = Pick<SwitchProps<T>, 'tooltip' | 'tooltipMode'> &
  Required<Pick<SwitchProps<T>, 'name' | 'value'>> &
  Pick<SwitchProps<T>, 'disabled'>

export const TableFormSwitchCell = <Schema extends FieldValues>({
  name,
  value,
  tooltip,
  tooltipMode,
  disabled,
}: Props<Schema>): JSX.Element => {
  const { control } = useFormContext<Schema>()

  return (
    <Switch<Schema>
      control={control}
      name={name}
      value={value}
      tooltip={tooltip}
      tooltipMode={tooltipMode}
      disabled={disabled}
    />
  )
}
