import { mapDTO } from '@twistezo/ts-dto-mapper'

import { GamConnectionOrderDTO } from 'features/workspace/api/types/gamConnectionOrderDTO'
import { GamConnectionOrder } from 'features/workspace/types/gamConnectionOrder'

export const gamConnectionOrderFromDTO = (from: GamConnectionOrderDTO): GamConnectionOrder =>
  mapDTO<GamConnectionOrderDTO, GamConnectionOrder>({ from }).transform(dto => ({
    ...dto,
    errorMessage: dto.errorMessage || undefined,
    createdAt: new Date(dto.createdAt),
    updatedAt: new Date(dto.updatedAt),
  }))
