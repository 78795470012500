import { Box, SxProps, Theme, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { BUTTON_BORDER_RADIUS } from 'providers/material-ui/theme/constants'

const sx = (theme: Theme): SxProps => ({
  backgroundColor: theme.palette.background.default,
  borderColor: theme.palette.grey[400],
  borderRadius: BUTTON_BORDER_RADIUS / 4,
  borderStyle: 'dashed',
  borderWidth: 2,
  px: 2,
  py: 4,
})

const ACCEPTED_FILE_TYPES = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
}

type Props = {
  file?: File
  onChange: (file: File) => void
}

export const DropZone = ({ file, onChange }: Props): JSX.Element => {
  const { t } = useTranslation('features/domain')
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    multiple: false,
  })

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      onChange(acceptedFiles[0])
    }
  }, [acceptedFiles]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box {...getRootProps()}>
      <Box
        sx={theme => ({ ...sx(theme) })}
        data-cy='drop-zone-container'
      >
        <input
          {...getInputProps()}
          data-cy='drop-zone-input'
        />
        <Typography align='center'>
          {file ? file.name : t('form.general.bulkManagement.dialog.hint')}
        </Typography>
      </Box>
    </Box>
  )
}
