import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../forms/GeneralPageForm'
import { toDTO } from './api/mapper'
import { UPSERT_WORKSPACE_AMAZON } from './api/mutation'
import { Data } from './api/types'
import { Props, UseUpsertWorkspaceAmazon } from './types'

export const useUpsertWorkspaceAmazon = ({ accountType }: Props): UseUpsertWorkspaceAmazon => {
  const { t } = useTranslation('features/globalSetup/amazon', { keyPrefix: 'pages.general.upsert' })
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate, { loading }] = useMutation(UPSERT_WORKSPACE_AMAZON, {
    refetchQueries: ['getWorkspaceAmazon'],
    onCompleted: ({ upsertWorkspaceAmazon: { errors } }: Data): void => {
      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))

        enqueueSnackbar(
          t(accountType === 'yieldbird' ? 'yieldbird.error' : 'ownAndThirdParty.error'),
          { variant: 'error' },
        )
      } else {
        enqueueSnackbar(
          t(accountType === 'yieldbird' ? 'yieldbird.success' : 'ownAndThirdParty.success'),
          { variant: 'success' },
        )
      }
    },
  })

  const upsert = (data: Schema): void => {
    mutate({
      variables: {
        input: toDTO(data),
      },
    })
  }

  return {
    upsert,
    loading,
    errors,
  }
}
