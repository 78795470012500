import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

export const DescriptionCondtition = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.domainSids',
  })

  return (
    <Trans
      t={t}
      i18nKey='description.condition'
      components={{
        bold: (
          <Typography
            component='span'
            fontWeight='bold'
            variant='subtitle1'
          />
        ),
      }}
    />
  )
}
