import { useTranslation } from 'react-i18next'

import { KNOWLEDGE_BASE } from 'features/globalSetup/constants'
import { GamConnectionOrderStatus } from 'features/workspace/types/gamConnectionOrderStatus'
import SignalConnected from 'images/icons/signal-connected.svg?react'
import SignalDisconnected from 'images/icons/signal-disconnected.svg?react'
import { UseConfig } from './types'

export const useConfig = (status: GamConnectionOrderStatus): UseConfig => {
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'general.googleAdManagerConnection.connectionStatus',
  })

  switch (status) {
    case 'completed':
      return {
        icon: SignalConnected,
        color: 'success.main',
      }
    case 'inProgress':
      return {
        color: 'warning.main',
      }
    case 'failed':
      return {
        icon: SignalDisconnected,
        color: 'error.main',
        tooltip: {
          content: t('tooltip'),
          link: {
            title: tC('learnMore'),
            url: KNOWLEDGE_BASE.GOOGLE_AD_MANAGER_CONNECTION,
          },
        },
      }
  }
}
