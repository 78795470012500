import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { Schema } from './schema'
import { BiddersSection } from './sections/BiddersSection'

export const Form = (): JSX.Element => {
  const { t } = useTranslation('features/adUnit')
  const { control } = useFormContext<Schema>()

  return (
    <>
      <Paper>
        <SectionHeader
          title={t('form.prebidStack.enabled.title')}
          subtitle={t('form.prebidStack.enabled.description')}
          titleEndAdornment={
            <Switch
              control={control}
              name='prebidStackEnabled'
            />
          }
        />
      </Paper>

      <BiddersSection />
    </>
  )
}
