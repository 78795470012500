import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainRefresher } from '../../types/DomainRefresher'
import { DomainRefresherDTO } from '../types/domainRefresherDTO'

export const domainRefresherFromDTO = (from: DomainRefresherDTO) =>
  mapDTO<DomainRefresherDTO, DomainRefresher>({ from }).transform(domainRefresherDTO => {
    const { adThreshold, createdAt, refreshInterval, updatedAt } = domainRefresherDTO

    return {
      ...domainRefresherDTO,
      adThreshold: adThreshold * 100,
      refreshInterval: refreshInterval / 1000,
      createdAt: new Date(createdAt),
      updatedAt: new Date(updatedAt),
    }
  })
