import { camelCase } from 'change-case'
import { useParams } from 'react-router-dom'

import { Product } from 'features/product'
import { PerformancePageParams } from '../../types'
import { UsePerformancePageParams } from './types'

export const usePerformancePageParams = (): UsePerformancePageParams => {
  const { workspaceId, product } = useParams<PerformancePageParams>()

  return { workspaceId, product: product ? (camelCase(product) as Product) : undefined }
}
