import { CellContext } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'

import { Switch } from 'components/Form'
import { useDataForAdUnitBidderSwitch } from 'features/globalSetup'
import { AdUnitBidderSlice } from 'features/inventory/ad-unit/forms/PrebidStackForm'
import { Schema } from 'features/inventory/ad-unit/forms/PrebidStackForm/schema'
import { useAbility } from 'providers/casl'
import { isDisabledByYieldbirdDenied, isDisabledByYieldbirdRequested } from '../../utils'

export const CsEnabledCell = ({
  row: {
    original: { id, accountType, csRequiredParams, workspaceBidderStatus },
  },
}: CellContext<AdUnitBidderSlice, unknown>): JSX.Element => {
  const ability = useAbility()
  const { control } = useFormContext<Schema>()

  const { isDisabled, tooltip } = useDataForAdUnitBidderSwitch({
    paramsKind: 'cs',
    isDisabledByAuth: !ability.can('update', 'GlobalSetupFeature-bidder'),
    isDisabledByYieldbirdRequested: isDisabledByYieldbirdRequested(
      accountType,
      workspaceBidderStatus,
    ),
    isDisabledByYieldbirdDenied: isDisabledByYieldbirdDenied(accountType, workspaceBidderStatus),
    isDisabledByRequiredParams: !csRequiredParams,
  })

  return (
    <Switch<Schema>
      control={control}
      name={`adUnitBiddersAttributes.${id}.csEnabled`}
      tooltip={tooltip}
      tooltipMode='wrap'
      disabled={isDisabled}
    />
  )
}
